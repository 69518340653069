
function getFirstDayOfWeek(selectedDay) {
    const date = new Date(selectedDay);
    let newDate = date.setDate(selectedDay.getDate() - selectedDay.getDay() );
    newDate = new Date(newDate);
    newDate.setHours(0);
    return new Date(newDate);
}

function getDayOffset(startDate, offset) {
    const date = new Date(startDate);
    const newDate = new Date(date.setDate(date.getDate() + offset));
    newDate.setHours(0);
    return new Date(newDate);
}

let dayOfWeekFromOffset = (selectedDate, offset) => {
    const date = new Date(selectedDate);
    const newDate = date.setDate(selectedDate.getDate() + offset );
    return new Date(newDate);
}

function getFirstDayOfMonth(selectedDay) {
    const date = new Date(selectedDay);
    const newDate = new Date(date.getFullYear(), date.getMonth(), 1, 0);
    return newDate;
}

function sameDay(d1, d2) {
    return d1 && d2 && d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
}

const monthsBetween = (d1, d2) => {
    let yearDifference = d1.getFullYear() - d2.getFullYear();
    let month1 = d1.getMonth();
    let month2 = d2.getMonth();
    if (yearDifference > 0) {
        month1 += 11;
    } else if (yearDifference < 0) {
      month2 += 11;
    }
    return month1 - month2;
}


const dateBetween = (d1, d2, testDate) => {
    return (d1 >= testDate && testDate >= d2) ||
    (d1 <= testDate && testDate <= d2);
}

const iterationsFrom = (d1, d2) => {
    let iterations = ((Number(d2)/1000) - Number(d1)/1000) / (2 * 7 * 24 * 60 * 60);
    return Math.round((iterations*10)/5)*5/10;
}

const dateAfterIterations = (d1, iterations) => {
    return new Date(d1.getTime() +  (iterations * 2 * 7 * 24 * 60 * 60 * 1000));
}

export {
    getFirstDayOfWeek,
    getFirstDayOfMonth,
    dayOfWeekFromOffset,
    sameDay,
    monthsBetween,
    dateBetween,
    iterationsFrom,
    getDayOffset,
    dateAfterIterations
}