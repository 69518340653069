import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";

const GET_INVENTORY_WARNINGS = gql`
  query GetInventoryWarnings {
    inventoryWarnings {
      message
    }
  }
`;

export default function useAdminWarnings(currentUser) {
  const { loading, error, data, refetch } = useQuery(GET_INVENTORY_WARNINGS, {
    skip: ( currentUser && ["admin", "super-admin"].includes(currentUser.type)) ? false : true,
  });
  return {
    warnings: data && data.inventoryWarnings,
    updateWarnings: refetch
  }
}
