import React from 'react';
import { Routes } from 'react-router-dom';

import LandingScreens from './landing';
import AuthScreens from './auth';
import NotFoundScreens from './notFound';
import NotAuthorizedScreens from './notAuthorized';
import PrefabScreens from './prefab';
import WinterHarvestScreens from './winterHarvest';
import OrderScreens from './order';
import AdminScreens from './admin';
import UserScreens from './user';
import TOCScreens from './terms';


const Router = () => {
  return (
    <Routes>
      {
        [   
          ...LandingScreens,
          ...PrefabScreens,
          ...AuthScreens,
          ...NotAuthorizedScreens,
          ...WinterHarvestScreens,
          ...OrderScreens,
          ...AdminScreens,
          ...UserScreens,
          ...TOCScreens,
          ...NotFoundScreens,
        ]
      }
    </Routes>
  );
};

export default Router;
