import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimaryOutline = ({className, white, ...rest}) => {
  return (
    <ButtonRoot 
      className={`
        border-2 
        ${white ? 'border-white text-white' : 'border-background-dark'}
        font-semibold 
        cursor-pointer
        rounded 
        hover:no-underline
        hover:text-black
        hover:bg-white
        ${className}
      `}
      {...rest}
    />
  );
};

export default ButtonPrimaryOutline;
