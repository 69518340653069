import React, {useState, useContext, useCallback} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useMemo } from 'react';
import Message from 'components/common/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Login from './Login';

const SIGNUP = gql`
  mutation Signup($email: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $addressOne: String!, $longitude: Float!, $latitude: Float!, $addressTwo: String, $password: String!, $organizationId: String) {
    signup(email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, addressOne: $addressOne, longitude: $longitude, latitude: $latitude, addressTwo: $addressTwo, password: $password, organizationId: $organizationId) {
      id
    }
  }
`;

const SignUp = ({embedded, onSuccess}) => {
  const {setLoading, loading, setError, error, currentUser, login, organization} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [addressOne, setAddressOne] = useState();
  const [addressCheck, setAddressCheck] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [addressTwo, setAddressTwo] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [signupMutation, { loading:signupLoading, error:signupError, reset }] = useMutation(SIGNUP);
  const addressInputRef = useRef();

  const measuredRef = useCallback(node => {
    if (node !== null) {
      let autocomplete = locationAutocomplete(node);
      autocomplete.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        setAddressOne(place.formatted_address);
      });
      addressInputRef.current = node;
    }
  }, []);

  if (signupLoading && !loading) {
    setLoading(true);
  } else if (!signupLoading && loading) {
    setLoading(false);
  }

  // if (signupError && !error) {
  //   setError(true);
  // } else if (!signupError && error) {
  //   setError(false);
  // }

  const valid = () => {
    return email && firstName && addressOne && longitude && latitude && lastName && password && phoneNumber && (password === confirmPassword)
  }

  const addressValid = useMemo(() => {
    return addressCheck ? longitude && latitude : true;
  }, [addressCheck, longitude, latitude]);

  const signUp = () => {
    if (valid()) {
      signupMutation({ variables: { email, phoneNumber, firstName, lastName, addressOne, addressTwo, longitude, latitude, password, organizationId: organization && organization.id } })
        .then(res => {
          window.gtag('event', 'signUp', {
            'email': email,
          });
          login(email, password, embedded, "/subscription");
          onSuccess();
        })
        .catch(err => {
          console.log(err);
          reset();
        })
    }
  }
  let form = <>
    <div className="flex flex-col">
      <label>Email </label>
      <Input onChange={setEmail}/>
    </div>
    <div className="flex flex-col">
      <label>Phone Number </label>
      <Input onChange={setPhoneNumber}/>
    </div>
    <div className="flex flex-col">
      <label>First Name </label>
      <Input onChange={setFirstname}/>
    </div>
    <div className="flex flex-col">
      <label>Last Name </label>
      <Input onChange={setLastname}/>
    </div>
    <div className="flex flex-col">
      <label>Address</label>
      <Input className="hidden"/>
      <Input autocomplete="chrome-off" forwardRef={measuredRef} onBlur={setAddressCheck} valueOverride={addressOne}/>
    </div>                  
    {
      !addressValid &&
      <Message>
        <FontAwesomeIcon className="text-xs text-green-400" icon={faExclamationTriangle}/>
        &nbsp;Please enter a valid address, using the address select
      </Message>
    }
    <div className="flex flex-col">
      <label>Apt, suite, etc.</label>
      <Input valueOverride={addressTwo} onChange={setAddressTwo}/>
    </div>
    <div className="flex flex-col">
      <label>Password </label>
      <Input type="password" onChange={setPassword}/>
    </div>
    <div className="flex flex-col">
      <label>Confirm Password </label>
      <Input type="password" onChange={setConfirmPassword}/>
    </div>
    <ButtonPrimary disabled={!valid() || signupLoading} onClick={signUp}>{signupLoading ? <Loading/> : "Submit"}</ButtonPrimary>
  </>

  return embedded ?
      form
    :
    <PaddedPage className="flex justify-center">
      <div className="flex flex-col justify-center gap-2 max-w-80">
        <h1>Sign Up</h1>
        <p>
          Already have an account? Login <Link to="/login">Here</Link>
        </p>
        {form}
      </div>
    </PaddedPage>
  ;
};

export default SignUp;
