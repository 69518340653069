import React, { useState } from 'react'
import { useMutation, gql } from "@apollo/client";

const UPDATE_INVENTORY_ORDER = gql`
  mutation UpdateInventoryOrder($status: String, $id: String!) {
    updateInventoryOrder(status: $status, id: $id) {
      id
    }
  }
`;

let options = ['pending', 'processing', 'filled']

let colorLookup = {
  pending: 'yellow',
  processing: 'orange',
  filled: 'green',
}

export default function OrderToggle({order, readOnly}) {
  const [status, setStatus] = useState(order.status);
  const [showOptions, setShowOptions] = useState(false);
  const [updateInventoryOrderMutation] = useMutation(UPDATE_INVENTORY_ORDER);

  const index = options.indexOf(status);

  const updateStatus = (update,) => {
    updateInventoryOrderMutation({ variables: {status: update, id: order.id} })
    .then(() => {
      setStatus(update);
    })
  }
  
  // -top-16 -top-8 -top-0 border-orange text-orange bg-orange
  return (
    <div className="relative cursor-pointer" onMouseEnter={() => !readOnly && setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
      <p className={`rounded bg-${colorLookup[status]} text-sm align-middle text-white text-center p-1 w-24 `}>{status.toUpperCase()}</p>
      <div className={`${showOptions ? 'flex' : 'hidden'} ${`-top-${index*8}`} flex-col text-center w-24 absolute gap-1 rounded bg-white z-10`}>
        {options.map(option => 
        <p 
          className={`rounded border hover:font-bold border-${colorLookup[option]} text-sm align-middle p-1 ${option == status ? `bg-${colorLookup[option]} text-white` : `text-${colorLookup[option]}`}`}
          onClick={() => updateStatus(option)}
        >
          {option.toUpperCase()}
        </p>)}
      </div>
    </div>
  )
}
