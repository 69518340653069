import { gql, useQuery } from '@apollo/client';
import MultipleSelect from 'components/inputs/MultipleSelect'
import ActivePageContext from 'contexts/ActivePageContext';
import React, { useContext } from 'react'

const GET_TAGS = gql`
  query GetTags($organizationId: String){
    tags(organizationId: $organizationId) {
      id,
      name,
    }
  }
`;

export default function ItemListFilters({filters, setFilters, drivers}) {
  const {organizationId} = useContext(ActivePageContext);
  const { data: tagsData } = useQuery(GET_TAGS, {
    variables: {organizationId: organizationId},
  });

  return (
    <div className="border flex p-2 gap-4">
      <div> 
        <strong>Tags</strong>
        <hr></hr>
        <div className="flex gap-2">
        <MultipleSelect options={tagsData ? tagsData.tags.map(tag => ({value: tag.id, name: tag.name})) : []} onChange={(tags) => setFilters({...filters, tags})}/>
        </div>
      </div>
      <div> 
        <strong>Search</strong>
        <hr></hr>
        <div className="flex gap-2">
        <input className="px-2" value={filters.search} onChange={(e) => setFilters({...filters, search: e.target.value})}/>
        </div>
      </div>
    </div>
  )
}
