import { faArrowRight, faSeedling, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import Calendar from 'components/calendar';
import { getDayOffset, getFirstDayOfWeek, iterationsFrom } from 'helpers/calendar';
import Loading from 'components/common/Loading';
import { useMemo } from 'react';
import OneTimeProductCalendar from 'components/calendar/OneTimeProductCalendar';
import Products from './Products';
import { useMutation, gql } from "@apollo/client";

const ADD_ONETIME_PRODUCTS = gql`
  mutation AddOneTimeProducts($items: JSON, $date: String) {
    addOneTimeProducts(items: $items, date: $date) {
      message
    }
  }
`;

let offsetLookup = {
  "sunday": 0,
  "monday": 1,
  tuesday: 2, 
  wednesday: 3,
  thursday: 4,
  friday: 5, 
  saturday: 6,
}

const dayValid = (sub, billingAnchor, date) => {
  if (sub.metadata.paused == "true") return false;
  let iterations = iterationsFrom(new Date().getTime(), date.getTime());
  let interval = iterationsFrom(billingAnchor*1000, date.getTime());
  if (iterations < 0.5 && !Number.isInteger(interval)) return false;
  for (let item of sub.items) {
    if (item.price.nickname.toLowerCase() === "bi-weekly") {
      if (Number.isInteger(interval)) {
        return true;
      }
    } else {
      return true;
    }
  }
}

const subscriptionToCalendarEvents = (subscription, upcommingChanges, billingAnchor) => {
  const deliveryDays = [];
  let upcommingChange = upcommingChanges && upcommingChanges[0];
  let changeIndex = 0;
  let currentSub = subscription;
  let currentDay = getDayOffset(getFirstDayOfWeek(new Date()), 7);
  let deliveryOffset = offsetLookup[subscription && subscription.metadata && subscription.metadata.delivery];
  let endTime = new Date(currentDay.getTime() + (12 * 7 * 24 * 60 * 60 * 1000));
  while (currentDay < endTime) {
    if (upcommingChange && ((currentDay.getTime() + 4 * 24 * 60 * 60 * 1000) > Number(upcommingChange.start_date)*1000)) {
      deliveryOffset = offsetLookup[upcommingChange.metadata && upcommingChange.metadata.delivery];
      currentSub = upcommingChange;
      upcommingChange = upcommingChanges[changeIndex + 1];
      changeIndex ++;
      if (dayValid(currentSub, billingAnchor, currentDay)){
        deliveryDays.push(getDayOffset(currentDay, deliveryOffset).toISOString().split('T')[0]);
      }
    } else if (currentSub && currentSub.start_date && Number(currentSub.start_date)*1000 < currentDay.getTime() + 4 * 24 * 60 * 60 * 1000 && ((!currentSub.cancel_at_period_end || currentSub.cancel_at_period_end == 'false') || (Number(currentSub.current_period_end)*1000 > (currentDay.getTime() + 4 * 24 * 60 * 60 * 1000) ))) {
      if (dayValid(currentSub, billingAnchor, currentDay)){
        deliveryDays.push(getDayOffset(currentDay, deliveryOffset).toISOString().split('T')[0]);
      }
    }
    currentDay = new Date(currentDay.getTime() +  7 * 24 * 60 * 60 * 1000);
  }
  return deliveryDays;
}

export default function OneTimeProductModal({subscription, upcommingChanges, onComplete, onClose, loading, date}) {
  let [open, setOpen] = useState(true);
  let [stage, setState] = useState(date ? 1 : 0); 
  let [selectedDate, setSelectedDate] = useState();
  let [items, setItems] = useState({});
  const [addOneTimeProductsMutation, { loading:addLoading }] = useMutation(ADD_ONETIME_PRODUCTS);

  const billingAnchor = subscription.current_period_end || subscription.schedule.phases[0].start_date;

  const deliveryDays = useMemo(() => {
    return subscriptionToCalendarEvents(subscription, upcommingChanges, billingAnchor)
  }, [subscription, upcommingChanges, billingAnchor]);
  const location = useMemo(() => {
    return subscription.metadata ? {location: subscription.metadata.location} : {location: subscription.schedule && subscription.schedule.metadata.location};
  }, [subscription.metadata, subscription.schedule]);

  const close = () => {
    setOpen(false);
    onClose();
  }

  const valid = () => {
    let total = 0;
    for (let priceKey in items) {
      if (items[priceKey]) {
        total += items[priceKey].quantity;
      }
    }
    return selectedDate && total > 0;
  }

  const confirm = () => {
    if (valid()) {
      delete items.load;
      addOneTimeProductsMutation({ variables: {items, date: selectedDate} })
      .then(res => {
        onComplete();
      })
      .catch(err => {
        console.log("err", err);
      })
    }
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border w-full max-w-160 shadow-md">
              <div className={`flex justify-between relative px-4 p-2 bg-green`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faSeedling} />
                  &nbsp;
                  One-Time Products
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              {
                stage === 0 &&
                <div className="p-8 bg-whiter flex flex-col gap-2 justify-center">
                  <div className="text-center">
                    <h3>
                      When would you like to add to your order?
                      <hr></hr>
                    </h3>
                    <small>*One time products can be added to your order up until the Saturday beforehand, up to 2 months in advance.</small>
                  </div>
                  <div className="py-4">
                    <OneTimeProductCalendar 
                      max={2} 
                      min={0} 
                      billingAnchor={billingAnchor}
                      validDays={deliveryDays}
                      onSelected={setSelectedDate}
                    />
                  </div>
                  {selectedDate &&
                    <div className="text-center">Add a One-Time product for
                      <div className="bg-accent-two inline-block mx-1 rounded p-1 text-sm font-bold">{selectedDate.toDateString()}</div>
                    ?</div>
                  }
                </div>
              }
              {
                stage === 1 &&
                <div className="p-8 bg-whiter flex flex-col gap-2 justify-center">
                  <div className="text-center">
                    <h3>
                      What would you like to add to your order?
                      <hr></hr>
                    </h3>
                  </div>
                  <div className="max-h-50vh overflow-y-scroll scroller">
                    <Products categories={true} query="-metadata['subtype']:'delivery'" small="true" oneTime="true" subscription={location} onChange={(newItems) => setItems(newItems)} items={items}/>
                  </div>
                </div>
              }
              {
                stage === 2 &&
                <div className="p-8 bg-whiter flex flex-col gap-2 justify-center">
                  <div className="text-center">
                    <h3>
                      Summary
                      <hr></hr>
                    </h3>
                  </div>
                  <div className="max-h-50vh overflow-y-scroll scroller">
                    <Products query="-metadata['subtype']:'delivery'" small="true" oneTime="true" summary="true" items={items}/>
                  </div>
                  {selectedDate &&
                    <div className="text-center pt-4 font-medium">Scheduled for 
                      <div className="bg-accent-two inline-block mx-1 rounded p-1 text-sm font-bold">{selectedDate.toDateString()}</div>
                    </div>
                  }
                </div>
              }
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                {stage !== 0 && <ButtonPrimary onClick={() => setState(stage - 1)} >Back</ButtonPrimary>}
                {stage === 2 && <ButtonPrimary className=""  disabled={!valid()} onClick={confirm} >{loading || addLoading ? <Loading/> : 'Confirm'}</ButtonPrimary>}
                {stage !== 2 && <ButtonPrimary onClick={() => setState(stage + 1)} disabled={stage === 0 ? !selectedDate : !valid()}>Next</ButtonPrimary>}
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
