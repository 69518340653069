export default function IntervalPicker({defaultInterval, onChange}) {

  const updateInterval = (interval) => {
    onChange(interval);
  }

  return (
   <div className="flex flex-col gap-4">
      <label>Subscription Interval</label>
      <div className="flex gap-2">
         <div className={`
         border-2 rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
         ${!defaultInterval ? 'border-dark-grey text-dark-grey' : 'text-light-grey'}
         `}
         onClick={() => updateInterval()}
         >
            <h2 className={`${!defaultInterval ? 'text-dark-grey': "text-light-grey"}`}>Weekly</h2>
            <small className="font-bold"></small>
         </div>
         <div className={`
         border-2  rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
         ${defaultInterval == "bi-weekly" ? 'border-dark-grey text-dark-grey' : 'border-light-grey text-light-grey'}
         `}
         onClick={() => updateInterval("bi-weekly")}
         >
            <h2 className={`${defaultInterval == "bi-weekly" ? 'text-dark-grey': "text-light-grey"}`}>Bi-Weekly</h2>
            <small className="font-bold"></small>
         </div>
      </div>
   </div>
  )
}
