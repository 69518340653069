import AppContext from 'contexts/AppContext';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';

export default function SalesNav() {
  const {currentUser} = useContext(AppContext);
  return (
    <div className="flex gap-4 items-center">
        {/* <Link className="no-underline font-medium text-dark-grey" to="/prefab">Prefab Farms</Link>
        <Link className="no-underline font-medium" to="/order">Order Now</Link> */}
        {currentUser ?
          <Link className="no-underline font-medium" to="/my-subscription">My Subscription</Link>
          :
          <Link className="no-underline font-medium" to="/subscription">Subscriptions</Link>
        }
   </div>
  )
}
