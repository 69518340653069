import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope, faEarth } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Organization({organization}) {

  return (
    <Link className="no-underline" to={`/admin/super/organizations/${organization.id}`}>
      <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon className="" icon={faUser} />
            <strong>{organization.name}</strong>
            <div className="flex items-center gap-2 ml-4">
              <small><FontAwesomeIcon className="" icon={faEnvelope} /> {organization.email}</small>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className={`rounded px-2 bg-primary text-sm text-white font-bold`}>
              <FontAwesomeIcon className="" icon={faEarth} /> {organization.subdomain}
            </div>
          </div>
      </div>
    </Link>
  )
}
