import React, { useState } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Input } from 'components/inputs';
import { ButtonPrimary } from 'components/common/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";

const RESET_PASSWORD = gql`
  mutation ResetPassword($id: String!, $password: String!) {
    resetPassword(id: $id, password: $password) {
      message
    }
  }
`;

const PasswordReset = () => {
  const [password, setPassword] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState();
  const [resetPasswordMutation, { loading: restLoading }] = useMutation(RESET_PASSWORD);


  const handleReset = () => {
    resetPasswordMutation({variables: {id: params.id, password: password}})
    .then(res => {
      navigate('/login?reset=true');
    });
  }

  const validate = () => {
    return password && password === confirmPassword;
  }

  return (
    <PaddedPage className="flex justify-center">
      <div className="flex flex-col justify-center gap-4">
        <h1>Reset Password</h1>
        <div className="flex flex-col">
          <label>New Password</label>
          <Input type="password" onChange={setPassword} />
        </div>
        <div className="flex flex-col">
          <label>Confirm New Password</label>
          <Input type="password" onChange={setConfirmPassword} />
        </div>
        <ButtonPrimary disabled={!validate()} onClick={handleReset}>Submit</ButtonPrimary>
      </div>
    </PaddedPage>
  );
};

export default PasswordReset;
