import PlantManagement from 'components/plantManagements/PlantManagement'
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';
import React, { useContext } from 'react'

export default function PlantManagementList({inProgressPlantManagements}) {
  const {date, monthEvents} = useContext(CalendarContext);

  const dayName = new Intl.DateTimeFormat('en-Us', { weekday: 'long' }).format(new Date(date));

  const todaysEvents = monthEvents ? monthEvents.filter(event => sameDay(date, event.date)) : [];

  return (
    <div className="flex flex-col gap-4">
      <div className="grow flex flex-col gap-2">
        <div>
          <h2>In Progress</h2>
          <hr></hr>
        </div>
        {inProgressPlantManagements && inProgressPlantManagements.map(plantManagement => <PlantManagement key={plantManagement.id} plantManagement={plantManagement}/>)}
        {inProgressPlantManagements && inProgressPlantManagements.length === 0 && <small className="text-med-grey">No Plant Managements</small> }
      </div>
      <div className="grow flex flex-col gap-2">
        <div>
          <h2>{dayName} {date.getMonth() + 1 + "/"  + date.getDate()}</h2>
          <hr></hr>
        </div>
        {todaysEvents.map(event => <PlantManagement key={event.data.id} plantManagement={event.data} phase={event.name} date={event.date}/>)}
        {todaysEvents.length === 0 && <small className="text-med-grey">No Plant Managements</small> }
      </div>
    </div>
  )
}
