import { iterationsFrom } from 'helpers/calendar';

const getNextBillingCycle = (changesStart, prev) => {
    return new Date(new Date(changesStart).getTime() + 7 * 24 * 60 * 60 *1000 * (prev ? -1 : 1)).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
}
  
const subscriptionToClientSubscription = (sub) => {
    let subscription = {items: {}, location: "whitehorse", delivery: "wednesday"};
    let sortedChanges = (sub.schedule && sub.schedule.phases && sub.schedule.phases.sort((a,b) => Number(a.start_date) - Number(b.start_date))) || []
    let upcommingChange = sortedChanges.find(phase => sub.current_period_end ? (Math.abs(sub.current_period_end - phase.start_date)) < 100 : (new Date(phase.start_date * 1000) > new Date())) || null;
    upcommingChange = upcommingChange && {...upcommingChange};
    if (upcommingChange && (iterationsFrom(Number(upcommingChange.start_date)*1000, Number(upcommingChange.end_date)*1000) !== 1)) {
        // change is a half block 
        let newChangeStart = upcommingChange.start_date;
        upcommingChange = sortedChanges.find(phase => iterationsFrom(Number(phase.start_date)*1000, Number(phase.end_date)*1000) === 1) || {items: sub.items};
        upcommingChange = upcommingChange && {...upcommingChange};
        upcommingChange.start_date = newChangeStart;
    }
    let changeStart = sub.current_period_end || (upcommingChange && upcommingChange.start_date);
    let metadata = upcommingChange ? {...(sub.metadata || {}),...sub.schedule.metadata, ...upcommingChange.metadata} : {...sub.metadata};
    let items = (upcommingChange ? upcommingChange.items : sub.items) || [];
    subscription.location = metadata.location;
    subscription.delivery = metadata.delivery;
    subscription.notes = metadata.notes;
    subscription.items = items.reduce((acc, current) => ({...acc, [current.price.product.id]: {price: current.price, quantity: current.quantity}}), {});
    return {subscription, changeStart};
}

const getDefaultSubscription = () => {
    try {
        let stored = JSON.parse(localStorage.getItem('subscription'));
        stored.load = true;
        return stored || {items: {}, location: "whitehorse", delivery: "wednesday"};
    } catch (e) {
        return {items: {}, location: "whitehorse", delivery: "wednesday"};
    }
}

  
  export {
    getNextBillingCycle,
    subscriptionToClientSubscription,
    getDefaultSubscription,
}