import React, {useState} from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import AppContext from 'contexts/AppContext';
import useOrganization from 'hooks/useOrganization';
import useAdminWarnings from 'hooks/useAdminWarnings';

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [error, setError] = useState();
  const {organization} = useOrganization();
  const {currentUser, login, logout, impersonating, impersonate, stopImpersonating, loading: userLoading, error: userError} = useCurrentUser();
  const {warnings, updateWarnings} = useAdminWarnings(currentUser);
  // TODO globl loading, error, messages
  return (
    <AppContext.Provider value={{ 
      currentUser,
      login, 
      logout,
      impersonating,
      impersonate,
      stopImpersonating,
      loading: userLoading || loading,
      setLoading,
      error: userError || error,
      page,
      organization,
      setPage,
      warnings,
      updateWarnings
    }} >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
