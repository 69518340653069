import React, {useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faUser, faTimes, faCircleLeft, faMask, faMoneyBill, faKey, faPhone, faDesktop, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import MiniButton from 'components/common/Button/MiniButton';
import CreateBalanceModal from 'screens/user/CreateBalanceModal';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Input, { Checkbox } from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { faDeskpro } from '@fortawesome/free-brands-svg-icons';
import SubscriptionOverview from 'screens/winterHarvest/subscriptions/SubscriptionOverview';

const GET_USER = gql`
  query GetUser($id: String) {
    user(id: $id) {
      id,
      firstName,
      lastName,
      email,
      type,
      note,
      phoneNumber, 
      addressOne,
      addressTwo,
      activeSubscription,
      unsubscribeNotifications,
      unsubscribePromotions,
      subscription {
        id,
        cancel_at_period_end,
        items {
          price {
            unit_amount,
            nickname, 
            metadata,
            product{
              description,
              image,
              name
            },
          },
          metadata,
          quantity
        },
        start_date,
        metadata,
        current_period_end,
        current_period_start,
        schedule {
          current_phase {start_date, end_date}
          metadata,
          phases{
            end_date,
            start_date,
            trial_end,
            metadata,
            items{
              quantity,
              price{
                metadata,
                nickname,
                recurring{
                  interval,
                  interval_count
                },
                product{
                  description,
                  image,
                  name
                },
                unit_amount
              }
            }
          }
          status
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($note: String, $userId: String, $type: String) {
    updateUser(note: $note, userId: $userId, type: $type) {
      id
    }
  }
`;

export default function User({removeUser, currentFirmId}) {
  const {currentUser, impersonate, loading:userLoading} = useContext(AppContext);
  const [showCreateBalance, setShowCreateBalance] = useState(false);
  const [editNote, setEditNote] = useState();
  const [note, setNote] = useState();
  const [showConfirmAdmin, setShowConfirmAdmin] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const [updateUserMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_USER);
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: {id: params.id}
  });

  const upcommingChanges = (data && data.user.subscription && data.user.subscription.schedule && data.user.subscription.schedule.phases && data.user.subscription.schedule.phases.filter(phase => (new Date(phase.start_date * 1000) > new Date()))) || null;
  const updateNote = () => {
    updateUserMutation({variables: {note, userId: data.user.id}})
      .then(res => {
        setEditNote(false);
        refetch();
      })
      .catch(() => {
        reset();
      })
  }

  const promoteToAdmin = () => {
    updateUserMutation({variables: {userId: data.user.id, type: "admin"}})
    .then(res => {
      setShowConfirmAdmin(false);
      refetch();
    })
    .catch(() => {
      reset();
    })
  }

  return (
    data ?
    <>
      {showCreateBalance && <CreateBalanceModal
        user={data.user}
        onClose={() => setShowCreateBalance(false)}
      /> }
      {showConfirmAdmin && <ConfirmNotice
        title={`Make ${data.user.firstName} an Admin?`}
        message={`They will have access to the dispatch, inventory, and users list for this organization.`}
        onConfirm={promoteToAdmin}
        onClose={() => setShowConfirmAdmin(false)}
      /> }
      <div className="p-4 flex flex-col gap-6">
        <div className="flex gap-2">
          <Link to="/admin/super/users"><FontAwesomeIcon className="text-2xl" icon={faCircleLeft} /></Link>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <h1><FontAwesomeIcon className="" icon={faUser} /> {data.user.firstName}
            </h1>
            {data.user.type == "admin" && <div className="rounded bg-red text-white px-3 font-bold">ADMIN</div>}
            {data.user.type == "super-admin" && <div className="rounded bg-red text-white px-3 font-bold">SUPER-ADMIN</div>}
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            <small><FontAwesomeIcon className="" icon={faTag} /> {data.user.firstName}  {data.user.lastName}</small>
            <small><FontAwesomeIcon className="" icon={faEnvelope} /> {data.user.email}</small>
            <small><FontAwesomeIcon className="" icon={faPhone} /> {data.user.phoneNumber}</small>
            <small><FontAwesomeIcon className="" icon={faLocationArrow} /> {data.user.addressTwo} {data.user.addressOne}</small>
          </div>
          <div className="flex gap-2">
            <Checkbox
              name="terms"
              initialValue={!data.user.unsubscribeNotifications}
              className="mr-2"
              readOnly={true}
              text={
                <label>
                  Notifications
                </label>
              }
              required
            />
            <Checkbox
              name="terms"
              initialValue={!data.user.unsubscribePromotions}
              className="mr-2"
              readOnly={true}
              text={
                <label>
                  Promotions
                </label>
              }
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <h3>
                Note
                <sup>
                  &nbsp;
                  <FontAwesomeIcon onClick={() => setEditNote(!editNote)} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
                </sup>
              </h3>
              <hr></hr>
            </div>
            <div className="flex gap-2">
              {editNote ?
                <>
                  <Input valueOverride={data.user.note} onChange={setNote}/>
                  <ButtonPrimary disabled={updateLoading} onClick={updateNote}>{updateLoading ? <Loading/> : "Update"}</ButtonPrimary>
                </>
                :
                <p>{data.user.note || '-'}</p>
              }
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <h3>Actions</h3>
              <hr></hr>
            </div>
            <div className="flex gap-2">
              <MiniButton onClick={() => impersonate(data.user.id)} disabled={userLoading} className="self-start text-white bg-dark-grey"><FontAwesomeIcon className="" icon={faMask} /> Impersonate</MiniButton>
              <MiniButton onClick={() => setShowCreateBalance(true)} disabled={userLoading} className="self-start text-white bg-dark-grey"><FontAwesomeIcon className="" icon={faMoneyBill} /> Credit Account</MiniButton>
              {
                currentUser.type === "super-admin" && !["admin", "super-admin"].includes(data.user.type) &&
                <MiniButton onClick={() => setShowConfirmAdmin(true)} disabled={userLoading} className="self-start text-white bg-dark-grey"><FontAwesomeIcon className="" icon={faKey} /> Make Admin</MiniButton>
              }
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <hr></hr>
            </div>
            <div className="w-full flex flex-col gap-2">
              <div>
                <h3 className="rounded bg-primary px-1 inline-block text-white">Active Subscription</h3>
                <hr></hr>
              </div>
              <SubscriptionOverview sub={data && data.user.subscription} lastUpdate={data && data.user.subscription && data.user.subscription.cancel_at_period_end !== 'true' && (!upcommingChanges || upcommingChanges.length === 0)}/>
            </div>
            {upcommingChanges && upcommingChanges.length > 0 &&
              <div className="w-full flex flex-col gap-2">
                <div>
                  <h3 className="rounded bg-primary px-1 inline-block text-white">Pending Updates</h3>
                  <hr></hr>
                </div>
                {upcommingChanges.map((change, index) => (
                  <SubscriptionOverview key={index} sub={change} lastUpdate={index === upcommingChanges.length -1}/>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </>
    :
    <></>
  )
}
