import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import hydroponicPlantTop from 'assets/images/landing/hydro-plant-top.png';
import hydroponicPlantBottom from 'assets/images/landing/hydro-plant-bottom.png';
import funkyLines from 'assets/images/landing/funky-lines.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDroplet, faSeedling, faSquareCheck } from '@fortawesome/free-solid-svg-icons';


export default function CrossSectionJumbo() {
  return (
    <div className="flex flex-col pb-20">
        <PaddedPage className="flex sm:flex-col items-stretch z-10" noBottom>
          <div className="w-1/2 md:min-h-60 sm:w-full flex flex-col items-end sm:items-start justify-end pb-4">
            <h1 className="jumbo">What is <span className="gradient">Hydroponics?</span></h1>
            <h2>
              Hydroponics is when plants are grown in a water medium instead of dirt.
            </h2>
          </div>
          <div className="w-1/2 sm:w-full grow bg-contain bg-no-repeat min-h-40 bg-bottom " style={{backgroundImage: `url(${hydroponicPlantTop})`}} />
        </PaddedPage>
        {/* <div className="w-full bg-contain opacity-10 min-h-40 bg-bottom -mt-40 -mb-1" style={{backgroundImage: `url(${hydroponicPlantTop})`}} /> */}
        <div className="w-full h-8 z-10 border-2" style={{backgroundImage: `url(${funkyLines})`}} />
        <PaddedPage noTop className="flex sm:flex-col-reverse items-stretch">
          <div className="w-1/2 sm:w-full relative min-h-80">
            <div className="w-1/2 md:min-h-60 sm:w-full flex flex-col items-end justify-end pb-4 opacity-0">
              <h1 className="jumbo">What is <span className="gradient">Hydroponics?</span></h1>
              <h2>
                Hydroponics is when plants are grown in a water medium instead of dirt.
              </h2>
            </div>
            <div className="absolute top-2 flex flex-col gap-8 w-full">
              <table className="w-full">
                <tr className="">
                  <th></th>
                  <th className=" p-2">Hydroponics</th>
                  <th className=" p-2">Traditional Farming</th>
                </tr>
                <tr className="text-center bg-light-light-grey">
                  <td className="font-medium text-left">Water usage</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary" icon={faDroplet}/></td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>  
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="font-medium text-left">Density</td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                  </td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                  </td>
                </tr>
                <tr className="text-center bg-light-light-grey">
                  <td className="font-medium text-left">Weather Dependent</td>
                  <td></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
                <tr className="text-center">
                  <td className="font-medium text-left">Safe Produce</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
                <tr className="text-center bg-light-light-grey">
                  <td className="font-medium text-left">Requires Electricity</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                  <td></td>
                </tr>
                <tr className="text-center">
                  <td className="font-medium text-left">Crop Rotation</td>
                  <td></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
              </table>
            </div>
          </div>
          <div className="w-1/2 sm:w-full grow bg-contain bg-no-repeat min-h-40 bg-top -mt-1" style={{backgroundImage: `url(${hydroponicPlantBottom})`}} />
        </PaddedPage>
    </div>
  )
}
