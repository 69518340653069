import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useNavigate } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import Plant from './Plant';

const ADD_PLANT = gql`
  mutation AddPlant($name: String!, $description: String!, $image: String!, $growthPhases: JSON!) {
    addPlant(name: $name, description: $description, image: $image, growthPhases: $growthPhases) {
      id,
    }
  }
`;

export default function NewPlant() {
  const {currentUser} = useContext(AppContext);
  const [plant, setPlant] = useState({});
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const addressInputRef = useRef();
  const [addPlantMutation, { loading:updateLoading, reset }] = useMutation(ADD_PLANT);
  const {setActiveSubpage} = useContext(ActivePageContext);
  setActiveSubpage("new");
  

  const valid = () => {
    return plant.name && plant.description && plant.image && plant.growthPhases;
  }

  const addPlant = (plant) => {
    addPlantMutation({variables: {...plant}})
      .then(res => {
        navigate(`/admin/plants/${res.data.addPlant.id}`)
      })
      .catch(() => {
        reset();
      })
  }

  return (
    <div className="flex flex-col gap-2">
      <Plant newPlant={true} overrideLoading={updateLoading} addPlant={addPlant} />
    </div>
  )
}
