import React, {useContext, useMemo} from 'react';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';
import { monthsBetween } from 'helpers/calendar';
import { dateBetween } from 'helpers/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';

export default function MonthDay({dayOfWeek, dayOfMonth, empty, currentDate, allowSetDate}) {
  const {
    setDate, 
    date, 
    monthEvents,
    startClickable,
    endClickable,
    selectDate,
    hoverOnDate,
    startDate,
    endDate,
    hoverDate,
  } = useContext(CalendarContext);
  const todaysEvents = monthEvents ? monthEvents.filter(event => sameDay(currentDate, event.date)) : [];
  const canStart = useMemo(() => {
    return !empty && startClickable && startClickable(currentDate)
  }, [currentDate, startClickable]);
  const canEnd = useMemo(() => {
    return !empty && endClickable && endClickable(currentDate)
  }, [currentDate, endClickable]);
  const clickable = useMemo(() => {
    return !startDate ? canStart : endDate ? dateBetween(startDate, endDate, currentDate) : canEnd;
  }, [currentDate, endClickable]);

  const handleClick = () => {
    if (!empty) {
      clickable && selectDate(currentDate);
      allowSetDate && setDate(currentDate);
    }
  }
  return (
    <div 
      className={`
        flex relative w-1/7
        ${!empty && 'border'}
        ${!empty && (allowSetDate) && 'cursor-pointer'}
        ${!empty && (clickable) && `cursor-pointer hover:bg-light-grey hover:font-md hover:scale-110 hover:z-10 ${endDate ? 'hover:border-red hover:bg-white hover:text-red' : 'hover:border-green hover:bg-green hover:text-white'}`}
        ${!empty && sameDay(allowSetDate ? date : new Date(), currentDate) && 'bg-light-grey font-md scale-110 border-b-2 border-b-primary text-black shadow-md z-10'}
        ${!empty && startDate && sameDay(startDate, currentDate) && 'bg-green'}
        ${!empty && startDate && endDate && dateBetween(startDate, endDate, currentDate) && 'bg-green text-white'}
        ${!empty && startDate && hoverDate && dateBetween(startDate, hoverDate, currentDate) && 'border-green text-green bg-light-green'}
        ${!empty && !startDate && canStart && 'bg-light-green border-green text-green'}
      `}
      onClick={handleClick}
      onMouseEnter={() => hoverOnDate && hoverOnDate(currentDate)}
    >
      {
        !empty &&
        <>
          <div className="mt-full"/>
          <div className="flex flex-col absolute justify-between top-0 left-0 right-0 bottom-0 p-0.5">
            <small>{dayOfMonth}</small>
            <div className="flex overflow-hidden">
              {todaysEvents.map(event =>
                <>
                  <span className="text-xxs -mr-1">
                    {event.icon}{event.tag && <span className="font-bold text-primary">#{event.tag}</span>}
                  </span>
                  {
                    event.paused &&
                    <div className="w-full h-full absolute bg-transparent-white top-0 opacity-75 flex justify-center items-center">
                      <FontAwesomeIcon icon={faPause}/>
                    </div>
                  }
                </>
              )}
            </div>
          </div>
        </>
      }
    </div>
  )
}
