import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Dropdown from 'components/common/Dropdown';
import AppContext from 'contexts/AppContext';
import { faExclamationTriangle, faMask } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'components/common/Tooltip';

export default function UserNav() {
  const {logout, currentUser, warnings, impersonating, stopImpersonating} = useContext(AppContext);
  return (
    <div className="flex gap-4 items-center">
      {/* <a href={`${process.env.REACT_APP_WINTERHARVEST_CLIENT}`}>WinterHarvest</a> */}
      {/* <a href={`${process.env.REACT_APP_CLIENT}`} className="xs:hidden">Prefab units</a> */}
      {["admin", "super-admin"].includes(currentUser.type) && 
        <>
          <Link className="" to={"/admin"}>Admin</Link>
          {warnings &&
            <Link to="/admin/inventory">
              <Tooltip 
                message="Warning you have items in low stock"
                icon={faExclamationTriangle}
                color="red"
                jumbo
                bottom
                left
              />
            </Link>
          }
        </>
      }
      <Dropdown options={[
              {
                action: impersonating ? stopImpersonating : logout,
                text: impersonating ? "stop impersonating" : "logout"
              },
              {
                to: "user/profile",
                text: "Profile"
              }
          ]}
          trigger={<FontAwesomeIcon icon={impersonating ? faMask: faUser}/> }
      />
   </div>
  )
}
