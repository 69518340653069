import React from 'react'

export default function PrefabNav() {
  return (
    <div  
        className="
        sticky
        top-0
        -mb-12
        flex 
        justify-center
        z-20
        bg-transparent-secondary
        "
    >
        <div className="
            py-3 
            w-full 
            flex 
            justify-between
            sm:px-6
            md:px-12 
            lg:px-16 
            xl:px-24 
            2xl:max-w-7xl 
            2xl:p-auto
        ">
            <strong>Prefab Units</strong>
            <div className="flex items-center gap-2">
                <small><a href="#build" className="no-underline text-primary">Build</a></small>
                <small><a href="#customize" className="no-underline text-white">Customization</a></small>
                <small><a href="#production" className="no-underline text-accent-three">Production</a></small>
            </div>
        </div>
    </div>
  )
}
