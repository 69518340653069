import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function Tooltip({message, color, icon, className, showOverride, jumbo, left, bottom}) {
  const [show, setShow] = useState(false);
  // border-red
  return (
    <div className={`inline-block ${jumbo ? '-ml-2' : 'ml-1'} relative w-2 ${className}`}>
      <div className={`absolute ${jumbo ? '-bottom-2' : 'bottom-0'} w-10`}>
        {(showOverride || show) && <div className={`${bottom ? 'top-20' : ''} relative ${left ? '-left-52' : '-left-32'} flex justify-center items-end w-1/4screen z-10`}>
          <div className={` text-xxs w-fit flex p-2 rounded bg-white ${color ? `border-${color}` : 'border-blue'} border`}>
            <p className="grow">
              {message}
            </p>
          </div>
          <div className={`absolute ${bottom ? '-rotate-135 -top-1.5' : 'rotate-45 -bottom-1.5'} ${left ? 'left-52' : 'left-32'} w-3 h-3 ${color ? `border-${color}` : 'border-blue'} border-r border-b bg-white`}></div>
        </div>}
        <FontAwesomeIcon onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className={`${color ? `text-${color}` : 'text-blue'} ${jumbo ? 'text-lg' : 'text-xxs'} top-0 cursor-pointer`} icon={icon || faCircleQuestion}/>
      </div>
    </div>
  )
}
