import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from './Transparent';
import {Input} from 'components/inputs';

export default function ConfirmNotice({confirmText, title, message, submessage, onClose, onConfirm, confirmButtonText, cancelButtonText, color}) {
  let [open, setOpen] = useState(true);
  let [confirmTextMatch, setConfirmTextMatch] = useState();

  const close = () => {
    setOpen(false);
    onClose();
  }

  const confirm = () => {
    setOpen(false);
    onConfirm();
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-120 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-${color || 'red'}`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faTriangleExclamation} />
                  &nbsp;
                  {title}
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <p>
                  {message}
                </p>
                {submessage && <p>{submessage}</p>}
                {confirmText &&
                  <Input onChange={setConfirmTextMatch}/>
                }
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >{cancelButtonText || 'Close'}</ButtonPrimary>
                <ButtonPrimary className={`border-${color || 'red'} text-${color || 'red'}`} disabled={confirmText ? confirmText !== confirmTextMatch : false} onClick={confirm} >{confirmButtonText || 'Confirm'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
