import React, {useEffect, useMemo, useState} from 'react';
import CalendarDay from './CalendarDay';
import CalendarMonth from './CalendarMonth';
import CalendarWeek from './CalendarWeek';
import CalendarContext from 'contexts/CalendarContext';
import { monthsBetween } from 'helpers/calendar';
import { sameDay } from 'helpers/time';

const addMonths = (change, date) => {
  let newMonth = date.getMonth() + change;
  if (newMonth > 11) {
    return new Date(date.getFullYear() + 1, newMonth%12, 1);
  } else if (newMonth < 0) {
    return new Date(date.getFullYear() - 1, 12 + newMonth, 1);
  } else {
    return new Date(date.getFullYear(), newMonth, 1);            
  }
}

const GrowthCalandar = ({max, min, billingAnchor, onSelected, events, children, onMonthChange}) => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hoverDate, setHoverDate] = useState();

  let monthYear = useMemo(() => {
    return date.getFullYear() + '-' + date.getMonth();
  }, [date]);

  useEffect(() => {
    onMonthChange(new Date(date.getFullYear(), date.getMonth(), 1));
  }, [monthYear]);

  const handleSetDate = (val) => {
		setDate(val);
	}

  const hoverOnDate = (date) => {
    if (startDate && !endDate) {
      setHoverDate(date);
    }
  }

  const startClickable = (date) => {
    return false;
  }

  const selectDate = (date) => {
    setDate(date);
  }

  const nextMonth = useMemo(() => {
    let newMonth = addMonths(1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min]);

  const prevMonth = useMemo(() => {
    let newMonth = addMonths(-1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min])

  return (
		<CalendarContext.Provider value={{
      date,
      selectDate,
      startClickable,
      startDate,
      endDate,
      hoverOnDate,
      hoverDate,
      setDate: handleSetDate, 
      monthEvents: events, 
      nextMonth, 
      prevMonth
    }}>
      <div className="flex gap-4 justify-center grow">
          <div className="flex flex-col gap-2 grow">
            <CalendarMonth allowSetDate={true}/>
          </div>
          <div className="grow">
            {children}
          </div>
      </div>
		</CalendarContext.Provider>
  )
}

export default GrowthCalandar;
