import Organization from './Organization';
import Organizations from './Organizations';
import New from './New';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = [
  <Route key="organizations-new" path="organizations/new" element={<New/>} />,
  <Route key="organization" path="organizations/:id" element={<Organization/>} />,
  <Route key="organizations" path="organizations" element={<Organizations/>} />,
];

export default routes;
