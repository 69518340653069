import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { data } from 'autoprefixer';

export default function DetailedUser({user, firmId, grey, index}) {
  return (
    <tr className={grey ? `bg-color-light-grey` : ''}>
      <td>{index}</td>
      <td>
        <Link className="no-underline" to={`/admin/super/users/${user.id}`}>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon className="" icon={faUser} />
            <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName} {user.lastName}</small>
          </div>
        </Link>
      </td>
      <td><small><FontAwesomeIcon className="" icon={faEnvelope} /> {user.email}</small></td>
      <td>            
        {["admin", "super-admin"].includes(user.type) && <div className="rounded bg-red text-white px-2 text-sm font-bold">{user.type.toUpperCase()}</div>}
      </td>
      <td>{user.phoneNumber}</td>
      <td>{user.firstSubscribed ? new Date(user.firstSubscribed * 1000).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"} ) : '-'}</td>
      <td>{user.cancelDate ? new Date(user.cancelDate * 1000).toLocaleString('default', {month: 'long', day: "numeric", year: "numeric"} ) : '-'}</td>
      <td>{user.cancellationReason?.reason}</td>
      <td>{user.credits?.length}</td>
      <td>{user.credits?.reduce((partialSum, a) => partialSum + a, 0)}</td>
    </tr>
  )
}
