import React from 'react'

export default function ItemListFilters({filters, loading, setFilters, drivers}) {
  return (
    <div className="border flex p-2 gap-4">
      <div> 
        <strong>Location</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.location === "whitehorse"} onClick={() => setFilters({...filters, location: "whitehorse"})} type="radio" id="location1" name="location" value="whitehorse"/>
            <label className="font-normal cursor-pointer" htmlFor="location1">Whitehorse</label>
          </div>
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.location === "dawson"} onClick={() => setFilters({...filters, location: "dawson"})} type="radio" id="location2" name="location" value="dawson"/>
            <label className="font-normal cursor-pointer" htmlFor="location2">Dawson</label> 
          </div>
        </div>
      </div>
      <div> 
        <strong>Days</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.day === ""} onClick={() => setFilters({...filters, day: ""})} type="radio" id="day1" name="day" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="day1">All</label>
          </div>
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.day === "wednesday"} onClick={() => setFilters({...filters, day: "wednesday"})} type="radio" id="day2" name="day" value="wednesday"/>
            <label className="font-normal cursor-pointer" htmlFor="day2">Wednesday</label> 
          </div>
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.day === "thursday"} onClick={() => setFilters({...filters, day: "thursday"})} type="radio" id="day3" name="day" value="thursday"/>
            <label className="font-normal cursor-pointer" htmlFor="day3">Thursday</label>
          </div>
        </div>
      </div>
      <div> 
        <strong>Distribution</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.distribution === ""} onClick={() => setFilters({...filters, distribution: ""})} type="radio" id="distribution0" name="distribution" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="distribution0">All</label>
          </div>
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.distribution === "pick-up"} onClick={() => setFilters({...filters, distribution: "pick-up"})} type="radio" id="distribution1" name="distribution" value="pick-up"/>
            <label className="font-normal cursor-pointer" htmlFor="distribution1">Pick-Up</label>
          </div>
          <div>
            <input disabled={loading} className="cursor-pointer" defaultChecked={filters.distribution === "delivery"} onClick={() => setFilters({...filters, distribution: "delivery"})} type="radio" id="distribution2" name="distribution" value="delivery"/>
            <label className="font-normal cursor-pointer" htmlFor="distribution2">Delivery</label> 
          </div>
        </div>
      </div>
      {drivers && drivers.length > 0 &&
        <div> 
          <strong>Driver</strong>
          <hr></hr>
          <div className="flex gap-2">
            {drivers.map(driver => (
              <div key={driver}>
                <input disabled={loading} className="cursor-pointer" defaultChecked={filters.driver === driver} onClick={() => setFilters({...filters, driver})} type="radio" id={`driver${driver}`} name="driver" value={driver}/>
                <label className="font-normal cursor-pointer" htmlFor={`driver${driver}`}>{driver}</label>
              </div>
            ))}
            <div>
              <input disabled={loading} className="cursor-pointer" defaultChecked={!filters.driver} onClick={() => setFilters({...filters, driver: ""})} type="radio" id={`driverall`} name="driver" value=""/>
              <label className="font-normal cursor-pointer" htmlFor="driverall">All</label>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
