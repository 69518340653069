import { faArrowRight, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import Calendar from 'components/calendar';
import { getFirstDayOfWeek, iterationsFrom } from 'helpers/calendar';
import Loading from 'components/common/Loading';

export default function DateRangeSelector({onConfirm, onClose, loading, setRange}) {
  let [open, setOpen] = useState(true);
  // let [selected, setSelected] = useState();
  console.log(getFirstDayOfWeek(new Date()));
  const anchor = getFirstDayOfWeek(new Date()).getTime()/1000;

  const close = () => {
    setOpen(false);
    onClose();
  }

  const handleSelected = (val) => {
    if (val) {
      setRange({start: val.start , iterations: 2 * iterationsFrom(val.start, val.end)})
      onClose();
    }
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white w-full max-w-80 pt-2 rounded border shadow-md">
                <Calendar 
                  max={2} 
                  min={0} 
                  billingAnchor={anchor}
                  onSelected={handleSelected}
                />
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
