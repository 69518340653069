import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Input from 'components/inputs';
import PaymentMethod from './PaymentMethod';
import ButtonPrimary from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/common/Loading';
import Message from 'components/common/Message';
import { gql, useMutation, useQuery } from '@apollo/client';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import ConfirmNotice from 'components/notices/ConfirmNotice';

const appearance = {
  style: {
    base: {
      margin: '5px 15px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1em',
      '::placeholder': {
        color: 'rgba(0,0,0,.4)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const GET_PROMO_CODE = gql`
  query GetPromoCode($code: String!) {
    getPromoCode(code: $code) {
      active,
      restrictions,
      coupon {
        id,
        name,
        amount_off,
        percent_off
      }
    }
  }
`;

const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($paymentId: String!) {
    removePaymentMethod(paymentId: $paymentId) {
      id,
    }
  }
`;

function SubscriptionStripeCardSetupForm({ paymentIntent, paymentMethods, setPaymentMethods, handleSuccess, loadingOverride, returnUrl, valid, update, coupons, setCouponUsed, buttonText }) {
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [showNewPayment, setShowNewPayment] = useState(paymentMethods ? false : true);
  const [promoCode, setPromoCode] = useState();
  const [showNotice, setShowNotice] = useState();
  const [removePaymentMethodMutation, { loading:updateLoading }] = useMutation(REMOVE_PAYMENT_METHOD);

  const [savedPaymentMethod, setSavedPaymentMethod] = useState(paymentMethods ? paymentMethods[0].id : null);

  const { loading: subloading, error, data } = useQuery(GET_PROMO_CODE, {
    fetchPolicy:'network-only',
    skip: !promoCode,
    variables: {code: promoCode},
    onCompleted(data)  {
      if (data.getPromoCode) {
        setCouponUsed(data.getPromoCode.coupon);
      } else {
        setCouponUsed();
      }
    }
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [cost, setCost] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    if (paymentMethods) {
      setSavedPaymentMethod(paymentMethods[0].id);
      setShowNewPayment(false);
    }
  }, [paymentMethods]);

  const validateForm = () => {
    return !loading && !message.success && paymentInfo.terms && (showNewPayment || savedPaymentMethod);
  };


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event?.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !paymentIntent) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage({ success: false, content: 'Payment could not be processed.' });
      return;
    }

    if (!showNewPayment && savedPaymentMethod) {
      setLoading(false);
      return handleSuccess(savedPaymentMethod, data && data.getPromoCode && data.getPromoCode.coupon.id);
    }

    // const result = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     return_url: returnUrl,
    //   },
    //   // Uncomment below if you only want redirect for redirect-based payments
    //   redirect: 'if_required',
    // });
    // The payment has been processed!
    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    })
  
    // The payment has been processed!
    setMessage({
      success: true, 
      content: 'Success, payment method received.'
    });
    setLoading(false);
    handleSuccess(result.setupIntent && result.setupIntent.payment_method);
  };

  const handleRemovePaymentMethod = (method) => {
    return removePaymentMethodMutation({ variables: {paymentId: method.id} })
    .then(({data}) => {
        if (savedPaymentMethod === method.id) {
          setSavedPaymentMethod(null);
          setPaymentMethods(current => {
            let filtered = current.filter(currentMethod => currentMethod.id !== method.id);
            if (filtered.length < 1) {
              setShowNewPayment(true);
              return null;
            }
            return filtered;
          }
          );
        }
      });
  };

  return (
    <>
      {showNotice && <ConfirmNotice
          title={`Notice`}
          message={<div className="flex flex-col gap-2">
            <p>
              Thank you for your interest in supporting local food! WinterHarvest is paused until September 2nd. We're upgrading our equipment and taking some time off with loved ones.
            </p>
            <p>
              Set up your subscription now to have fresh, locally grown, pesticide free greens delivered to you this fall. Order today and receive greens September 4th or 5th. Have a wonderful summer,
            </p>
            The WinterHarvest Team          
          </div>}
          onConfirm={handleSubmit}
          cancelButtonText="Cancel"
          confirmButtonText="Subscribe"
          color="green"
          onClose={() => setShowNotice(false)}
      /> }
      <div className="flex flex-col gap-4">
        <h3>{buttonText || (update ? "Update Your Subscription" : 'Finalize Your Subscription!')}</h3>
        {/* <div className="flex flex-col gap-2 p-2 bg-light-light-grey rounded">
          <div><small>Reservation Fee for ColdAcre container farm</small></div>
          <div><span className="font-medium">Total: ${paymentIntent.amount/100.0}</span>{cost}</div>
        </div> */}
        {
          coupons &&
          <div className="flex flex-col gap-1">
            <strong>Coupon</strong>
            <input className="border rounded border-2 p-2" onBlur={(e) => setPromoCode(e.target.value)}/>
            {
              promoCode &&
              <Message positive={data && data.getPromoCode} className="text-center">
                {data && data.getPromoCode ?
                  <>
                    <FontAwesomeIcon icon={faCircleCheck}/>&nbsp;
                    {data.getPromoCode.coupon.name}
                  </>
                  : 
                  <>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;
                    Invalid Coupon
                  </>
                }
              </Message>
            }
          </div>
        }
        <div className="flex justify-between items-center">
          <strong>Card</strong>
          { paymentMethods && <ButtonPrimary size="mini" onClick={() => setShowNewPayment(!showNewPayment)}>{ showNewPayment ? 'Saved Card' : 'New Card' }</ButtonPrimary> }
        </div>
        {
          paymentMethods && !showNewPayment ?
            <div className="flex flex-column gap-2 mb-3">
              {paymentMethods.map((method, index) => <PaymentMethod key={index} method={method} selected={savedPaymentMethod} setPaymentMethod={setSavedPaymentMethod} removePaymentMethod={handleRemovePaymentMethod}/>)}
            </div>
            :
            <>
              <div>
                <PaymentElement id="card-element" />
              </div>
            </>
        }
        <Checkbox
          name="terms"
          checked={paymentInfo.terms}
          className="mr-2"
          onChange={() => {
            setPaymentInfo({ ...paymentInfo, terms: !paymentInfo.terms });
          }}
          text={
            <label>
              I have read and agree to the&nbsp; 
              <a href={'/terms-and-conditions'} rel="noreferrer" target="_blank">Terms and Conditions</a>,&nbsp; 
            </label>
          }
          required
        />
        <ButtonPrimary
          margin
          primary basic
          disabled={!validateForm() || !valid}
          onClick={() => setShowNotice(true)}
        >
          {loading || loadingOverride || !paymentIntent ?
            <Loading/>
            : (buttonText || (update ? 'Update Subscription' : 'Start Subscription'))
          }
        </ButtonPrimary>
        {
          message.content &&
          <Message attached='bottom' negative={!message.success} positive={message.success}>
            {message.success ? 
              <FontAwesomeIcon className="text-xs text-green-400" icon={faCheck} />
              :
              <FontAwesomeIcon className="text-xs text-green-400" icon={faTimes} />
            }&nbsp;
            {message.content}
          </Message>
        }
      </div>
    </>
  );
}

export default SubscriptionStripeCardSetupForm;

