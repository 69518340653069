import React, { useMemo, useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import micros from 'assets/images/winterharvest/Micros.jpg';
import { useEffect } from 'react';

const unitPrice = (price) => {
  if (price.nickname && price.nickname.toLowerCase() === "weekly") {
    return Number(price.unit_amount)/2/100
  }
  return Number(price.unit_amount)/100
}

export default function Product({product, updateProducts, quantityDefault, priceDefault, items, small, oneTime, summary, interval, medium, hidden}) {
  const prices = useMemo(() => {
    let prices =  product.prices
      .filter(price => price?.active && price.recurring && price.recurring.interval === 'week' && price.recurring.interval_count == 2 && (interval ? (price.nickname || "").toLowerCase() === interval : true))
      .sort((priceA, priceB) => {
        if (priceA.nickname > priceB.nickname) return -1;
        else if (priceA.nickname < priceB.nickname) return 1;
        return 0;
      })
      if (priceDefault && !prices.find(price => price.id === priceDefault.id)) {
        return [...prices, priceDefault];
      }
      return prices
  }, [product, interval]);

  const [price, setPrice] = useState((priceDefault && {...priceDefault, product}) || (prices[0] && {...prices[0], product}) || {});
  useEffect(() => {
    if (priceDefault) {
      setPrice({...priceDefault, product});
    } else {
      setPrice((prices[0] && {...prices[0], product}) || {})
    }
  }, [priceDefault, product, prices]);
  
  quantityDefault = quantityDefault || 0;
  const updatePrice = (id) => {
    let price = prices.find(product => product.id == id);
    price = {...price, product}
    setPrice(price);
    updateProducts({[product.id]: {price, quantity: items[product.id] ? items[product.id].quantity : 0 }});
  }

  useEffect(() => {
    if (items[product.id] && (!items[product.id].price?.active)){
      updatePrice(prices.find(price => (price.nickname.toLowerCase() == items[product.id].price.nickname.toLowerCase()))?.id);
    }
  }, [items]);

  const addProduct = () => {
    updateProducts({[product.id]: {price, quantity: Number(quantityDefault) + 1}});
  }

  const removeProduct = () => {
    updateProducts({[product.id]: {price, quantity: Math.max(Number(quantityDefault) - 1,0)}});
  }

  return !summary || (items[product.id] && items[product.id].quantity > 0) ? (
    <div className={`flex ${small ? 'flex-row-reverse gap-2' : medium ? `gap-4 grow justify-between rounded ${hidden ? 'p-0 border-0 h-almost0 overflow-hidden -m-b' : 'p-4 border'} border-med-grey` : ''} ${summary ? 'border-b' : ''}`}>
      <div className={`flex justify-between ${small ? 'w-full items-center' : medium ? 'w-80' :'w-1/2 gap-4 items-center'} sm:w-full`}>
        <div className={`flex flex-col w-full`}>
          <div className={`relative`}>
            <div className="flex">
              <div className={`flex justify-center mr-3 hidden sm:block`}>
                <div className={`bg-primary w-16 h-16 bg-cover rounded-xl bg-center`} style={{backgroundImage: `url(${product.image})`}}>
                </div>
              </div>
              <div>
                <div>
                  <label className="mr-10 inline-block">{product.name}{product.unit_label ? ` - ${product.unit_label}` : ""}{small && !summary && <span className="absolute top-0 right-2 text-lg"> {quantityDefault || 0}</span>}</label>
                  {product.metadata && product.metadata.vendor && <p className="-mt-2 text-primary">{product.metadata.vendor}</p>}
                </div>
                <p>
                  <small className="font-bold">CA${unitPrice(price)}/each</small>
                </p>
              </div>
            </div>
            {(!small || summary) && <h1 className="absolute right-2 top-0">{quantityDefault || 0} {summary && <span className="text-sm tracking-tight">${(items[product.id].quantity * price.unit_amount/100).toFixed(2)}</span>}</h1>}
          </div>
          <p className="grow">
            {product.description}
          </p>
          {/* <ul>
              <li>Better regulation of temperature</li>
              <li>Pest management</li>
              <li>Space for prep</li>
          </ul> */}
          {/* <small className="text-center">
              * Prices shown exclude transport and may vary by territory/province.
          </small> */}
          {!oneTime && <div>
            <h3>Delivery</h3>
            <select  className="w-full border" onChange={(e)=>updatePrice(e.target.value)}>
              {prices.map(currentPrice => (
                <option key={currentPrice.id} value={currentPrice.id} selected={currentPrice.id === price.id}>{currentPrice.nickname}</option>
              ))}
            </select>
          </div>}
          {!summary && !small && <div className="flex gap-2 justify-center">
            <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={addProduct}>Add</ButtonPrimary>
            {quantityDefault > 0 && <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={removeProduct}>Remove</ButtonPrimary>}
          </div>}
        </div>
        {!summary && small && <div className="flex gap-2 justify-center w-full">
          <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={addProduct}>Add</ButtonPrimary>
          {quantityDefault > 0 && <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={removeProduct}>Remove</ButtonPrimary>}
        </div>}
      </div>
      <div className={`flex justify-center ${small ? 'w-16' : medium ? 'w-32' :'w-1/2'} sm:hidden`}>
        <div className={`bg-primary ${small ? 'w-16 h-16' : medium ? 'w-32 h-32' : 'w-80 h-80'} bg-cover rounded-xl bg-center`} style={{backgroundImage: `url(${product.image})`}}>
        </div>
      </div>
    </div>
  )
  :
  <></>
}
