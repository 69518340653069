import React from 'react'

export default function UsersFilters({filters, setFilters, clientFilters, setClientFilters}) {
  return (
    <div className="border flex p-2 gap-4">
      <div> 
        <strong>Search</strong>
        <hr></hr>
        <div className="flex gap-2">
          <input className="border px-2" onChange={(e) => setFilters({...filters, search: e.target.value})} value={filters.search}/>
        </div>
      </div>
      <div> 
        <strong>Promotions</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" defaultChecked={filters.unsubscribePromotions === ""} onClick={() => setFilters({...filters, unsubscribePromotions: ""})} type="radio" id="all" name="subscribe" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="all">All</label>
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={filters.unsubscribePromotions === "false"} onClick={() => setFilters({...filters, unsubscribePromotions: "false"})} type="radio" id="subscribed" name="subscribe" value="subscribed"/>
            <label className="font-normal cursor-pointer" htmlFor="subscribed">Subscribed</label> 
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={filters.unsubscribePromotions === "true"} onClick={() => setFilters({...filters, unsubscribePromotions: "true"})} type="radio" id="not-subscribed" name="subscribe" value="not-subscribed"/>
            <label className="font-normal cursor-pointer" htmlFor="not-subscribed">Not subscribed</label>
          </div>
        </div>
      </div>
      <div> 
        <strong>Subscription</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" defaultChecked={clientFilters.subscribed === "all"} onClick={() => setClientFilters({...clientFilters, subscribed: "all"})} type="radio" id="allsubscriptions" name="subscription" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="allsubscriptions">All</label>
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={clientFilters.subscribed === true} onClick={() => setClientFilters({...clientFilters, subscribed: true})} type="radio" id="subscription" name="subscription" value="subscription"/>
            <label className="font-normal cursor-pointer" htmlFor="subscription">Subscribed</label> 
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={clientFilters.subscribed === false} onClick={() => setClientFilters({...clientFilters, subscribed: false})} type="radio" id="not-subscription" name="subscription" value="not-subscription"/>
            <label className="font-normal cursor-pointer" htmlFor="not-subscription">Not subscribed</label>
          </div>
        </div>
      </div>
    </div>
  )
}
