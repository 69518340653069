import { gql, useMutation } from '@apollo/client';
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MiniButton from 'components/common/Button/MiniButton'
import Loading from 'components/common/Loading';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PauseModal from './PauseModal';


const PAUSE_SUBSCRIPTION = gql`
  mutation PauseSubscription($iterations: Float! $startIteration: Float!) {
    pauseSubscription(iterations: $iterations, startIteration: $startIteration) {
      id
    }
  }
`;

export default function PauseSub({subscription}) {
  const [showPause, setShowPause] = useState(false);
  const navigate = useNavigate();
  const [pauseSubscriptionMutation, { loading:pauseLoading }] = useMutation(PAUSE_SUBSCRIPTION);

  const pauseSubscription = (startIteration, iterations) => {
    pauseSubscriptionMutation({variables: {startIteration, iterations}})
    .then(res => {
        setShowPause(false);
        window.location.href = '/my-subscription';
      })
    .catch(err => {
      setShowPause(false);  
    })
  }
  
  return (
    <>
        {showPause && <PauseModal
            subscription={subscription}
            onConfirm={pauseSubscription}
            loading={pauseLoading}
            onClose={() => setShowPause(false)}
        /> }
        <MiniButton onClick={() => setShowPause(true)} disabled={pauseLoading} className="text-yellow border-yellow" colored>{pauseLoading ? <Loading/> : <><FontAwesomeIcon icon={faPause} /> Pause subscription</>}</MiniButton>
    </>
  )
}
