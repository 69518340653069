import React, {useCallback, useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faUser, faTimes, faCircleLeft, faMask, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import MiniButton from 'components/common/Button/MiniButton';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Input from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useRef } from 'react';
import { useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';
import Phase from './Phase';

const GET_PLANT = gql`
  query GetPlant($id: String) {
    plant(id: $id) {
      date,
      name,
      description,
      image,
      growthPhases {
        name,
        duration,
        icon,
        dataName,
        title,
        instructions,
      }
    }
  }
`;

const UPDATE_PLANT = gql`
  mutation UpdatePlant($id: String!, $name: String!, $description: String!, $image: String!, $growthPhases: JSON) {
    updatePlant(id: $id, name: $name, description: $description, image: $image, growthPhases: $growthPhases) {
      id
    }
  }
`;

export default function Plant({newPlant, loadingOverride, addPlant}) {
  const {currentUser} = useContext(AppContext);
  const params = useParams();
  const { loading, error, data } = useQuery(GET_PLANT, {
    variables: {id: params.id},
    skip: !params.id
  });
  const [plant, setPlant] = useState({});
  const [edit, setEdit] = useState(newPlant);

  const [updatePlantMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_PLANT);

  useEffect(() => {
    if (data && data.plant) {
      setPlant(data.plant);
    }
  }, [data]);

  const toggleEdit = () => {
    if (edit) {
      setPlant(data.plant);
    }
    setEdit(!edit);
  }

  const updatePlant = () => {
    if (newPlant) {
      return addPlant(plant);
    }
    updatePlantMutation({variables: {id: params.id, ...plant}})
      .then(res => {
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }

  const updatePhase = (phase, i) => {
    setPlant((plant) => {
      let newPhases = [...plant.growthPhases];
      newPhases[i] = phase;
      return {...plant, growthPhases: newPhases}
    })
  }

  const addPhase = () => {
    setPlant((plant) => {
      return {...plant, growthPhases: [...(plant.growthPhases || []), {}]}
    })
  }

  return (
    <PaddedPage className="w-full">
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            {newPlant ? "New Plant" : "Plant"}
            {
              !newPlant &&
              <sup>
                <FontAwesomeIcon onClick={toggleEdit} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
              </sup>
            }
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          {edit ?
            <>
              <label>Image</label>
              <Input valueOverride={plant.image} onChange={(image) => setPlant({...plant, image})}/> 
            </>
            :
            <img className={"w-1/2 rounded border border-background-dark"} src={plant.image} alt="plant preview"/>
          }
        </div>
        <div className="flex flex-col">
          <label>Name</label>
          {edit ?<Input valueOverride={plant.name} onChange={(name) => setPlant({...plant, name})}/> : <p>{plant.name}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Description</label>
          {edit ?<Input valueOverride={plant.description} onChange={(description) => setPlant({...plant, description})}/> : <p>{plant.description}&nbsp;</p>}
        </div>
        <div>
          <h3>Phases</h3>
          <hr></hr>
          <div className="p-2 flex flex-col gap-2">
            {
              (plant.growthPhases && plant.growthPhases.length > 0)  ? 
                plant.growthPhases.map((phase, i) => <Phase key={i} phase={phase} edit={edit} updatePhase={(phase) => updatePhase(phase, i)} />)
                :
                <small className="text-med-grey">No Phases</small>
            }
            {newPlant && <MiniButton className="self-start" onClick={addPhase}>Add Phase</MiniButton>}
          </div>
        </div>
        {edit && <ButtonPrimary className="self-start" disabled={updateLoading || loadingOverride} onClick={updatePlant}>{(updateLoading || loadingOverride) ? <Loading/> : newPlant ? "Add Plant" : "Update Plant"}</ButtonPrimary>}
      </div>
    </PaddedPage>
  )
}
