import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useContext, useEffect } from 'react'
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPenToSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';
import MultipleSelect from 'components/inputs/MultipleSelect';
import { useMemo } from 'react';
import Quantity from './Quantity';
import Message from 'components/common/Message';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import LinkedProduct from './LinkedProduct';
import useCurrentUser from 'hooks/useCurrentUser';
import AppContext from 'contexts/AppContext';
import Order from '../order/Order';
import ConfirmNotice from 'components/notices/ConfirmNotice';

const GET_INVENTORYITEM = gql`
  query GetInventoryItem($id: String!) {
    inventoryItem(id: $id) {
      id,
      name,
      organizationId,
      tags {
        name, 
        id
      }
      stripeProductId,
      product {
        description,
        image,
        name,
        metadata,
        prices {
          unit_amount,
          id
        }
      },
      notify,
      quantity {
        quantity, 
        createdAt
      }
    }
  }
`;

const UPDATE_INVENTORYITEM = gql`
  mutation UpdateInventoryItem($id: String!, $name: String, $tags: [String], $notify: String, $stripeProductId: String) {
    updateInventoryItem(id: $id, name: $name, tags: $tags, notify: $notify, stripeProductId: $stripeProductId) {
      id,
    }
  }
`;

const DELETE_INVENTORYITEM = gql`
  mutation DeleteInventoryItem($id: String!) {
    deleteInventoryItem(id: $id) {
      id,
    }
  }
`;

const GET_TAGS = gql`
  query GetTags($organizationId: String){
    tags(organizationId: $organizationId) {
      id,
      name,
    }
  }
`;

const GET_INVENTORY_ORDERS = gql`
  query GetInventoryOrders($priceId: String) {
    inventoryOrders(priceId: $priceId) {
      id,
      quantity,
      priceId,
      productId,
      status,
      invoiceId,
      createdAt,
    }
  }
`;

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
    }
  }
`;

export default function InventoryItem() {
  const params = useParams();
  const { loading, error, data, refetch } = useQuery(GET_INVENTORYITEM, {variables: {id: params.id}});
  const [inventoryItem, setInventoryItem] = useState({});
  const {currentUser} = useContext(AppContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState();
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const supplyItem = inventoryItem.product && inventoryItem.product.metadata.subtype === "supplies";
  const { loading:loadingOrders, error:errorOrders, data:inventoryOrders } = useQuery(GET_INVENTORY_ORDERS, {
    variables: {priceId: inventoryItem.product && inventoryItem.product.prices[0].id},
    skip: !supplyItem
  });
  const { data: tagsData } = useQuery(GET_TAGS, {
    skip: !(data && data.inventoryItem),
    variables: {organizationId: data && data.inventoryItem.organizationId},
  });
  const { loadingProducts, errorProducts, data:productsRes } = useQuery(GET_PRODUCTS, {
    skip: !edit,
    variables: { query: `-metadata['subtype']:'delivery' metadata['organizationId']:null` },
  });
  const [updateInventoryItemMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_INVENTORYITEM);
  const [deleteInventoryItemMutation, { loading:deleteLoading }] = useMutation(DELETE_INVENTORYITEM);

  useEffect(() => {
    if (data && data.inventoryItem) {
      setInventoryItem({...data.inventoryItem, tags: data.inventoryItem.tags.map(tag => tag.id)});
    }
  }, [data]);
  const toggleEdit = () => {
    if (edit) {
      setInventoryItem(data.inventoryItem);
    }
    setEdit(!edit);
  }

  const updateInventoryItem = () => {
    updateInventoryItemMutation({variables: {...inventoryItem}})
      .then(res => {
        refetch();
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }
  
  const deleteInventoryItem = () => {
    deleteInventoryItemMutation({variables: { id: inventoryItem.id}})
      .then(res => {
        navigate("/admin/inventory")
      })
      .catch(() => {
        reset();
      })
  }

  return (
    <PaddedPage className="w-full">
      {showConfirmDelete && <ConfirmNotice
        title={`Delete Inventory Item?`}
        message={`This will remove all inventory tracking of this item.`}
        onConfirm={deleteInventoryItem}
        onClose={() => setShowConfirmDelete(false)}
      /> }
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            InventoryItem
            <sup>
              <FontAwesomeIcon onClick={toggleEdit} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
            </sup>
            <sup>
              <FontAwesomeIcon onClick={() => setShowConfirmDelete(true)} className={`text-l cursor-pointer text-red`} icon={faTimesCircle}/>
            </sup>
          </h2>
          <hr className="mb-2"></hr>
        </div>
        {
          inventoryItem.notify && inventoryItem.notify > inventoryItem.quantity.quantity ? 
          <Message>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            Warning Low Stock
          </Message>
          :
          <></>
        }
        <div className="flex flex-col">
          <label>Name</label>
          <hr className="mb-2"></hr>
          {edit ?<Input valueOverride={inventoryItem.name} onChange={(name) => setInventoryItem({...inventoryItem, name})}/> : <p>{inventoryItem.name}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Quantity</label>
          <hr className="mb-2"></hr>
          {inventoryItem.quantity && <Quantity item={inventoryItem}/>}
        </div>
        <div className="flex flex-col">
          <label>Notify</label>
          <hr className="mb-2"></hr>
          <div>
            <input onClick={() => setInventoryItem({...inventoryItem, notify: 5})} disabled={!edit} checked={inventoryItem.notify} type="checkbox" id="yes" name="yes" value="yes"/>
            <label className="text-sm font-md" for="yes"> Yes</label>
            <br></br>
            <input onClick={() => setInventoryItem({...inventoryItem, notify: 0})} disabled={!edit} checked={!inventoryItem.notify} type="checkbox" id="no" name="no" value="no"/>
            <label className="text-sm font-md" for="no"> No</label>
          </div>
          {
            inventoryItem.notify ?
            (edit ?
              <div>
                <p>Notify when stock falls below...</p>
                <Input type="number" valueOverride={inventoryItem.notify} onChange={(notify) => setInventoryItem({...inventoryItem, notify})}/>
              </div>
             : <p>Notify below {inventoryItem.notify} quantity&nbsp;</p>)
            : ''
          }
        </div>
        <div className="flex flex-col">
          <label>Tags</label>
          <hr className="mb-2"></hr>
          <MultipleSelect readOnly={!edit} options={tagsData ? tagsData.tags.map(tag => ({value: tag.id, name: tag.name})) : []} initialValue={inventoryItem.tags} onChange={(tags) => setInventoryItem({...inventoryItem, tags})}/>
        </div>
        {
          supplyItem && currentUser.organizationId &&
          <div className="flex flex-col">
            <label>Product Orders</label>
            <hr className="mb-2"></hr>
            <div className='flex flex-col gap-1'>
              {inventoryOrders && inventoryOrders.inventoryOrders.length > 0 ? inventoryOrders.inventoryOrders.map(order => 
                <Order order={order} product={inventoryItem.product} ></Order>
              )
              :
              <h2>-</h2>
              }
            </div>
          </div>
        }
        <div className="flex flex-col">
          <label>Linked Product</label>
          <hr className="mb-2"></hr>
          {
            edit ?
            <select 
              name="product" 
              id="product" 
              value={inventoryItem.stripeProductId}
              onChange={e => setInventoryItem({...inventoryItem, stripeProductId: e.target.value})}
            >
              <option value="">none</option>
              {
                productsRes &&  productsRes.products.map(product => 
                  <option 
                    key={product.id} 
                    value={product.id}
                  >
                    {product.name}
                  </option>
                )
              }
            </select>
            :
            <>
              {
              inventoryItem.product ?
                <LinkedProduct product={inventoryItem.product}/>
              :
                <div>
                  none
                </div>
              }
            </>
          }
        </div>
        {edit && <ButtonPrimary className="self-start" disabled={updateLoading} onClick={updateInventoryItem}>{updateLoading ? <Loading/> : "Update InventoryItem"}</ButtonPrimary>}
      </div>
    </PaddedPage>
  )
}
