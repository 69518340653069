import React, {useContext, useMemo} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faFilter, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import { useState } from 'react';
import ExportCSC from 'components/common/ExportCSC';
import OrganizationSelect from 'components/common/OrganizationSelect';
import DetailedUsersFilters from './DetailedUsersFilters';
import DetailedUser from 'components/users/DetailedUser';
import Loading from 'components/common/Loading';

const GET_USERS = gql`
  query GetUsers($organizationId: String, $filters: JSONObject){
    users(organizationId: $organizationId, filters: $filters) {
      id,
      createdAt,
      stripeCustomerId,
      firstName,
      lastName,
      phoneNumber,
      addressOne, 
      addressTwo,
      email,
      type,
      hasSubscribed,
      activeSubscription,
      organization,
      unsubscribeNotifications,
      subscriptionLocation,
      unsubscribePromotions,
      cancelDate,
      cancellationReason {
        reason
      }
      subscription {
        items {
          price {
            product {
              id
            }
          }
        }
      }
      firstSubscribed,
    }
  }
`;

const GET_CREDITS = gql`
  query GetCustomerCredits($filters: JSONObject){
    customerCredits(filters: $filters) {
      id,
      userId,
      createdAt,
      issuedBy,
    }
  }
`;

let filtersInitial = () => {
  let curr = new Date();
  let first = curr.getDate() - curr.getDay();
  let last = first + 6;
  
  let firstday = new Date(curr.setDate(first)).toISOString().split('T')[0];
  let lastday = new Date(curr.setDate(last)).toISOString().split('T')[0];
  return {from: firstday, to: lastday};
}

export default function DetailedUsers() {
  const [filters, setFilters] = useState({});
  const [clientFilters, setClientFilters] = useState(filtersInitial());
  const [showFilters, setShowFilters] = useState(true);
  const {setActivePage, organizationId} = useContext(ActivePageContext);
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {filters: filters, organizationId}
  });
  const { loading:loadingCharges, error:errorCharges, data:dataCredits } = useQuery(GET_CREDITS, {
    variables: {filters: clientFilters}
  });
  const users = useMemo(() => {
    let users = data?.users?.filter(user => {
      let keep = true;
      if (clientFilters.rangeFor === 'cancelled') {
        keep = user.cancelDate && new Date(user.cancelDate * 1000) < new Date(clientFilters.to) && (new Date(user.cancelDate * 1000) > new Date(clientFilters.from))
      } else if (clientFilters.rangeFor === 'subscribed') {
        keep = user.firstSubscribed && new Date(user.firstSubscribed * 1000) < new Date(clientFilters.to) && (new Date(user.firstSubscribed * 1000) > new Date(clientFilters.from))
      }
      if (clientFilters.subscriptionLocation) {
        keep = keep && user.subscriptionLocation?.toLowerCase() == clientFilters.subscriptionLocation?.toLowerCase();
      }
      if (clientFilters.cancelled) {
        keep = keep && user.hasSubscribed && !user.activeSubscription && (clientFilters.cancelled === 'cancelledNoReason' ? !user.cancellationReason?.reason : true);
      }
      if (clientFilters.stripeProductId) {
        keep = keep && user.subscription?.items?.find(item => item.price?.product?.id == clientFilters.stripeProductId);
      }
      return keep;
    });
    let balanceLookup = {};
    for(let balance of dataCredits?.customerBalances || []) {
      if (!balanceLookup[balance.customer]) {
        balanceLookup[balance.customer] = [];
      }
      balanceLookup[balance.customer].push(balance);
    }
    return users?.map(user => ({...user, credits: balanceLookup[user.stripeCustomerId] || []}))
  }, [data, clientFilters, dataCredits]);

  setActivePage(null);
  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end justify-between">
        <div className="flex gap-2 items-end">
          <h2>Users</h2>
          <div className="flex items-end h-full">
            <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
            <FontAwesomeIcon className="text-xs" icon={showFilters ? faCaretUp : faCaretDown} />
          </div>
          <OrganizationSelect/>
        </div>
        {users && 
          <ExportCSC 
            loading={loading}
            data={users?.map((user, index) => (
              {
                index,
                ...user, 
                referralCode: user.stripeCustomerId && user.stripeCustomerId.replace('cus_', ''), 
                createdAt: user.createdAt && new Date(Number(user.createdAt)), 
                cancelDate: user.cancelDate && new Date(Number(user.cancelDate)), 
                cancelReason: user.cancellationReason?.reason || '', 
                firstSubscribed: user.firstSubscribed && new Date(Number(user.firstSubscribed))}))
              } 
            filename="winterharvestUsers"
          />
        }
      </div>
      {
        showFilters && <DetailedUsersFilters filters={filters} setFilters={setFilters} clientFilters={clientFilters} setClientFilters={setClientFilters} />
      }
      <div>
        <table>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Phone</th>
            <th>Subscribed At</th>
            <th>Cancelled At</th>
            <th>Cancel Reason</th>
            <th>Credits</th>
            <th>Credits Value</th>
          </tr>
          {
            loading ? 
            <tr>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
              <td><Loading/></td>
            </tr>
            :
            users?.map((user, index) => <DetailedUser index={index} key={user.id} grey={index%2} user={user}/>)
          }
          {
            (loading || users?.length > 0) ||
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          }
        </table>
      </div>
    </div>
  )
}
