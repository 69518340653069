import React from 'react'
import { faArrowLeft, faArrowRight, faCircleArrowLeft, faCircleArrowRight, faHouse, faLaptop, faLocationDot, faPhone, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderSheet from './OrderSheet';
import { useState } from 'react';
import Loading from 'components/common/Loading';

export default function OrderSheets({sheet, routeLoading}) {
  let [showIndex, setShowIndex] = useState(0);
  return (
    <div className={`rounded border border-dark p-4 max-w-200 print-no-break ${(sheet.orders.length === 1 && sheet.orders[0].metadata.paused === 'true') ? "hide-print" : ''}`}>
      <div className="flex justify-between">
        <h3>{sheet.user.firstName} {sheet.user.lastName}</h3>
        {sheet.driver && <div className="flex items-center gap-2">
          <small className="text-primary">{sheet.driver}</small><h4><FontAwesomeIcon icon={faTruck}/> {sheet.stopNumber}</h4>
        </div>}
        {routeLoading && <div className="flex items-center gap-2">
          <h4><FontAwesomeIcon icon={faTruck}/> <Loading/></h4>
        </div>}
      </div>
      <hr></hr>
      {sheet.deliveryZone && 
        <div className="flex gap-2 mb-2 text-sm">
          <p><FontAwesomeIcon icon={faLocationDot}/> {sheet.deliveryZone}</p>
        </div>
      }
      <div className="flex gap-2 mb-2 text-sm">
        <p><FontAwesomeIcon icon={faHouse}/> {sheet.user.addressOne} {sheet.user.addressTwo}</p>
        <p><FontAwesomeIcon icon={faPhone}/> {sheet.user.phoneNumber} </p>
        <p><FontAwesomeIcon icon={faLaptop}/> {sheet.user.email}</p>
      </div>
      <div className="flex items-center cursor-pointer gap-2">
        {sheet.orders.length > 1 && <FontAwesomeIcon className=""  onClick={() => setShowIndex(((showIndex || sheet.orders.length) - 1) % sheet.orders.length)} icon={faCircleArrowLeft}/>}
        {
          sheet.orders.map((order, index) => <OrderSheet key={index} show={index === showIndex} order={order}/>)
        }
        {sheet.orders.length > 1 && <FontAwesomeIcon className=""  onClick={() => setShowIndex((showIndex + 1) % sheet.orders.length)} icon={faCircleArrowRight}/>}
      </div>
    </div>
  )
}
