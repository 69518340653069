import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useNavigate } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import MultipleSelect from 'components/inputs/MultipleSelect';

const ADD_TAG = gql`
  mutation AddTag($name: String!, $organizationId: String) {
    addTag(name: $name, organizationId: $organizationId) {
      id,
    }
  }
`;

export default function NewTag() {
  const {currentUser} = useContext(AppContext);
  const [tag, setTag] = useState({});
  const {setActiveSubpage, organizationId} = useContext(ActivePageContext);
  const navigate = useNavigate();
  const [addTagMutation, { loading:updateLoading, reset }] = useMutation(ADD_TAG);
  setActiveSubpage("newTag");

  const valid = () => {
    return tag.name;
  }

  const addTag = () => {
    addTagMutation({variables: {...tag, organizationId}})
      .then(res => {
        navigate(`/admin/inventory/new`)
      })
      .catch(() => {
        reset();
      })
  }


  return (
    <PaddedPage className="w-full">
      {

      }
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            New Tag
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          <label>Name</label>
          <Input valueOverride={tag.name} onChange={(name) => setTag({...tag, name})}/>
        </div>
        <ButtonPrimary className="self-start mt-2" disabled={!valid() || updateLoading} onClick={addTag}>{updateLoading ? <Loading/> : "Add Tag"}</ButtonPrimary>
      </div>
    </PaddedPage>
  )
}
