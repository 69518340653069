import React, {useContext, useMemo, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import PlantManagement from 'components/plantManagements/PlantManagement';
import GrowthCalandar from 'components/calendar/GrowthCalendar';
import PlantManagementList from './PlantManagementList';
import { introspectionFromSchema } from 'graphql';
import OrganizationSelect from 'components/common/OrganizationSelect';
import ActivePageContext from 'contexts/ActivePageContext';

const GET_PLANTMANAGEMENTS = gql`
  query GetPlantManagements($filters: JSONObject, $organizationId: String){
    plantManagements(filters: $filters, organizationId: $organizationId) {
      id,
      notes,
      phaseStates {
        name,
        startTime,
        endTime,
        notes,
        value,
      },
      plant {
        name,
        growthPhases {
          name,
          duration,
          icon,
          dataName,
          title,
          instructions,
        },
      }
    }
  }
`;

const plantManagementsToEvents = (plantManagements) => {
  let events = [];
  for (let plantManagement of plantManagements) {
    let prevStartTime;
    for (let [i, phaseState] of Object.entries(plantManagement.phaseStates.map(phase => ({...phase})))) {
      let growthPhase = plantManagement.plant.growthPhases.find(phase => phase.name === phaseState.name);
      if (phaseState.startTime) {
        events.push(
          {
            date: new Date(Number(phaseState.startTime)),
            type: phaseState.name,
            icon: growthPhase.icon,
            name: phaseState.name,
            data: plantManagement,
            tag: plantManagement.id.slice(-5)
          },
        )
      } else {
        let lastPhase = plantManagement.phaseStates[i - 1];
        if (!lastPhase) break;
        let lastGrowthPhase = plantManagement.plant.growthPhases.find(phase => phase.name === lastPhase.name);
        prevStartTime = Number(lastPhase.startTime || prevStartTime) + (lastGrowthPhase.duration * 24 * 60 * 60 *1000);
        events.push(
          {
            date: new Date(Number(prevStartTime)),
            type: phaseState.name,
            icon: growthPhase.icon,
            name: phaseState.name,
            data: plantManagement,
            tag: plantManagement.id.slice(-5)
          },
        )
      }
    }
  }
  return events;
}

export default function PlantManagements() {
  const [filters, setFilters] = useState({});
  const {organizationId} = useContext(ActivePageContext);
  const { loading, error, data } = useQuery(GET_PLANTMANAGEMENTS, {
    skip: !filters.date,
    variables: {filters, organizationId}
  });
  const { data:inProgressPlants } = useQuery(GET_PLANTMANAGEMENTS, {
    variables: {filters: {inProgress: true}, organizationId}
  });

  const events = useMemo(() => {
    if (data && data.plantManagements) {
      return plantManagementsToEvents(data.plantManagements);
    }
    return [];
  }, [data])

  return (
    <div className="p-4 flex flex-col gap-8">
      <div className="flex gap-2 items-end">
        <h2>Plant Management</h2>
        <OrganizationSelect defaultOrg={"ColdAcre"}/>
      </div>
      <div className="flex gap-8 w-full">
        <GrowthCalandar events={events} onMonthChange={(date) => setFilters({...filters, date})}>
          <PlantManagementList 
            inProgressPlantManagements={inProgressPlants && inProgressPlants.plantManagements}
          />
        </GrowthCalandar>
      </div>
    </div>
  )
}
