import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope, faEarth, faSeedling } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

export default function Plant({plant}) {
  console.log("plnat", plant);
  return (
    <Link className="no-underline" to={`/admin/plants/${plant.id}`}>
      <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon className="" icon={faSeedling} />
            <strong>{plant.name}</strong>
            {/* <div className="flex items-center gap-2 ml-4">
              <small><FontAwesomeIcon className="" icon={faEnvelope} /> {plant.description}</small>
            </div> */}
          </div>
          <div className="flex items-center gap-2">
            {plant.growthPhases.length} Phases:
            {plant.growthPhases.map((phase,i) => {
              return (
                <React.Fragment key={i}>
                  <div className="rounded bg-primary text-xs px-1 text-white">
                    {phase.name}
                  </div>
                  {
                    plant.growthPhases.length !== (i + 1) &&
                    <FontAwesomeIcon className="text-sm" icon={faArrowAltCircleRight}/>
                  }
                </React.Fragment>
              )
            })}
          </div>
      </div>
    </Link>
  )
}
