import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope, faEarth, faSeedling, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faArrowAltCircleRight, faClock } from '@fortawesome/free-regular-svg-icons';

export default function PlantManagement({plantManagement, phase, date}) {

  let currentPhaseState = [...plantManagement.phaseStates].sort((a,b) => (a.startTime - b.startTime)).reduce((prev, current) => {
    if (phase ? current.name === phase : current.startTime) {
      return current;
    }
    return prev;
  }, null);

  let currentGrowthPhase = currentPhaseState && plantManagement.plant.growthPhases.find(phase => phase.name === currentPhaseState.name);

  return (
    <Link className="no-underline" to={`/admin/plant-management/${plantManagement.id}`}>
      <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
          <div className="flex items-center gap-2 relative">
            <FontAwesomeIcon className="" icon={faSeedling} />
            <strong>{plantManagement.plant.name}</strong>
            {/* <div className="flex items-center gap-2 ml-4">
              <small><FontAwesomeIcon className="" icon={faEnvelope} /> {plant.description}</small>
            </div> */}
            <span className="absolute right-0 text-xxs -bottom-1.5 text-primary font-bold">#{plantManagement.id.slice(-5)}</span>
          </div>
          {currentPhaseState &&
            <div className="flex items-center gap-2">
              <div className="rounded bg-primary text-xs px-1 text-white flex items-center gap-1">
                {currentGrowthPhase.icon}
                {currentPhaseState.name}
                <span className="text-yellow">{currentPhaseState.value}</span>
              </div>
              <div className="text-sm flex gap-1 items-center">
                <FontAwesomeIcon icon={faClock}/>
                <span>{new Date(Number(date || currentPhaseState.startTime)).toISOString().split('T')[0]}</span>
                <FontAwesomeIcon icon={faArrowRight}/>
                <span>{new Date(Number(date || currentPhaseState.startTime) + currentGrowthPhase.duration * 24 * 60 * 60 *1000).toISOString().split('T')[0]}</span>
              </div>
            </div>
          }
      </div>
    </Link>
  )
}
