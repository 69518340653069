import React, { useContext, useMemo, useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import micros from 'assets/images/winterharvest/Micros.jpg';
import { useEffect } from 'react';
import SubscriptionPaymentForm from 'components/payments/SubscriptionPaymentForm';
import AppContext from 'contexts/AppContext';
import { useMutation, gql, useQuery } from "@apollo/client";

const CREATE_INVENTORY_ORDER = gql`
  mutation CreateInventoryOrder($priceId: String!, $quantity: Float!, $paymentMethod: String) {
    createInventoryOrder(priceId: $priceId, quantity: $quantity, paymentMethod: $paymentMethod) {
      quantity,
      priceId,
      invoiceId,
      status,
      createdAt
    }
  }
`;


const unitPrice = (price) => {
  return Number(price.unit_amount)/100
}

export default function Product({product}) {
  const { currentUser } = useContext(AppContext);
  const [quantity, setQuantity] = useState(0);
  const [createInventoryOrdderMutation, { loading }] = useMutation(CREATE_INVENTORY_ORDER);

  const order = (paymentMethod) => {
    createInventoryOrdderMutation({ variables: {priceId: product.prices[0].id, quantity, paymentMethod} })
    .then(res => {
      setQuantity(0);
    })
  }

  return (
    <div>
      <div className={`flex flex-row-reverse gap-2 rounded border-2 p-2`}>
        <div className={`flex justify-between w-full items-center sm:w-full`}>
          <div className={`flex flex-col w-full`}>
            <div className="relative">
              <div className="flex">
                <div className={`flex justify-center mr-3 hidden sm:block`}>
                  <div className={`bg-primary w-16 h-16 bg-cover rounded-xl bg-center`} style={{backgroundImage: `url(${product.image})`}}>
                  </div>
                </div>
                <div>
                  <label className="mr-10 inline-block">{product.name}{product.unit_label ? ` - ${product.unit_label}` : ""}{product.metadata && product.metadata.subtype === "supplies" && <span className="text-sm"> x {quantity}</span>}</label>
                  <p>
                    <small className="font-bold">CA${unitPrice(product.prices[0])}/each</small>
                  </p>
                </div>
              </div>
            </div>
            <p className="grow">
              {product.description}
            </p>
          </div>
          {
            product.metadata && product.metadata.subtype === "supplies" && currentUser.organizationId &&
            <div className="flex gap-2 justify-center w-full">
              <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={() => setQuantity(quantity + 1)}>Add</ButtonPrimary>
              {quantity > 0 && <ButtonPrimary className="mt-2 w-2/3 self-center" onClick={() => setQuantity(quantity -1)}>Remove</ButtonPrimary>}
            </div>
          }
        </div>
        <div className={`flex justify-center w-16 sm:hidden`}>
          <div className={`bg-primary w-16 h-16 bg-cover rounded-xl bg-center`} style={{backgroundImage: `url(${product.image})`}}>
          </div>
        </div>
      </div>
      {
        quantity ?
        <SubscriptionPaymentForm valid={true} onSuccess={order} asAdmin={true} buttonText={"Order"}/>
        :
        ''
      }
    </div>
  )
}
