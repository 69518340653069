import React, {useState} from 'react'

export default function Checkboxes({text, initialValue, onChange, readOnly}) {
  const [value, setValue] = useState(initialValue || false);
  const updateValue = (val) => {
    setValue(val);
    onChange(val);
  }

  return (
    <form>
      <input 
        type="checkbox"   
        name={text} 
        readOnly={readOnly}
        value={value}
        checked={value}
        onChange={() => !readOnly && updateValue(!value)}
      />
      &nbsp;
      <label>{text}</label>
    </form>
  )
}
