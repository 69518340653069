import React, {useState, useContext} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link, useSearchParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import Message from 'components/common/Message';
import { useMutation, gql } from "@apollo/client";

const RESEND = gql`
  mutation ResendConfirmEmail($email: String!) {
    resendConfirmEmail(email: $email) {
      message
    }
  }
`;

const Login = ({inviteId}) => {
  const { login, loading } = useContext(AppContext);
  const [search] = useSearchParams();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmSend, setConfirmSent] = useState();
  const [resendMutation, { loading:loadingNewConfirm }] = useMutation(RESEND);

  const handleLogin = () => {
    login(email, password);
  }

  const handleResendConfirm = () => {
    if (email) {
      resendMutation({variables: { email: email}})
        .then(res => {
          setConfirmSent(true);
        })
    }
  }
  
  return (
    <PaddedPage className="flex justify-center">
      <div className="flex flex-col justify-center items-center gap-4 max-w-80">
        <h1>Login</h1>
        {search.get('confirm') == "true" &&
          <Message positive>
            Email Confirmed!
          </Message>
        }
        {search.get('confirm') == "false" &&
          <Message >
            {confirmSend ?
              "Confirmation email sent!"
            :
            <div className="flex flex-col items-center gap-2">
              Resend Confirmation Email?
              <ButtonPrimary disabled={!email || loadingNewConfirm} className="text-dark" onClick={handleResendConfirm}>Resend</ButtonPrimary>
            </div>
            }
          </Message>
        }
        {search.get('reset') &&
          <Message positive>
            Password Reset!
            Login with your new credentials.
          </Message>
        }
        <p className="text-sm">
          Dont have an account? Sign Up <Link to="/sign-up">Here</Link>
        </p>
        <div className="flex flex-col w-full">
          <label>email</label>
          <Input onChange={setEmail}/>
        </div>
        <div className="flex flex-col w-full">
          <label>password</label>
          <Input type="password" onChange={setPassword}/>
          <Link className="self-end text-sm" to="/forgot-password">Forgot Password?</Link>
        </div>
        <ButtonPrimary onClick={handleLogin}>Submit</ButtonPrimary>
      </div>
    </PaddedPage>
  );
};

export default Login;
