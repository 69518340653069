import React from 'react'
import { useQuery, gql } from "@apollo/client";

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
      prices {
        id
      }
    }
  }
`;;

export default function OrderFilters({filters, setFilters}) {
  const {data:coldacreProductsRes } = useQuery(GET_PRODUCTS, {
    variables: { query: `-metadata['subtype']:'delivery' metadata['subtype']:'supplies' metadata['organizationId']:null` },
  });
  return (
    <div className="border flex p-2 gap-4">
      <div> 
        <strong>Status</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" defaultChecked={!filters.status} onClick={() => setFilters({...filters, status: null})} type="radio" id="status0" name="status" value=""/>
            <label className="font-normal cursor-pointer" htmlFor="status1">All</label>
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={filters.status === "pending"} onClick={() => setFilters({...filters, status: "pending"})} type="radio" id="status1" name="status" value="pending"/>
            <label className="font-normal cursor-pointer" htmlFor="status1">Pending</label>
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={filters.status === "filled"} onClick={() => setFilters({...filters, status: "processing"})} type="radio" id="status2" name="status" value="processing"/>
            <label className="font-normal cursor-pointer" htmlFor="status2">Processing</label> 
          </div>
          <div>
            <input className="cursor-pointer" defaultChecked={filters.status === "filled"} onClick={() => setFilters({...filters, status: "filled"})} type="radio" id="status3" name="status" value="filled"/>
            <label className="font-normal cursor-pointer" htmlFor="status3">Filled</label> 
          </div>
        </div>
      </div>
      <div> 
        <strong>Product</strong>
        <hr></hr>
        {
          coldacreProductsRes &&
            <select name="product" id="product" onChange={e => setFilters({...filters, priceId: e.target.value})}>
              <option value="">none</option>
              {
                coldacreProductsRes &&  coldacreProductsRes.products.map(product => 
                  <option 
                    key={product.id} 
                    value={product.prices[0].id}
                  >
                    {product.name}
                  </option>
                )
              }
            </select>
        }
      </div>
    </div>
  )
}
