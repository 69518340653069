import React, { useMemo, useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import micros from 'assets/images/winterharvest/Micros.jpg';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/inputs';
import MiniButton from 'components/common/Button/MiniButton';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Loading from 'components/common/Loading';

const unitPrice = (price) => {
  if (price.nickname && price.nickname.toLowerCase() === "weekly") {
    return Number(price.unit_amount)/2/100
  }
  return Number(price.unit_amount)/100
}

export default function Product({product, updateProduct, priceDefault, loading}) {
  const [showEdit, setShowEdit] = useState();
  const [confirmUpdate, setConfirmUpdate] = useState();
  const [newPrice, setNewPrice] = useState();
  const validPrices = product.prices.filter(price => price.active);
  const [price, setPrice] = useState((priceDefault && {...priceDefault, product}) || (validPrices[0] && {...validPrices[0], product}) || {});
  useEffect(() => {
    if (priceDefault) {
      setPrice({...priceDefault, product});
    } else {
      setPrice((product.prices[0] && {...product.prices[0], product}) || {})
    }
  }, [priceDefault, product]);

  const handleUpdateProduct = () => {
    updateProduct({price: newPrice, productId: product.id})
    .then((res) => {
      setShowEdit(false);
    })
  }

  return (
    <>
      {confirmUpdate &&
        <ConfirmNotice
          title="Update Product?"
          message={newPrice ? `Update products price to CA$${newPrice}?` : `This product will be archived`}
          submessage="Existing subscriptions with this product will be updated over the next billing cycle."
          onClose={() => setConfirmUpdate(false)}
          onConfirm={handleUpdateProduct}
        />
      }
      <div className={`flex gap-4 grow justify-between rounded border border-med-grey p-4 pr-8 relative`}>
        {!loading &&
          <div className="absolute top-1 right-1 flex gap-0.5 text-xs">
            <FontAwesomeIcon onClick={() => setShowEdit(!showEdit)} className="cursor-pointer" icon={faEdit}/>
            <FontAwesomeIcon onClick={() => setConfirmUpdate(true)} className="cursor-pointer text-red" icon={faArchive}/>
          </div>
        }
        <div className={`flex justify-center mr-3 sm:block`}>
          <div className={`bg-primary w-16 h-16 bg-cover rounded-xl bg-center`} style={{backgroundImage: `url(${product.image})`}}>
          </div>
        </div>
        <div className={`flex flex-col w-full`}>
          <div className="flex">
            <div>
              <div>
                <label className="mr-10 inline-block">{product.name}{product.unit_label ? ` - ${product.unit_label}` : ""}</label>
                <p className="-mt-2 text-primary">
                  {product.metadata && product.metadata.vendor && product.metadata.vendor}
                  {product.metadata && product.metadata.location && <span className="text-primary-two"> | {product.metadata.location}</span>}
                  
                </p>
              </div>
            </div>
          </div>
          <p className="grow">
            {product.description}
          </p>
        </div>
        <div className={`flex flex-col justify-between`}>
          {loading ?
            <Loading/>
            :
            <>
              {
                showEdit ?
                  <div className="flex gap-1 self-start">
                    <span>CA$</span>
                    <input className="border" type="number" onChange={(e) => setNewPrice(e.target.value)} value={newPrice}/>
                    <span>/each</span>
                  </div>
                :
                <p>
                  <small className="font-bold">CA${unitPrice(price)}/each</small>
                </p>

              }
              {
                showEdit && <MiniButton disabled={loading} onClick={() => setConfirmUpdate(true)}>{loading ? <Loading/> : "Update"}</MiniButton>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}
