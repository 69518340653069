import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useNavigate } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import MultipleSelect from 'components/inputs/MultipleSelect';

const ADD_INVENTORYITEM = gql`
  mutation AddInventoryItem($name: String!, $tags: [String], $organizationId: String, $stripeProductId: String, $notify: Float) {
    addInventoryItem(name: $name, tags: $tags, organizationId: $organizationId, stripeProductId: $stripeProductId, notify: $notify) {
      id,
    }
  }
`;

const GET_TAGS = gql`
  query GetTags($organizationId: String){
    tags(organizationId: $organizationId) {
      id,
      name,
    }
  }
`;

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
    }
  }
`;;

export default function New() {
  const {currentUser, organization} = useContext(AppContext);
  const [inventoryItem, setInventoryItem] = useState({});
  const [edit, setEdit] = useState(false);
  const {setActiveSubpage, organizationId} = useContext(ActivePageContext);
  const { loading, error, data:productsRes } = useQuery(GET_PRODUCTS, {
    variables: { query: `-metadata['subtype']:'delivery' metadata['organizationId']:${organization && `'${organization.id}'`}` },
  });
  const {data:coldacreProductsRes } = useQuery(GET_PRODUCTS, {
    skip: !organization,
    variables: { query: `-metadata['subtype']:'delivery' metadata['subtype']:'supplies' metadata['organizationId']:null` },
  });

  const { data: tagsData } = useQuery(GET_TAGS, {
    fetchPolicy:'no-cache',
    variables: {organizationId},
  });
  const navigate = useNavigate();
  const addressInputRef = useRef();
  const [addInventoryItemMutation, { loading:updateLoading, reset }] = useMutation(ADD_INVENTORYITEM);
  setActiveSubpage("new");

  const valid = () => {
    return inventoryItem.name;
  }

  const addInventoryItem = () => {
    addInventoryItemMutation({variables: {...inventoryItem, organizationId}})
      .then(res => {
        navigate(`/admin/inventory/${res.data.addInventoryItem.id}`)
      })
      .catch(() => {
        reset();
      })
  }

  const tags = tagsData ? tagsData.tags.map(tag => ({value: tag.id, name: tag.name})) : [];

  return (
    <PaddedPage className="w-full">
      {

      }
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            New Inventory Item
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          <label>Name</label>
          <Input valueOverride={inventoryItem.name} onChange={(name) => setInventoryItem({...inventoryItem, name})}/>
        </div>
        <div className="flex flex-col">
          <label>Notify</label>
          <hr></hr>
          <div>
            <input className="cursor-pointer" onClick={() => setInventoryItem({...inventoryItem, notify: 5})} checked={inventoryItem.notify} type="checkbox" id="yes" name="yes" value="yes"/>
            <label className="text-sm font-md" for="yes"> Yes</label>
            <br></br>
            <input className="cursor-pointer" onClick={() => setInventoryItem({...inventoryItem, notify: 0})} checked={!inventoryItem.notify} type="checkbox" id="no" name="no" value="no"/>
            <label className="text-sm font-md" for="no"> No</label>
          </div>
          {
            inventoryItem.notify ?
              <div>
                <p>Notify when stock falls below...</p>
                <Input type="number" valueOverride={inventoryItem.notify} onChange={(notify) => setInventoryItem({...inventoryItem, notify})}/>
              </div>
            : ''
          }
        </div>
        <div className="flex flex-col">
          <label>Tags</label>
          <MultipleSelect options={tags} onChange={(tags) => setInventoryItem({...inventoryItem, tags})}/>
        </div>
        <div className="flex flex-col">
          <label>Linked Product</label>
          <select name="product" id="product" onChange={e => setInventoryItem({...inventoryItem, stripeProductId: e.target.value})}>
            <option value="">none</option>
            {
              productsRes &&  productsRes.products.map(product => 
                <option 
                  key={product.id} 
                  value={product.id}
                >
                  {product.name}
                </option>
              )
            }
          </select>
        </div>
        {
          coldacreProductsRes &&
          <div className="flex flex-col">
            <label>ColdAcre Products</label>
            <select name="product" id="product" onChange={e => setInventoryItem({...inventoryItem, stripeProductId: e.target.value})}>
              <option value="">none</option>
              {
                coldacreProductsRes &&  coldacreProductsRes.products.map(product => 
                  <option 
                    key={product.id} 
                    value={product.id}
                  >
                    {product.name}
                  </option>
                )
              }
            </select>
          </div>
        }
        <ButtonPrimary className="self-start mt-2" disabled={!valid() || updateLoading} onClick={addInventoryItem}>{updateLoading ? <Loading/> : "Add Inventory Item"}</ButtonPrimary>
      </div>
    </PaddedPage>
  )
}
