import React, {useCallback, useContext, useMemo, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faUser, faTimes, faCircleLeft, faMask, faMoneyBill, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import MiniButton from 'components/common/Button/MiniButton';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Input from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useRef } from 'react';
import { useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';
import PhaseState from './PhaseState';

const GET_PLANTMANAGEMENT = gql`
  query GetPlantManagement($id: String) {
    plantManagement(id: $id) {
      notes,
      phaseStates {
        name,
        startTime,
        value,
        notes,
      },
      plant {
        name,
        growthPhases {
          name,
          duration,
          icon,
          dataName,
          title,
          instructions,
        },
      }
    }
  }
`;

const UPDATE_PLANTMANAGEMENT = gql`
  mutation UpdatePlantManagement($id: String!, $notes: String!, $phaseStates: JSON) {
    updatePlantManagement(id: $id, notes: $notes, phaseStates: $phaseStates) {
      id
    }
  }
`;

const GET_PLANTS = gql`
  query GetPlants{
    plants {
      id,
      name,
      description,
      image,
      growthPhases {
        name,
        duration,
        icon,
        dataName,
        title,
        instructions,
      }
    }
  }
`;

export default function PlantManagement({newPlant, loadingOverride, addPlant}) {
  const {currentUser} = useContext(AppContext);
  const params = useParams();
  const { loading, error, data } = useQuery(GET_PLANTMANAGEMENT, {
    fetchPolicy: 'network-only',
    variables: {id: params.id},
    skip: !params.id
  });
  const { data: plantsData } = useQuery(GET_PLANTS, {});
  const [plantManagement, setPlantManagement] = useState({phaseStates: []});
  const [edit, setEdit] = useState(newPlant);

  const [updatePlantManagementMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_PLANTMANAGEMENT);

  useEffect(() => {
    if (data && data.plantManagement) {
      setPlantManagement(data.plantManagement);
    }
  }, [data]);

  const toggleEdit = () => {
    if (edit) {
      setPlantManagement(data.plantManagement);
    }
    setEdit(!edit);
  }

  const updatePlant = (plantOverride) => {
    let newPlantManagement = plantOverride || plantManagement;
    if (!valid()) {
      return;
    }
    if (newPlant) {
      return addPlant(newPlantManagement);
    }
    updatePlantManagementMutation({variables: {id: params.id, ...newPlantManagement}})
      .then(res => {
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }

  const valid = () => {
    return plantManagement.notes && (!newPlant || plantManagement.plantId) && plantManagement.phaseStates;
  }

  const updatePhaseState = (phaseState) => {
    setPlantManagement((plantManagement) => {
      let found = false;
      let newPhaseStates = plantManagement.phaseStates.map(state => {
        if (state.name === phaseState.name) {
          found = true;
          return phaseState;
        }
        return state;
      })
      if (!found) {
        newPhaseStates.push(phaseState);
      }
      let newPlantManagement = {...plantManagement, phaseStates: newPhaseStates};
      if (!newPlant) {
        updatePlant(newPlantManagement);
      }
      return newPlantManagement;
    })
  }

  const selectedPlant = useMemo(() => {
    return plantManagement.plant  || (plantsData &&  plantsData.plants.find(plant => plant.id === plantManagement.plantId));
  }, [plantManagement])

  return (
    <PaddedPage className="w-full">
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            {newPlant ? "Track Crop Growth" : "Plant Tracker"}
            {
              !newPlant &&
              <sup>
                <FontAwesomeIcon onClick={toggleEdit} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
              </sup>
            }
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          {newPlant ?
            <>
              <label>Plant</label>
              <select name="plant" id="plant" onChange={e => setPlantManagement({...plantManagement, plantId: e.target.value})}>
                <option value="">Select Plant</option>
                {
                  plantsData &&  plantsData.plants.map(plant => 
                    <option 
                      key={plant.id} 
                      value={plant.id}
                    >
                      {plant.name}
                    </option>
                  )
                }
              </select>
            </>
            :
            <h2>
              {plantManagement.plant && plantManagement.plant.name}
            </h2>
          }
        </div>
        {/* <div className="flex flex-col">
          <label>Name</label>
          {edit ?<Input valueOverride={plantManagement.name} onChange={(name) => setPlantManagement({...plantManagement, name})}/> : <p>{plantManagement.name}&nbsp;</p>}
        </div> */}
        <div className="flex flex-col">
          <label>Notes</label>
          {edit ?<Input valueOverride={plantManagement.notes} onChange={(notes) => setPlantManagement({...plantManagement, notes})}/> : <p>{plantManagement.notes}&nbsp;</p>}
        </div>
        {
          selectedPlant &&
          <div>
            <h3>Phases</h3>
            <hr></hr>
            <div className="p-2 flex flex-col gap-2">
              {
                selectedPlant.growthPhases.map((phase, i) => {
                  let last = (i === selectedPlant.growthPhases.length -1);
                  return <React.Fragment key={i}>
                      <PhaseState key={i} phase={phase} updatePhaseState={updatePhaseState} phaseStates={plantManagement.phaseStates}/>
                      {
                        !last && <FontAwesomeIcon icon={faArrowAltCircleDown} />
                      }
                    </React.Fragment>
                })
              }
            </div>
          </div>
        }
        {edit && <ButtonPrimary className="self-start" disabled={!valid() || updateLoading || loadingOverride} onClick={() => updatePlant()}>{(updateLoading || loadingOverride) ? <Loading/> : newPlant ? "Track Crop" : "Update Crop Tracking"}</ButtonPrimary>}
      </div>
    </PaddedPage>
  )
}
