import { iterationsFrom } from "helpers/calendar";

const getWeekItems = (sub) => {
    const week1Details = {};
    const week2Details = {};

    if (sub && sub.items) {
      for (let item of sub.items) {
        week1Details[sub.metadata.delivery] = [...(week1Details[sub.metadata.delivery] || []), {...item.price.product, quantity: item.quantity}];
        if (item.price.nickname === "Weekly") {
          week2Details[sub.metadata.delivery] = [...(week2Details[sub.metadata.delivery] || []), {...item.price.product, quantity: item.quantity}];
        }
      }
    }
    return [week1Details, week2Details];
};

const getTotalWeekItems = (sub, date) => {
    let start = sub.current_period_start || sub.start_date;
    let iterations = iterationsFrom(start * 1000, date);
    const items = [];
    if (sub && sub.items) {
      for (let item of sub.items) {
        if (iterations % 1 === 0) {
            items.push(item);
        } else if (item.price.nickname.toLowerCase() === "weekly") {
            items.push(item);
        }
      }
    }
    return items;
};

const oneTimeProductsForWeek = (oneTimeProducts, date) => {
  let validProducts = [];
  for(let product of (oneTimeProducts || [])) {
    let productDate = new Date(product.date + "T00:00").getTime() - (-1 * 60 * 60 *1000) - (3 * 24 * 60 * 60 *1000);
    if (productDate > date && iterationsFrom(productDate, date) === 0) {
      product.oneTime = true;
      validProducts.push(product);
    }
  }
  return validProducts;
};

const validSub = (metadata, items, filters) => {
  if (items.length < 1) {
    return false;
  } else if (filters.day && (filters.day !== metadata.delivery)) {
    return false;
  } else if (filters.location && (filters.location !== metadata.location)) {
    return false;
  } else if (filters.distribution === "pick-up" && (items.find(item => item.price.product.name === "Delivery"))) {
    return false;
  } else if (filters.distribution === "delivery" && (!items.find(item => item.price.product.name === "Delivery"))) {
    return false;
  } else {
    return true;
  }
}

const weekSubscriptionToProductList = (sub, startTime, iterations, filters, productsTotal, orderSheet, routeLookup) => {
  let currentTime = Number(startTime);
  let currentItems = sub.items ? getTotalWeekItems(sub, currentTime) : [];
  let currentMetadata = sub.metadata || {};
  let activePhase;
  let cancelTime;
  if (sub && sub.schedule && sub.schedule.phases && (new Date(Number(sub.current_period_end) * 1000) < currentTime)) {
    for (let [index, phase] of Object.entries(sub.schedule.phases)) {
      if (Number(phase.start_date) < currentTime / 1000) {
        currentMetadata = { ...currentMetadata, ...phase.metadata };
      }
      if ((Number(phase.start_date) < (currentTime / 1000))) {
        activePhase = phase;
        currentItems = getTotalWeekItems(activePhase, currentTime);
      }
    }
  } else if (sub.cancel_at_period_end && sub.cancel_at_period_end != 'false') {
    cancelTime = new Date(sub.current_period_end * 1000);
    cancelTime.setHours(0, 0, 0);
    cancelTime = cancelTime.getTime();
  }
  for (let iteration = 0; iteration < iterations; iteration++) {
    const changeForWeek = sub.schedule && sub.schedule.phases && sub.schedule.phases.find(schedule => iterationsFrom(Number(schedule.start_date) * 1000, currentTime) == 0 && iterationsFrom(currentTime, Number(schedule.end_date) * 1000) > 0);
    if (changeForWeek) {
      activePhase = changeForWeek;
      currentMetadata = { ...currentMetadata, ...changeForWeek.metadata };
      currentItems = getTotalWeekItems(activePhase || sub, currentTime);
    }
    if (validSub(currentMetadata, currentItems, filters) && (!cancelTime || cancelTime > currentTime)) {
      if (!sub.user) {
        break;
      }
      if (!filters.driver || (routeLookup[sub.user.email] && routeLookup[sub.user.email].driver === filters.driver)) {
        orderSheet[sub.user.id] = orderSheet[sub.user.id] || { user: sub.user, orders: [], deliveryZone: sub.deliveryZone && sub.deliveryZone.name };
        currentItems = [...currentItems, ...oneTimeProductsForWeek(sub.oneTimeProducts, currentTime)]
        orderSheet[sub.user.id].orders.push({
          metadata: currentMetadata,
          currentDate: new Date(currentTime),
          items: currentItems,
        });
        if (currentMetadata.paused === 'true') {
          productsTotal["orders"] = { ...(productsTotal["orders"] || {}), name: "Orders", paused: (productsTotal["orders"] && productsTotal["orders"].paused ? productsTotal["orders"].paused + 1 : 1) };
        } else {
          productsTotal["orders"] = { ...(productsTotal["orders"] || {}), name: "Orders", quantity: (productsTotal["orders"] && productsTotal["orders"].quantity ? productsTotal["orders"].quantity + 1 : 1) };
        }
        for (let item of currentItems) {
          if (currentMetadata.paused === 'true') {
            productsTotal[item.price.product.id] = {
              ...(productsTotal[item.price.product.id] || {}),
              name: item.price.product.name,
              paused: ((productsTotal[item.price.product.id] && productsTotal[item.price.product.id].paused) || 0) + Number(item.quantity),
              supplier: item.price.product.metadata && item.price.product.metadata.vendor,
              order: item.price.product.metadata && item.price.product.metadata["packing order"],
              inventoryItemId: item.price.product.inventoryItemId,
            }
          } else {
            productsTotal[item.price.product.id] = {
              ...(productsTotal[item.price.product.id] || {}),
              name: item.price.product.name,
              quantity: ((productsTotal[item.price.product.id] && productsTotal[item.price.product.id].quantity) || 0) + Number(item.quantity),
              supplier: item.price.product.metadata && item.price.product.metadata.vendor,
              order: item.price.product.metadata && item.price.product.metadata["packing order"],
              inventoryItemId: item.price.product.inventoryItemId,
            }
          }
        }
      }
    }
    currentTime += 7 * 24 * 60 * 60 * 1000;
  }
}

export {
    getWeekItems,
    getTotalWeekItems,
    oneTimeProductsForWeek,
    weekSubscriptionToProductList
}