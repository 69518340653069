import React, {useContext} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import Organization from 'components/organizations/Organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { List } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';

const GET_ORGNAIZATIONS = gql`
  query GetOrganizations{
    organizations {
      id,
      name,
      email,
      subdomain,
    }
  }
`;

export default function Organizations() {
  const { loading, error, data } = useQuery(GET_ORGNAIZATIONS, {fetchPolicy:'network-only'});
  const {setActiveSubpage} = useContext(ActivePageContext);
  setActiveSubpage(null);

  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end">
        <h2>Organizations</h2>
      </div>
      <div>
        {data && data.organizations.map(organization => <Organization key={organization.id} organization={organization}/>)}
        {data && data.organizations.length === 0 && <small className="text-med-grey">No Organizations</small> }
      </div>
    </div>
  )
}
