import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonPrimary from 'components/common/Button';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import React, { useState } from 'react'
import SubscriptionOverview from './SubscriptionOverview'
import { useMutation, gql } from "@apollo/client";

const REMOVE_ONETIME_PRODUCTS = gql`
  mutation RemoveOneTimeProducts($oneTimeProductId: String) {
    removeOneTimeProducts(oneTimeProductId: $oneTimeProductId) {
      message
    }
  }
`;

export default function OneTimeProducts({addProduct, subscription, onRemove}) {
  let [showCancel, setShowCancel] = useState(false);
  let products = (subscription && subscription.oneTimeProducts) || [];
  products = products.filter(product => new Date(product.date) > new Date())
  const [removeOneTimeProductsMutation, { loading:removeLoading }] = useMutation(REMOVE_ONETIME_PRODUCTS);

  const cancelOneTimeProduct = () => {
    removeOneTimeProductsMutation({ variables: {oneTimeProductId: showCancel.id} })
    .then(res => {
      onRemove();
      setShowCancel();
    })
    .catch(err => {
      setShowCancel();
      console.log("err", err);
    })
  }

  return (
    <div className="flex flex-col sm:w-full">
        {showCancel && <ConfirmNotice 
          title={`Are you sure?`}
          message={`Your One-Time order of ${showCancel.price.product.name} scheduled for ${showCancel.date} will be cancelled.`}
          onConfirm={cancelOneTimeProduct}
          onClose={() => setShowCancel(false)}
        /> }
        <div>
            <h3>One Time Products</h3>
            <hr></hr>
        </div>
        <div className="flex flex-col h-full grow gap-4 pt-4">
            <div className="flex flex-col gap-2">
                {products.length > 0 ?
                  products.map((product, index) => (
                    <div key={index} className="flex bg-light-grey p-2 rounded justify-between relative">
                      <span>🌸&nbsp;{product.quantity} {product.price.product.name}</span> 
                      <span className="bg-secondary font-bold rounded p-1 text-xs">{product.date}</span>
                      {!product.invoiceId && <span onClick={() => setShowCancel(product)} className="absolute top-0 right-1 text-xs cursor-pointer"><FontAwesomeIcon icon={faTimes}/></span>}
                    </div>
                  ))
                  :
                  <div className="border-2 rounded p-4 border-med-grey bg-light-grey w-full flex justify-center items-center">
                    <span className="font-xl text-med-grey">none</span>
                  </div>
                }
            </div>
            
            <ButtonPrimary onClick={addProduct} className="w-full">
                <FontAwesomeIcon className="font-xl" icon={faPlus} />Add One Time Products
            </ButtonPrimary>
        </div>
    </div>
  )
}
