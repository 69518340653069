import { faArrowRight, faMoneyBill, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import Calendar from 'components/calendar';
import Loading from 'components/common/Loading';
import { useMutation, useQuery, gql } from "@apollo/client";
import Input from 'components/inputs';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useEffect } from 'react';
import { getNextBillingCycle } from 'helpers/subscription';

export default function OffsetModal({allowDirection, loading,  onConfirm, onClose, changesStart}) {
  let [direction, setDirection] = useState(1);

  const close = () => {
    // setOpen(false);
    onClose();
  }

  return (
    <>
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border w-full max-w-160 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-red`}>
                <h2 className="">
                  <FontAwesomeIcon onClick={close} className="" icon={faMoneyBill} />
                  &nbsp;
                  Change the customers billing date?
                </h2>
                <FontAwesomeIcon onClick={close} className=" cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2 justify-center">
                <div className="flex flex-col gap-2">
                  <div>
                    <p>{`The customer's billing cycle anchor will be changed from ${changesStart} to ${getNextBillingCycle(changesStart, direction === -1)} bi-weekly`}</p>
                    <hr></hr>
                  </div>
                  <div className="flex flex-col gap-1 max-h-160 overflow-y-scroll scroller">
                    {allowDirection &&
                      <div className="flex flex-col">
                        <label>Offset forward or backward</label>
                        <div>
                          <input type="radio" checked={direction === 1} onClick={() => setDirection(1)} name="Forward"/>
                          <label>Forward</label>
                        </div>
                        <div>
                          <input type="radio" checked={direction === -1} onClick={() => setDirection(-1)} name="Backward"/>
                          <label>Backward</label>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className=""  disabled={loading} onClick={() => onConfirm(direction)} >{loading ? <Loading/> : 'Confirm'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
    </>
  )
}
