import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/farm.jpg';
import { Link } from 'react-router-dom';

const PrefabSizes = () => {
  const {setPage} = useContext(AppContext);

  useEffect(() => setPage("Prefab Units"), [setPage]);

  return (
    <div className="bg-cover pt-12" id="production">
        <PaddedPage className="flex flex-col items-center">
          <div className="flex justify-around w-full sm:flex-col">
            <div className="w-1/4 sm:w-2/3 flex flex-col items-center text-center">
              <div className="w-60 h-60 bg-primary mb-8"></div>
              <h1>6m</h1>
              <h2 className="mb-2">90<sub>lbs/week</sub></h2>
              <p>Feeds 80 people - Effective for populations of 1000 or more.</p>
            </div>
            <div className="w-1/4 sm:w-2/3 flex flex-col items-center text-center">
              <div className="w-60 h-60 bg-primary mb-8"></div>
              <h1>12m</h1>
              <h2 className="mb-2">185<sub>lbs/week</sub></h2>
              <p>Feeds 160 people - Effective for populations of 1000 or more.</p>
            </div>
            <div className="w-1/4 sm:w-2/3 flex flex-col items-center text-center">
              <div className="w-60 h-60 bg-primary mb-8"></div>
              <h1>16m</h1>
              <h2 className="mb-2">275-350<sub>lbs/week</sub></h2>
              <p>Feeds 250 people - Effective for populations of 1000 or more.</p>
            </div>
          </div>
          <div className="w-2/3 mt-8 flex items-center flex-col">
            <Link to="/order">
              <ButtonPrimary className="mt-2" >Order Now</ButtonPrimary>
            </Link>
          </div>
        </PaddedPage>
    </div>
  );
};

export default PrefabSizes;
