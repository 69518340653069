import Input from 'components/inputs'
import IconSelector from 'components/inputs/IconSelector'
import React from 'react'

export default function Phase({phase, updatePhase, edit}) {
  return (
    <div className="bg-light-grey rounded p-2 border border-background-dark">
      <div className="flex flex-col">
        <label>Name</label>
        {edit ?<Input valueOverride={phase.name} onChange={(name) => updatePhase({...phase, name})}/> : <p>{phase.name}&nbsp;</p>}
      </div>
      <div className="flex flex-col">
        <label>Duration (Days)</label>
        {edit ?<Input valueOverride={phase.duration} onChange={(duration) => updatePhase({...phase, duration})}/> : <p>{phase.duration}&nbsp;</p>}
      </div>
      <div className="flex flex-col">
        <label>Icon</label>
        <IconSelector initialValue={phase.icon} small={true} readOnly={!edit} onChange={(icon) => updatePhase({...phase, icon})}/>
      </div>
      <div className="flex flex-col">
        <label>Data collection label (ex seeded, planted, harvested)</label>
        {edit ?<Input valueOverride={phase.dataName} onChange={(dataName) => updatePhase({...phase, dataName})}/> : <p>{phase.dataName}&nbsp;</p>}
      </div>
      <div className="flex flex-col">
        <label>Instructions</label>
        {edit ?<Input valueOverride={phase.instructions} onChange={(instructions) => updatePhase({...phase, instructions})}/> : <p>{phase.instructions}&nbsp;</p>}
      </div>
    </div>
  )
}
