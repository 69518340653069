import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const MiniButton = ({className, white, colored, ...rest}) => {
  return (
    <ButtonRoot 
      className={`
        border-2 
        ${white ? 'border-white text-white' : colored || 'border-background-dark'}
        font-semibold 
        cursor-pointer
        rounded 
        hover:no-underline
        hover:text-black
        hover:bg-white
        text-xs
        ${className}
      `}
      small="true"
      {...rest}
    />
  );
};

export default MiniButton;
