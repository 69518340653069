export default function LocationPicker({defaultLocation, onChange}) {

  const updateLocation = (location) => {
    onChange(location);
  }

  return (
   <div className="flex flex-col gap-4">
      <label>Location</label>
      <div className="flex gap-2">
      <div className={`
        border-2 rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
        ${defaultLocation == "whitehorse" ? 'border-dark-grey text-dark-grey' : 'text-light-grey'}
        `}
        onClick={() => updateLocation("whitehorse")}
      >
         <h2 className={`${defaultLocation == "whitehorse" ? 'text-dark-grey': "text-light-grey"}`}>Whitehorse</h2>
         <small className="font-bold"></small>
      </div>
      <div className={`
        border-2  rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
        ${defaultLocation == "dawson" ? 'border-dark-grey text-dark-grey' : 'border-light-grey text-light-grey'}
        `}
        onClick={() => updateLocation("dawson")}
      >
         <h2 className={`${defaultLocation == "dawson" ? 'text-dark-grey': "text-light-grey"}`}>Dawson</h2>
         <small className="font-bold"></small>
      </div>
      </div>
      <div className="flex gap-4 justify-center">
      <div className="p2 flex flex-col cursor-pointer text-center">
         <h3 className="">Order Dates</h3>
         {
            defaultLocation === "whitehorse" ?
               <p className="">Wednesdays or Thursdays</p>
            :
               <p className="">Wednesdays</p>            
         }
      </div>
      {/* <div className="p2 flex flex-col cursor-pointer text-center">
         <h3 className="">1000 seeds</h3>
         <p className="">20,000 heads of lettuce/month</p>
      </div> */}
      </div>
   </div>
  )
}
