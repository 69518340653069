import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { useQuery, useMutation, gql } from "@apollo/client";
import jwt from "jwt-decode";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
    }
  }
`;

const IMPERSONATE = gql`
  mutation Impersonate($userId: String!) {
    impersonate(userId: $userId) {
      id
    }
  }
`;

// const GET_ME = gql`
//   query GetMe {
//     me {
//       name,
//       linkId,
//       order,
//       pages
//     }
//   }
// `;

const useCurrentUser = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState();
  const [refresh, setRefresh] = useState(0);
  const {unauthorized, tokenExpired, resetAuthFlags} = useContext(RequestFlagsContext);
  const token = localStorage.getItem('token');
  const impersonationToken = localStorage.getItem('impersonationToken');
  const currentUser = useMemo(() => {
    const userToken = impersonationToken || token;
    return userToken ? jwt(userToken).user : null;
  }, [token, impersonationToken, refresh]);
  // TO BE USED IF USER OBJECT GETS TOO BIG for TOKEN
  // const { loading, userError, currentUser } = useQuery(GET_ME, {
  //   variables: { id: impersonationToken ? impersonationToken.user.id : token.user.id },
  //   skip: (!token && !impersonationToken),
  //   onError(){
  //     navigate("/login");
  //   }
  // });

  const [loginMutation, { loginLoading, loginError }] = useMutation(LOGIN);
  const [impersonateMutation, { impersonateLoading, impersonateError }] = useMutation(IMPERSONATE);

  if (loginError) {
    navigate('/');
    console.log(loginError);
    setError({title: 'Could Not Login', message: loginError});
  }
  if (impersonateError) {
    console.log(loginError);
    setError({title: 'Could Not Impersonate', message: loginError});
  }
  // if (userError) {
  //   console.log(userError);
  //   setError({title: 'Could Not Get User Info', message: userError});
  // }

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('subscription')
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (unauthorized || tokenExpired) {
      // other component should show warnign if expired
      logout();
    }
  }, [unauthorized, tokenExpired, logout]);

  const login = (email, password, noRedirect, custom) => {
    return loginMutation({ variables: { email, password } })
      .then(res => {
        setRefresh(refresh + 1);
        if (!noRedirect) {
          navigate(custom || '/my-subscription');
        }
      })
      .catch(error => {
        if (error.toString().includes("confirm your email")) {
          setSearchParams(new URLSearchParams({confirm : false}));
        }
      })
  }

  const impersonate = (userId) => {
    impersonateMutation({ variables: { userId } })
    .then(res => {
      navigate("/my-subscription");
    })
  }

  const stopImpersonating = () => {
    localStorage.removeItem('impersonationToken');
    navigate('/');
  }
  
  return {
    currentUser: (token || impersonationToken) && currentUser,
    login,
    logout,
    impersonating: impersonationToken ? true : false,
    impersonate,
    stopImpersonating,
    loading: loginLoading || impersonateLoading,
    error
  }
};

export default useCurrentUser;
