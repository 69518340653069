import Inventory from './Inventory';
import React from 'react';
import { Route } from 'react-router-dom';
import New from './New';
import NewTag from './NewTag';
import InventoryItem from './InventoryItem';

const routes = [
  <Route key="Inventory" path="inventory" element={<Inventory/>} />,
  <Route key="New" path="new" element={<New/>} />,
  <Route key="NewTag" path="new-tag" element={<NewTag/>} />,
  <Route key="InventoryItem" path=":id" element={<InventoryItem/>} />,
];

export default routes;
