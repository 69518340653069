import React, {useContext} from 'react';
import CalendarContext from 'contexts/CalendarContext';
import { sameDay } from 'helpers/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';

const letterDayLookup = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', "Saturday"];

const getColor = (event) => {
  switch(event.type) {
  case 'Task':
    return 'text-pink';
  case 'Closing':
    return 'text-green';
  case 'amex':
    return 'text-yellow';
  default:
    return 'text-blue';
  }
}

export default function WeekDay({dayOfWeek, currentDate}) {
  const {setDate, date, monthEvents} = useContext(CalendarContext);
  const todaysEvents = monthEvents.filter(event => sameDay(event.date, currentDate));
  return (
    <div 
      className={`
        w-1/10 min-w-8 relative cursor-pointer overflow-hidden
        ${date.getDate() === currentDate.getDate() ? 'z-10 scale-110 min-w-32 bg-light-grey text-black shadow-md border-b-2 border-b-primary font-md w-2/5 mt-2' : 'bg-white'}
        hover:font-md hover:scale-110 hover:width-2/5 hover:mt-2 hover:z-10
      `}
      // TODO ifs this so it uses date obj instead of calendar date
      onClick={() => setDate(new Date(currentDate))}
    >
      <div className="mt-full relative"/>
        <div className={`
          flex flex-col items-center gap-1 absolute top-0 left-0 right-0 bottom-0
        `}>
          <small>{letterDayLookup[dayOfWeek].slice(0,3)}</small>
          <div className={`
            divide-y w-full
            h-full
            flex flex-col gap-2
            pb-2
            ${date.getDate() !== currentDate.getDate() && 'opacity-50'}
            ${date.getDate() === currentDate.getDate() && 'overflow-y-scroll scroller'}
          `}>
            {todaysEvents.map((event, i) => 
              <React.Fragment key={i}>
                <div>
                  <div key={event.name} className={`flex ${getColor(event)}`}>
                    <span className="text-xs font-medium">{event.icon}</span>
                    <span className="text-xs font-medium">{event.type}</span>
                  </div>
                  <ul className={`
                    -ml-2
                  `}>
                    <hr className="border-white"></hr>
                    {event.details && event.details.map((detail, index) => <li key={index} className="text-xs leading-3 font-normal mb-1 scroller m-0 p-0 list-disc">{detail}</li>)}
                  </ul>
                </div>
                {
                  event.paused &&
                  <div className="w-full h-full absolute bg-yellow top-0 opacity-75 flex justify-center items-center">
                    <FontAwesomeIcon icon={faPause}/>
                    <p> Paused</p>
                  </div>
                }
              </React.Fragment>
            )}
          </div>
        </div>
    </div>
  )
}
