const nextMonday = (date = new Date(), originalDate) => {
    const d = new Date(new Date(date).setHours(0,0,0,0));
    let testMonday = d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 1) % 7);
    if ((testMonday - (originalDate||d)) < (7 * 24 * 60 * 60 * 1000)) {
        return nextMonday(new Date(testMonday + (1 * 24 * 60 * 60 * 1000)), d)
    }
    return new Date(testMonday);
}

const sameDay = (date1, date2) => {
    return date1.toDateString() === date2.toDateString();
}

const withinTwentyFour = (date1, date2) => {
    return Math.abs(date1 - date2) < 24 * 60 * 60 * 1000;
}

const datePlusOffset = (date, offset) => {
    let currentDay = date.getDay();
    let distance = offset - currentDay;
    let deliveryDay = new Date(date);
    deliveryDay.setDate(deliveryDay.getDate() + distance);
    return deliveryDay;
}
// const getProrationDateOptions = (current_period_end, biweekly = false) => {
//     let now = new Date.now();
//     let options = [];
//     if (biweekly) {
//         if ((current_period_end - now) > 7 * 24 * 60 * 60 * 1000) {
//             options.push(current_period_end);
//         }
//         while(options.length <= 3) {
//             options.push(option.at(-1) + 7 * 24 * 60 * 60 * 1000)
//         }
//     } else {
//         options.push(nextMonday(now));
//         while(options.length <= 6) {
//             options.push(nextMonday(option.at(-1)));
//         }
//     }
// }

export {
    nextMonday,
    sameDay,
    datePlusOffset,
    // getProrationDateOptions,
    withinTwentyFour
}