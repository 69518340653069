import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useNavigate } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import PlantManagement from './PlantManagement';

const ADD_PLANTMANAGEMENT = gql`
  mutation AddPlantManagement($notes: String, $plantId: String!, $phaseStates  : JSON!) {
    addPlantManagement(notes: $notes, plantId: $plantId, phaseStates : $phaseStates ) {
      id,
    }
  }
`;

export default function NewPlantManagement() {
  const {currentUser} = useContext(AppContext);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const addressInputRef = useRef();
  const [addPlantMutation, { loading:updateLoading, reset }] = useMutation(ADD_PLANTMANAGEMENT);
  const {setActiveSubpage} = useContext(ActivePageContext);
  setActiveSubpage("new");


  const addPlant = (plant) => {
    addPlantMutation({variables: {...plant}})
      .then(res => {
        navigate(`/admin/plant-management/${res.data.addPlantManagement.id}`)
      })
      .catch((e) => {
        console.log("what", e, plant);
        reset();
      })
  }

  return (
    <div className="flex flex-col gap-2">
      <PlantManagement newPlant={true} overrideLoading={updateLoading} addPlant={addPlant} />
    </div>
  )
}
