import React, {useMemo, useState} from 'react';
import CalendarDay from './CalendarDay';
import CalendarMonth from './CalendarMonth';
import CalendarWeek from './CalendarWeek';
import CalendarContext from 'contexts/CalendarContext';
import { monthsBetween } from 'helpers/calendar';
import { sameDay } from 'helpers/time';

const testEventsForMonth = [
  // {
  //   date: new Date(),
  //   type: 'Closing',
  //   icon: '🎏',
  //   name: "Dave's House closing"
  // },
  // {
  //   date: new Date(),
  //   type: 'Task',
  //   icon: '🎉',
  //   name: "Bring Forward Item"
  // },
  // {
  //   date: new Date(),
  //   type: 'Task',
  //   icon: '💎',
  //   name: "Bring Forward Item Two"
  // },
]

const addMonths = (change, date) => {
  let newMonth = date.getMonth() + change;
  if (newMonth > 11) {
    return new Date(date.getFullYear() + 1, newMonth%12, 1);
  } else if (newMonth < 0) {
    return new Date(date.getFullYear() - 1, 12 - newMonth, 1);
  } else {
    return new Date(date.getFullYear(), newMonth, 1);            
  }
}

const CalendarModal = ({max, min, billingAnchor, onSelected, fuzzyStart, selectOffset}) => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hoverDate, setHoverDate] = useState();

  const handleSetDate = (val) => {
		setDate(val);
	}
  // eventually can have these dynamic so different select criteria
  const startClickable = (date) => {
    const billingAnchorDate = new Date(new Date(billingAnchor * 1000).setHours(0,0,0,0));
    if (date.getTime() <= billingAnchorDate.getTime() && !sameDay(date, billingAnchorDate)) return false;
    if (fuzzyStart) return true;
    let iterations = ((Number(date)/1000) - (Number(billingAnchorDate)/1000)) / (1 * 7 * 24 * 60 * 60);
    iterations = Math.round(iterations * 10)/10
    return Number.isInteger(iterations);
  }
  const endClickable = (date) => {
    return true;
  }

  const hoverOnDate = (date) => {
    if (startDate && !endDate) {
      setHoverDate(date);
    }
  }

  const selectDate = (date) => {
    if (!startDate) {
      setHoverDate();
      let iterations = (((Number(date)/1000) + (selectOffset || 0)) - Number(billingAnchor)) / (2 * 7 * 24 * 60 * 60);
      iterations = iterations > 0 ? Math.round((iterations*10)/5)*5/10 : 0;

      const newStartDate = new Date((billingAnchor *1000 + iterations * 2 * 7 * 24 * 60 * 60 *1000));
      setStartDate(newStartDate);
    } else if (startDate && endDate){
      onSelected();
      setStartDate();
      setEndDate();
    }else {
      setHoverDate();
      // let iterations = ((Number(date)/1000 + (selectOffset || 0) + 24 * 60 * 60) - Number(billingAnchor)) / (2 * 7 * 24 * 60 * 60);
      let iterations = ((Number(date)/1000 + (selectOffset || 0) + 24 * 60 * 60) - Number(startDate)/1000) / (2 * 7 * 24 * 60 * 60);
      iterations = iterations > 0.5 ? Math.round((iterations*10)/5)*5/10 : Math.ceil((iterations*10)/5)*5/10;
      const newEndDate = new Date((Number(startDate) + iterations * 2 * 7 * 24 * 60 * 60 *1000));
      onSelected({start: startDate, end: newEndDate});
      setEndDate(newEndDate);
    }
  }

  const nextMonth = useMemo(() => {
    let newMonth = addMonths(1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min]);

  const prevMonth = useMemo(() => {
    let newMonth = addMonths(-1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min])

  return (
		<CalendarContext.Provider value={{
      date,
      startClickable,
      endClickable,
      selectDate,
      startDate,
      endDate,
      hoverOnDate,
      hoverDate,
      setDate: handleSetDate, 
      monthEvents: testEventsForMonth, 
      nextMonth, 
      prevMonth
    }}>
      <div className="flex gap-4 justify-center">
          <div className="flex flex-col gap-2 grow max-w-50vh">
            <CalendarMonth/>
          </div>
      </div>
		</CalendarContext.Provider>
  )
}

export default CalendarModal;
