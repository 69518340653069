import React, {useContext} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import User from 'components/users/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import { useState } from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Order from './Order';
import OrderFilters from './OrderFilters';

const GET_ORDERS = gql`
  query GetOrders($organizationId: String, $filters: JSONObject){
    inventoryOrders(organizationId: $organizationId, filters: $filters) {
      id,
      firstName,
      lastName,
      email,
      type,
      activeSubscription,
      organization
    }
  }
`;

const GET_INVENTORY_ORDERS = gql`
  query GetInventoryOrders($priceId: String, $filters: JSONObject) {
    inventoryOrders(priceId: $priceId, filters: $filters) {
      id,
      quantity,
      priceId,
      productId,
      status,
      invoiceId,
      createdAt,
      organization {
        name
      }
      price {
        product {
          name
        }
      }
    }
  }
`;

export default function Orders() {
  const {currentUser} = useContext(AppContext);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const {setActivePage, organizationId} = useContext(ActivePageContext);
  const { loading, error, data } = useQuery(GET_INVENTORY_ORDERS, {
    fetchPolicy:'no-cache',
    variables: {filters}
  });
  console.log("filters , ", filters);
  setActivePage("orders");

  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end">
        <h2>Orders</h2>
        <div className="flex items-end h-full">
          <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
          <FontAwesomeIcon className="text-xs" icon={showFilters ? faCaretUp : faCaretDown} />
        </div>
      </div>
      {
        showFilters && <OrderFilters filters={filters} setFilters={setFilters} />
      }
      <div className="flex flex-col gap-2">
        {data && data.inventoryOrders.map(order => 
          <Order order={order} admin={!currentUser.organizationId} product={order.price.product} ></Order>
        )}
        {data && data.inventoryOrders.length === 0 && <small className="text-med-grey">No Orders</small> }
      </div>
    </div>
  )
}
