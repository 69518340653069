import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faRuler } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from 'components/inputs'
import IconSelector from 'components/inputs/IconSelector'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'

export default function PhaseState({phase, updatePhaseState, edit, phaseStates}) {
  let phaseData = useMemo(() => {
    return phaseStates.find(currentPhase => currentPhase.name === phase.name) || {name: phase.name};
  }, [phaseStates, phase]);

  const setPhaseData = (phaseData) => {
    updatePhaseState(phaseData);
  };
  let dateValue = phaseData.startTime && new Date(Number(phaseData.startTime)  || phaseData.startTime).toISOString().split('T')[0]
  return (
    <div className="bg-light-grey rounded p-2 border border-background-dark">
      <div className="flex justify-between gap-2">
        <div className="flex gap-2">
          <div>{phase.icon}</div>
          <h3>{phase.name}</h3>
        </div>
        <div className="flex gap-2">
          <p><FontAwesomeIcon icon={faClock} /> {phase.duration} days</p>
        </div>
      </div>
      <div className="text-sm flex gap-4 justify-between">
        {phase.instructions}
        <div className="flex flex-col gap-2">
          <div>
            <FontAwesomeIcon icon={faRuler} />{phase.dataName}&nbsp;
            <Input className="text-xs p-1" valueOverride={phaseData.value} onChange={(value) => setPhaseData({...phaseData, value})}/>&nbsp;
            on&nbsp;
            <Input className="text-xs p-1" valueOverride={dateValue} type="date" onChange={(startTime) => setPhaseData({...phaseData, startTime})}/>
          </div>
          <div className="flex flex-col">
            <p>Notes</p><Input className="text-xs p-1" valueOverride={phaseData.notes} type="textarea" onChange={(notes) => setPhaseData({...phaseData, notes})}/>
          </div>
        </div>
      </div>
    </div>
  )
}
