import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardSetupForm from './StripeCardSetupForm';
import { useMutation, gql } from "@apollo/client";
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const INITIALIZE = gql`
  mutation GetPaymentIntent($productId: String, $reservationId: String) {
    getPaymentIntent(productId: $productId, reservationId: $reservationId) {
      paymentIntent {
        id,
        amount,
        status,
        client_secret,
        metadata
      },
      paymentMethods
    }
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentForm({productId, reservation, onSuccess}) {
  const {setLoading, loading} = useContext(AppContext);
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const navigate = useNavigate();
  const [initializeMutation, { loading:initializeLoading }] = useMutation(INITIALIZE);
  
  useEffect(() => {
    initializeMutation({ variables: { reservationId: reservation && reservation.id, productId} })
      .then(({data}) => {
          setPaymentIntent(data.getPaymentIntent.paymentIntent);
          if (data.getPaymentIntent.paymentMethods && Array.isArray(data.getPaymentIntent.paymentMethods) && data.getPaymentIntent.paymentMethods.length > 0) {
            setPaymentMethods(data.getPaymentIntent.paymentMethods);
          }
      })
      .catch((error) => {
        console.log("error for getting secret", error.message);
        // TODO handle errror
      })
  }, []);
  
  const handleSuccess = () => {
    onSuccess(paymentIntent.id);
  };

  return (
    <div className="bg-white rounded shadow-md p-4 border-t">
      {paymentIntent ? <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret}}>
        <StripeCardSetupForm paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} paymentIntent={paymentIntent} handleSuccess={handleSuccess}/>
      </Elements>
      :
        <FontAwesomeIcon className="text-xl animate-spin" icon={faSpinner} />
      }
    </div>
  );
}

export default PaymentForm;

