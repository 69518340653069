import React, {useContext, useState, useEffect, useMemo} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import {Input} from 'components/inputs';
import Modal from 'components/common/Modal';
import PaymentForm from 'components/payments/PaymentForm';
import SizeSelector from './SizeSelector';
import AddFeature from './AddFeature';
import { useMutation, gql } from "@apollo/client";
// build images
import build6 from 'assets/images/prefab/6/6.png';
import build6GH from 'assets/images/prefab/6/6GH.png';
import build6GHsolar from 'assets/images/prefab/6/6GHsolar.png';
import build6solar from 'assets/images/prefab/6/6solar.png';
import build12 from 'assets/images/prefab/12/12.png';
import build12GH from 'assets/images/prefab/12/12GH.png';
import build12GHsolar from 'assets/images/prefab/12/12GHsolar.png';
import build12solar from 'assets/images/prefab/12/12solar.png';
import build16 from 'assets/images/prefab/16/16.png';
import build16GH from 'assets/images/prefab/16/16GH.png';
import build16GHsolar from 'assets/images/prefab/16/16GHsolar.png';
import build16solar from 'assets/images/prefab/16/16solar.png';
import build16AE from 'assets/images/prefab/16/16AE.png';
import build16GHAE from 'assets/images/prefab/16/16GHAE.png';
import build16GHsolarAE from 'assets/images/prefab/16/16GHsolarAE.png';
import build16solarAE from 'assets/images/prefab/16/16solarAE.png';

import { features } from './features';
import { getCalculatedValue } from 'helpers/orderHelpers';
import SuccessNotice from 'components/notices/SuccessNotice';

const buildImageLookup = {
  "6": build6,
  "6GH": build6GH,
  "6GHsolar": build6GHsolar,
  "6solar": build6solar,
  "12": build12,
  "12GH": build12GH,
  "12GHsolar": build12GHsolar,
  "12solar": build12solar,
  "16": build16,
  "16GH": build16GH,
  "16GHsolar": build16GHsolar,
  "16solar": build16solar,
  "16AE": build16AE,
  "16GHAE": build16GHAE,
  "16GHsolarAE": build16GHsolarAE,
  "16solarAE": build16solarAE,
}

const CREATE_RESERVATION = gql`
  mutation CreateReservation($firstName: String!, $email: String!, $lastName: String, $phoneNumber: String!, $addressOne: String!, $build: JSONObject) {
    createReservation(firstName: $firstName, email: $email, lastName: $lastName, phoneNumber: $phoneNumber, addressOne: $addressOne, build: $build) {
      id,
      email,
      lastName,
      firstName,
      phoneNumber,
      paymentIntentId
    }
  }
`;

const UPDATE_RESERVATION = gql`
  mutation UpdateReservation($id: String!, $paymentIntentId: String) {
    updateReservation(id: $id, paymentIntentId: $paymentIntentId) {
      id,
      email,
      lastName,
      firstName,
      phoneNumber,
      paymentIntentId,
      status
    }
  }
`;

const sizes = [
  {size: 6, cost: '40,000', meta: {feeds: 95, production: 114, produce: 114, population: 380, lettuce: 2000}},
  {size: 12, cost: '70,000', meta: {feeds: 190, production: 228, produce: 228, population: 760, lettuce: 2400}},
  {size: 16, cost: '80,000', meta: {feeds: 250, production: 300, produce: "275-350", population: 1000, lettuce: 3150}},
]

const sizeLookup = {
  6: sizes[0],
  12: sizes[1],
  16: sizes[2],

}

const OrderPage = () => {
  const {setPage} = useContext(AppContext);
  const [build, setBuild] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [reservation, setReservation] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [createReservationMutation, { loading, data }] = useMutation(CREATE_RESERVATION);
  const [updateReservationMutation, { loading: updateLoading}] = useMutation(UPDATE_RESERVATION);

  useEffect(() => setPage("Prefab Units"), [setPage]);

  const createReservation = () => {
    createReservationMutation({variables: {
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
      addressOne: userInfo.addressOne,
      build: build
    }})
    .then(res => {
      setReservation(res.data.createReservation);
      setShowPayment(true);
    })
  }
  const updateReservation = (paymentIntentId) => {
    updateReservationMutation({variables: {
      id: reservation.id,
      paymentIntentId,
    }})
    .then(res => {
      // reservation updated
      setShowPayment(false);
      setReservation(res.data.updateReservation);
    })
  }

  const valid = () => {
    return build && userInfo.email && userInfo.phoneNumber && userInfo.addressOne && userInfo.firstName
  }

  const filterBuildFromChanges = (build, newBuild) => {
    for (let key in build) {
      if (build[key] && build[key].showCalculation && !getCalculatedValue(newBuild,build[key].showCalculation)) {
        delete newBuild[key];
      }
    }
    return newBuild
  }

  const removeBuildItem = (id) => {
    let newBuild = {...build, [id]: null};
    newBuild = filterBuildFromChanges(build, newBuild);
    setBuild({...newBuild});
  }

  const updateSize = (size) => {
    let newBuild = {...build, size};
    newBuild = filterBuildFromChanges({...newBuild}, newBuild);
    setBuild({...newBuild, size});
  }

  const containerImage = useMemo(() =>{
    let lookup = `${build.size}`
    if (build.greenhouse) {
      lookup += "GH";
    }
    if (build.solar) {
      lookup += "solar";
    }
    if (build.arcticEntrance) {
      lookup += "AE";
    }
    return buildImageLookup[lookup];
  }, [build]);

  const totalCost = useMemo(() =>{
    let total = build.size ? Number(sizeLookup[build.size].cost.replace(",","")) : 0;
    for (let key in build) {
      if (build[key] && key !== "size") {
        total += Number(getCalculatedValue(build, build[key].cost).toString().replace(",", ""));
      }
    }
    return total;
  }, [build]);

  const production = useMemo(() => {
    let total = 0;
    for (let key in build) {
      if (build[key] && key !== "size") {
        total += getCalculatedValue(build, build[key].meta.production);
      }
    }
    return Math.max(total, 0);
  }, [build]);

  const crops  = useMemo(() => {
    let plants = [];
    for (let key in build) {
      if (build[key] && key !== "size") {
        plants = [...plants, ...build[key].meta.crops];
      }
    }
    if (plants.length < 1) {
      return null;
    }
    plants.push("and more");
    return plants;
  }, [build]);

  const power  = useMemo(() => {
    let total = 0;
    for (let key in build) {
      if (build[key] && key !== "size") {
        total += getCalculatedValue(build, build[key].meta.power);
      }
    }
    return total;
  }, [build]);

  const getFeatures = () => {
    let features = [];
    for (let key in build) {
      if (build[key] && key !== "size") {
        if (build[key].meta.additional) {
          features = [...features, ...build[key].meta.additional];
        }
      }
    }
    return features;
  }

  return (
    <>
      {showPayment && data && <Modal naked close={() => setShowPayment(false)}>
        <PaymentForm reservation={data.createReservation} onSuccess={updateReservation} productId={process.env.REACT_APP_STRIPE_RESERVATION_PRICE_ID}/>
      </Modal>}
      {reservation && reservation.status === "paid" && <SuccessNotice title="Success!" message="Your prefab farm has been reserved. We will be in contact with you shortly."/>}
      <div className="flex flex-col">
        <div className="bg-cover pt-12">
          <PaddedPage className="flex gap-16 sm:flex-col" >
            <div className="w-1/2 sm:w-full flex flex-col">
                <h1 className="relative">
                  <span className="text-primary">Model</span>&nbsp;
                  <span className={`lightgradient ${(build.size == 6 )? 'inline' : 'hidden'}`}>6</span>
                  <span className={`lightgradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                  <span className={`lightgradient ${(build.size == 16) ? 'inline' : 'hidden'}`}>16</span>  
                </h1>
                <p>ColdAcre Hydroponic Container Farm</p>
                <div className="flex flex-col gap-32 sm:gap-16 mt-4">
                  <p className="p-6 rounded-lg bg-light-grey">Select from the options below to, build out and reserve your farm.</p>
                  <SizeSelector sizes={sizes} updateSize={updateSize}/>
                  {features.map(feature => (
                    ((!feature.showCalculation || getCalculatedValue(build, feature.showCalculation)) ?
                      <AddFeature 
                        key={feature.id}
                        addFeature={(feat) => setBuild({...build, [feature.id]: feat})} 
                        removeFeature={() => removeBuildItem(feature.id)} 
                        feature={feature}
                        build={build}
                        selected={build[feature.id]}
                      />
                      :
                      ''
                    )
                  ))}

                  <div className="w-full hidden sm:block flex flex-col self-start -mt-20 pb-4">
                    <div className="w-full h-80 relative ">
                      <img src={containerImage} className="absolute bottom-0" alt="ColdAcre container farm"/>
                      <h1 className="absolute bottom-4">
                        <span className={`lightgradient ${(build.size == 6 )? 'inline' : 'hidden'}`}>6</span>
                        <span className={`lightgradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                        <span className={`lightgradient ${(build.size == 16) ? 'inline' : 'hidden'}`}>16</span>  
                      </h1>
                    </div>
                    <div className="flex flex-col items-end bg-light-grey text-sm border p-4 rounded border-grey scroller overflow-y-scroll">
                      <div className="w-full flex flex-col mb-2">
                        <strong className="w-full border-b border-dark">Features</strong>
                        {production ? <p className="">Produces {production}<sub>lbs/week</sub></p> : ''}
                        {crops ? <p className="">Grow {crops.join(", ")}<sub></sub></p> : ''}
                        {power ? <p className="">Uses {power}<sub>kWh/month</sub> of power</p> : ''}
                        {getFeatures().map((feature, index) => <p key={index} className="">{feature}</p> )}
                      </div>
                      <strong className="w-full border-b border-dark">Cost</strong>
                      <div className="flex justify-between w-full gap-2">Containter Farm Base: <span>${sizeLookup[build.size] && sizeLookup[build.size].cost}</span></div>
                      {Object.entries(build).filter((current) => current[0] !== "size" && current[1]).map(([key]) =>
                        <div className="flex justify-between w-full gap-2" key={key}>{key !== "size" && build[key] &&  build[key].name}: <span>${getCalculatedValue(build, build[key].cost)}</span></div>
                      )}
                      <hr className="w-full border-white"></hr>
                      <div className="flex justify-between w-full gap-2">
                        <strong>Total:</strong> <span className="font-bold">${totalCost}</span>
                      </div>
                    </div>
                  </div>
                  {(!reservation || reservation.status !== "paid") &&
                    <div className="flex flex-col gap-4">
                      <label className="">Info</label>
                      <p>
                        With some information our team will be in contact with you to process your order!                    
                      </p>
                      <div className="flex gap-2">
                        <div className="flex grow flex-col">
                          <label>First Name</label>
                          <Input onChange={(firstName) => setUserInfo({...userInfo, firstName})}/>
                        </div>
                        <div className="flex grow flex-col">
                          <label>Last Name</label>
                          <Input onChange={(lastName) => setUserInfo({...userInfo, lastName})}/>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <label>Email</label>
                        <Input onChange={(email) => setUserInfo({...userInfo, email})}/>
                      </div>
                      <div className="flex flex-col">
                        <label>Phone Number</label>
                        <Input onChange={(phoneNumber) => setUserInfo({...userInfo, phoneNumber})}/>
                      </div>
                      <div className="flex flex-col">
                        <label>Address</label>
                        <Input onChange={(addressOne) => setUserInfo({...userInfo, addressOne})}/>
                      </div>
                      <ul>
                        <li>Quote: ${totalCost}*</li>
                      </ul>
                      <small className="text-center">
                        * Prices shown exclude transport and may vary by territory/province.
                      </small>
                      <ButtonPrimary disabled={loading || !valid()} onClick={createReservation} className="mt-2" >Reserve</ButtonPrimary>
                    </div>
                  }
                </div>
            </div>
            <div className="w-1/2 sm:hidden sticky top-1 flex flex-col self-start h-screen -mt-20 pb-4">
              <div className="w-full h-80 relative ">
                <img src={containerImage} className="absolute bottom-0" alt="ColdAcre container farm"/>
                <h1 className="absolute bottom-4">
                  <span className={`lightgradient ${(build.size == 6 )? 'inline' : 'hidden'}`}>6</span>
                  <span className={`lightgradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                  <span className={`lightgradient ${(build.size == 16) ? 'inline' : 'hidden'}`}>16</span>  
                </h1>
              </div>
              <div className="flex flex-col items-end bg-light-grey text-sm border p-4 rounded border-grey scroller overflow-y-scroll">
                <div className="w-full flex flex-col mb-2">
                  <strong className="w-full border-b border-dark">Features</strong>
                  {production ? <p className="">Produces {production}<sub>lbs/week</sub></p> : ''}
                  {crops ? <p className="">Grow {crops.join(", ")}<sub></sub></p> : ''}
                  {power ? <p className="">Uses {power}<sub>kWh/month</sub> of power</p> : ''}
                  {getFeatures().map((feature, index) => <p key={index} className="">{feature}</p> )}
                </div>
                <strong className="w-full border-b border-dark">Cost</strong>
                <div className="flex justify-between w-full gap-2">Containter Farm Base: <span>${sizeLookup[build.size] && sizeLookup[build.size].cost}</span></div>
                {Object.entries(build).filter((current) => current[0] !== "size" && current[1]).map(([key]) =>
                  <div className="flex justify-between w-full gap-2" key={key}>{key !== "size" && build[key] &&  build[key].name}: <span>${getCalculatedValue(build, build[key].cost)}</span></div>
                )}
                <hr className="w-full border-white"></hr>
                <div className="flex justify-between w-full gap-2">
                  <strong>Total:</strong> <span className="font-bold">${totalCost}</span>
                </div>
              </div>
            </div>
          </PaddedPage>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
