import React, {useContext} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import ItemsTable from './ItemsTable';
import { useState } from 'react';

const GET_INVENTORY_ITEMS = gql`
  query GetInventoryItems($organizationId: String, $filters: JSONObject ){
    inventoryItems(organizationId: $organizationId, filters: $filters) {
      id,
      name,
      organization {
        name
      },
      quantity {
        quantity,
        createdAt
      },
      notify,
      tags {
        name
      }
    }
  }
`;

export default function Inventory() {
  const {currentUser} = useContext(AppContext);
  const [filters, setFilters] = useState({});
  const {setActiveSubpage, organizationId} = useContext(ActivePageContext);
  const { loading, error, data } = useQuery(GET_INVENTORY_ITEMS, {
    fetchPolicy:'no-cache',
    variables: {organizationId, filters}
  });

  setActiveSubpage("");
  const inventoryItems = (data && data.inventoryItems) || [];

  return (
    <div className="p-4 flex flex-col gap-2">
      <ItemsTable inventoryItems={inventoryItems} setFilters={setFilters} filters={filters}/>
    </div>
  )
}
