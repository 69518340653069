import { faArrowRight, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import Calendar from 'components/calendar';
import { iterationsFrom } from 'helpers/calendar';
import Loading from 'components/common/Loading';

export default function PauseModal({subscription, onConfirm, onClose, loading}) {
  let [open, setOpen] = useState(true);
  let [selected, setSelected] = useState();

  const billingAnchor = subscription.current_period_end || subscription.schedule.phases[0].start_date;

  const close = () => {
    setOpen(false);
    onClose();
  }

  const confirm = () => {
    if (selected) {
      // setOpen(false);
      const startIteration = iterationsFrom(billingAnchor*1000, selected.start.getTime());
      const iterations = iterationsFrom(selected.start.getTime(), selected.end.getTime());
      onConfirm(startIteration, iterations);
    }
  }
  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border w-full max-w-160 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-yellow`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faTriangleExclamation} />
                  &nbsp;
                  Pause Winterharvest Subscription
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2 justify-center">
                <div className="text-center">
                  <h3>
                    Choose how long you would like to pause for
                  </h3>
                  <small>*note subscriptions can only be paused up to 2 months in advance.</small>
                </div>
                <div className="py-4">
                  <Calendar 
                    max={6} 
                    min={0} 
                    billingAnchor={billingAnchor}
                    selectOffset={(subscription.metadata|| subscription.schedule.phases[0].metadata).delivery == "wesdnesday" ? 0 : -1 * 24 * 60 * 60}
                    fuzzyStart={true}
                    onSelected={setSelected}
                  />
                </div>
                {selected &&
                  <div className="text-center">Pause subscription from 
                    <div className="bg-accent-two inline-block mx-1 rounded p-1 text-sm font-bold">{selected.start.toDateString()}</div>
                     <FontAwesomeIcon icon={faArrowRight}/>
                    <div className="bg-accent-one inline-block mx-1 rounded p-1 text-sm font-bold">{selected.end.toDateString()}</div>
                   ?</div>
                }
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className=""  disabled={!selected || loading} onClick={confirm} >{loading ? <Loading/> : 'Pause Subscription'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
