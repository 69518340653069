import { faNoteSticky, faSnowflake } from '@fortawesome/free-regular-svg-icons';
import { faInbox, faPause, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

export default function OrderSheet({order, show}) {
  var currentDay = order.currentDate.getDay();
  var distance = (order.metadata.delivery === "thursday" ? 4 : 3) - currentDay;
  order.currentDate.setDate(order.currentDate.getDate() + distance);
  return (
    <div className={`flex relative justify-between bg-light-grey p-2 rounded w-full ${!show && 'hidden'}`}>
      <div>
        {order.items.find(item => item.price.product.name === "Delivery") ?
          <p><FontAwesomeIcon icon={faTruck}/> Delivery</p>
          :
          <p><FontAwesomeIcon icon={faInbox}/> Pick-Up</p>
        }
        {order.currentDate.toDateString()}
        {order.metadata.notes && <p className="text-sm max-w-80 bg-white p-1 rounded"><FontAwesomeIcon icon={faNoteSticky}/> {order.metadata.notes}</p>}
      </div>
      <div>
        {
          order.items.sort((itemA, itemB) => Number(itemA.price.product.metadata && itemA.price.product.metadata["packing order"]) - Number(itemB.price.product.metadata && itemB.price.product.metadata["packing order"]))
          .map((item, index) => (
            <div 
              key={index} 
              className={`flex items-center gap-1 ${item.price.product.metadata && item.price.product.metadata.storage === "frozen" ? 'border border-yellow bg-yellow border-2 p-1 -ml-1' : ''}`}
            >
              <input type="checkbox"></input>
              <p>
                {item.quantity} x {item.price.product.name} 
              </p>
              <span>
                {item.price.product.metadata && item.price.product.metadata.storage && <FontAwesomeIcon icon={faSnowflake}/>}
                {item.oneTime && <span>🌸</span>}
              </span>
            </div>
          ))
        }
      </div>
      {
        order.metadata.paused === 'true' &&
        <div className="w-full h-full absolute bg-yellow top-0 left-0 rounded opacity-75 flex justify-center items-center">
          <FontAwesomeIcon icon={faPause}/>
          <p> Paused</p>
        </div>
      }
    </div>
  )
}
