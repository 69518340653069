import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';

export default function Dropdown({options, trigger}) {
  const [show, setShow] = useState(false);
  const {currentUser} = useContext(AppContext);
  return (
    <div className="relative">
      <div className="cursor-pointer" onClick={() => setShow(!show)}>
        {trigger}
        <FontAwesomeIcon className="bottom-0 cursor-pointer" icon={faCaretDown}/> 
      </div>
      <div className={`bg-light-light-grey rounded shadow divide-y flex flex-col border right-0 ${show ? 'absolute' : 'hidden'}`}>
          <div className="p-1 px-4 bg-white">
            <p className="whitespace-nowrap text-sm">Logged in as {currentUser.firstName}</p>
          </div>
          <div className="">
            {options.map(option => (
              <div key={option.text} className="hover:bg-light-grey p-1 px-4" onClick={() => setShow(false)}>
                {option.to ?
                    <Link to={option.to} className="no-underline font-md">
                        <div className="whitespace-nowrap" onClick={option.action}>
                            {option.text}
                        </div> 
                    </Link>
                  :
                  <div className="whitespace-nowrap cursor-pointer grow" onClick={option.action}>
                      {option.text}
                  </div>}
              </div>
            ))}
          </div>
      </div>
    </div>
  )
}
