import { gql, useQuery } from '@apollo/client';
import ActivePageContext from 'contexts/ActivePageContext';
import AppContext from 'contexts/AppContext';
import React, { useContext, useState } from 'react'

const GET_ORGNAIZATIONS = gql`
  query GetOrganizations{
    organizations {
      id,
      name,
    }
  }
`;

export default function OrganizationSelect({defaultOrg}) {
  const {currentUser} = useContext(AppContext);
  const {organizationId, setOrganizationId} = useContext(ActivePageContext);
  const { data: organizationsData } = useQuery(GET_ORGNAIZATIONS, {
    skip: currentUser.type !== 'super-admin',
  });

  return (
    <div>
        {currentUser.type === 'super-admin' &&
            <div>
            <select name="organization" id="organization" value={organizationId} onChange={e => setOrganizationId(e.target.value)}>
                <option value="">{defaultOrg || "All"}</option>
                {
                organizationsData &&  organizationsData.organizations.map(organization => 
                    <option 
                    key={organization.id} 
                    value={organization.id}
                    >
                    {organization.name}
                    </option>
                )
                }
            </select>
            </div>
        }
    </div>
  )
}
