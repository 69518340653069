import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import landing from 'assets/images/landing/landing.jpg';
import { Link } from 'react-router-dom';

export default function Banner() {
  return (
    <div className="lg:bg-cover bg-top-20 bg-no-repeat bg-auto" style={{backgroundImage: `url(${landing})`}}>
       <PaddedPage className="flex h-80vh" >
          <div className="w-2/3 flex flex-col justify-center items-start gap-8">
              <h1 className="jumbo text-white backdrop-brightness-90">
                <span className=''>Community-grown</span>, reliable, fresh food.
              </h1>
              <Link to="/prefab">
                <ButtonPrimary className="p-12 px-10 font-bold border-white bg-white" >Learn more</ButtonPrimary>
              </Link>
          </div>
        </PaddedPage>
    </div>
  )
}
