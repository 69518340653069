import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionStripeCardSetupForm from './SubscriptionStripeCardSetupForm';
import { useMutation, gql } from "@apollo/client";
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const INITIALIZE = gql`
  mutation GetPaymentIntent($productId: String, $reservationId: String, $asAdmin: Boolean) {
    getPaymentIntent(productId: $productId, reservationId: $reservationId, asAdmin: $asAdmin) {
      paymentIntent {
        id,
        amount,
        status,
        client_secret,
        metadata
      },
      paymentMethods
    }
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function SubscriptionPaymentForm({onSuccess, valid, loading, update, coupons, setCouponUsed, buttonText, asAdmin}) {
  const {setLoading} = useContext(AppContext);
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const navigate = useNavigate();
  const [initializeMutation, { loading:initializeLoading }] = useMutation(INITIALIZE);

  // if (!loading) {
  //   setLoading(true);
  // } else if (loading) {
  //   setLoading(false);
  // }
  
  useEffect(() => {
    initializeMutation({ variables: {asAdmin} })
      .then(({data}) => {
          setPaymentIntent(data.getPaymentIntent.paymentIntent);
          if (data.getPaymentIntent.paymentMethods && Array.isArray(data.getPaymentIntent.paymentMethods) && data.getPaymentIntent.paymentMethods.length > 0) {
            setPaymentMethods(data.getPaymentIntent.paymentMethods);
          }
      })
      .catch((error) => {
        console.log("error for getting secret", error.message);
        // TODO handle errror
      })
  }, []);

  return (
    <div className="bg-white rounded shadow-md p-4 border-t">
      {paymentIntent ? <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret}}>
        <SubscriptionStripeCardSetupForm coupons={coupons} setCouponUsed={setCouponUsed} update={update} loadingOverride={loading} valid={valid} paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} paymentIntent={paymentIntent} handleSuccess={onSuccess} buttonText={buttonText} asAdmin={asAdmin}/>
      </Elements>
      :
        <FontAwesomeIcon className="text-xl animate-spin" icon={faSpinner} />
      }
    </div>
  );
}

export default SubscriptionPaymentForm;

