import ButtonPrimary from 'components/common/Button'
import React, { useState } from 'react'
import { getCalculatedValue } from 'helpers/orderHelpers';

export default function AddFeature({addFeature, removeFeature, feature, selected, build}) {
  const [selectedOption, setSelectedOption] = useState(feature.options ? {...feature, ...feature.options[0]} : feature);
  const updateSelect = (value) => {
    for(let option of feature.options) {
      if (option.id == value) {
        if (selected) {
          removeFeature();
          addFeature({...feature, ...option});
        }
        setSelectedOption({...feature, ...option});
      }
    }
  }
  return (
    <div className="flex flex-col gap-4">
      <div>
          <label className={`${feature.titleStyle}`}>{feature.name}</label>
          <p>
          <small className="font-bold">${getCalculatedValue(build, selectedOption.cost)}</small>
          </p>
      </div>
      {
        feature.options &&
        <select  onChange={(e)=>updateSelect(e.target.value)}>
          {feature.options.map(option => (
            !option.showCalculation || getCalculatedValue(build, option.showCalculation) ? <option key={option.id} value={option.id} selected={option.id === selectedOption.id}>{option.optionName}</option> : ""
          ))}
        </select>
      }
      <p>
        {selectedOption.description}
      </p>
      <ul>
        {feature.bullets && feature.bullets.map(bullet => <li key={bullet}>{bullet}</li>)}
      </ul>
      <small className="text-center">
          * Prices shown exclude transport and may vary by territory/province.
      </small>
      <ButtonPrimary 
        onClick={() => selected ? removeFeature(selectedOption) : addFeature(selectedOption)} 
        className="mt-2 w-2/3 self-center" 
      >
        {selected ? "Remove" : "Add"}
      </ButtonPrimary>
    </div>
  )
}
