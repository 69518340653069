import React, {useEffect, useMemo, useState} from 'react';
import CalendarDay from './CalendarDay';
import CalendarMonth from './CalendarMonth';
import CalendarWeek from './CalendarWeek';
import CalendarContext from 'contexts/CalendarContext';
import { getDayOffset, getFirstDayOfWeek, iterationsFrom, monthsBetween } from 'helpers/calendar';
import { sameDay } from 'helpers/time';
import SubscriptionOverview from 'screens/winterHarvest/subscriptions/SubscriptionOverview';
import PauseSub from 'screens/winterHarvest/subscriptions/PauseSub';
import CancelSub from 'screens/winterHarvest/subscriptions/CancelSub';
import { subscriptionToClientSubscription } from 'helpers/subscription';
import GetPortal from 'screens/winterHarvest/subscriptions/GetPortal';


const testEventsForMonth = [
  // {
  //   date: new Date(),
  //   type: 'Closing',
  //   icon: '🎏',
  //   name: "Dave's House closing"
  // },
  // {
  //   date: new Date(),
  //   type: 'Task',
  //   icon: '🎉',
  //   name: "Bring Forward Item"
  // },
  // {
  //   date: new Date(),
  //   type: 'Task',
  //   icon: '💎',
  //   name: "Bring Forward Item Two"
  // },
]

const addMonths = (change, date) => {
  let newMonth = date.getMonth() + change;
  if (newMonth > 11) {
    return new Date(date.getFullYear() + 1, newMonth%12, 1);
  } else if (newMonth < 0) {
    return new Date(date.getFullYear() - 1, 12 + newMonth, 1);
  } else {
    return new Date(date.getFullYear(), newMonth, 1);            
  }
}

let offsetLookup = {
  "sunday": 0,
  "monday": 1,
  tuesday: 2, 
  wednesday: 3,
  thursday: 4,
  friday: 5, 
  saturday: 6,
}

const itemsToDetails = (items, billingAnchor, date) => {
  return items.filter(item => {
    if (item.price.nickname.toLowerCase() === "bi-weekly") {
      return Number.isInteger(iterationsFrom(billingAnchor*1000, date.getTime()));
    } else {
      return true;
    }
  }).map(item => `${item.quantity} ${item.price.product.name}`)
}

const subscriptionToCalendarEvents = (subscription, upcommingChanges, billingAnchor) => {
  const calendarEvents = [];
  let upcommingChange = upcommingChanges && upcommingChanges[0];
  let changeIndex = 0;
  let currentSub = subscription;
  let currentDay = getFirstDayOfWeek(new Date());
  let deliveryOffset = offsetLookup[subscription && subscription.metadata && subscription.metadata.delivery];
  let endTime = new Date(currentDay.getTime() + (12 * 7 * 24 * 60 * 60 * 1000));
  while (currentDay < endTime) {
    if (upcommingChange && ((currentDay.getTime() + 4 * 24 * 60 * 60 * 1000) > Number(upcommingChange.start_date)*1000)) {
      deliveryOffset = offsetLookup[upcommingChange.metadata && upcommingChange.metadata.delivery];
      currentSub = upcommingChange;
      billingAnchor = Number(upcommingChange.start_date);
      upcommingChange = upcommingChanges[changeIndex + 1];
      changeIndex ++;
      let details = itemsToDetails(currentSub.items, billingAnchor, currentDay);
      currentSub.metadata.paused !== 'true' && calendarEvents.push({
        date: new Date(billingAnchor*1000),
        type: 'Billing',
        icon: '💰',
        name: "Billing"
      })
      if (details.length > 0 || currentSub.metadata.paused == 'true'){
        calendarEvents.push({
            date: getDayOffset(currentDay, deliveryOffset),
            type: 'Order',
            paused: currentSub.metadata.paused === 'true',
            icon: currentSub.metadata.paused !== 'true' ? '🌿' : '',
            name: "Order",
            details: currentSub.metadata.paused !== 'true' ? details : null
        })
      }
    } else if (currentSub && currentSub.start_date && Number(currentSub.start_date)*1000 < currentDay.getTime() + 4 * 24 * 60 * 60 * 1000 && ((!currentSub.cancel_at_period_end || currentSub.cancel_at_period_end == 'false') || (Number(currentSub.current_period_end)*1000 > (currentDay.getTime() + 4 * 24 * 60 * 60 * 1000) ))) {
      let details = itemsToDetails(currentSub.items, billingAnchor, currentDay);
      if (details.length > 0 || currentSub.metadata.paused == 'true'){
        calendarEvents.push({
            date: getDayOffset(currentDay, deliveryOffset),
            type: 'Order',
            paused: currentSub.metadata && currentSub.metadata.paused === 'true',
            icon: currentSub.metadata.paused !== 'true' ? '🌿' : '',
            name: "Order",
            details: currentSub.metadata.paused !== 'true' ? details : null
        })
      }
      let iterations = iterationsFrom(new Date(billingAnchor*1000), currentDay);
      if ( Number.isInteger(iterations)) {
        let newBillingDate = new Date(billingAnchor*1000);
        newBillingDate.setDate(newBillingDate.getDate() + iterations *14);
        currentSub.metadata.paused !== 'true' && calendarEvents.push({
          date: newBillingDate,
          type: 'Billing',
          icon: '💰',
          name: "Billing"
        })
      }
    }
    let newDate = new Date(currentDay.valueOf());
    newDate.setDate(newDate.getDate() + 7);
    currentDay = newDate;
  }
  for (let oneTimeProduct of subscription ? subscription.oneTimeProducts : []) {
    let dateComponents = oneTimeProduct.date.split('-');
    calendarEvents.push({
        date: new Date(dateComponents[0], Number(dateComponents[1])-1, dateComponents[2]),
        type: 'One-Time',
        paused: currentSub.metadata && currentSub.metadata.paused === 'true',
        icon: '🌸',
        name: "Order",
        details: [`${oneTimeProduct.quantity} ${oneTimeProduct.price.product.name}`]
    })
  }
  return calendarEvents;
}

const SubscriptionCalendar = ({max, min, billingAnchor, subscription, upcommingChanges, children}) => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hoverDate, setHoverDate] = useState();
  const [changesStart, setChangesStart] = useState();
  let calendarEvents = useMemo(() => {
    return subscriptionToCalendarEvents(subscription, upcommingChanges, billingAnchor) || [];
  }, [subscription, upcommingChanges, billingAnchor]);

	const handleSetDate = (val) => {
		setDate(val);
	}
  // eventually can have these dynamic so different select criteria
  const startClickable = (date) => {
    return false;
  }
  const endClickable = (date) => {
    return false;
  }

  const hoverOnDate = (date) => {
    if (startDate && !endDate) {
      setHoverDate(date);
    }
  }

  const selectDate = (date) => {
    setDate(date);
  }

  useEffect(() => {
    if (subscription && (subscription.id || (subscription.schedule && subscription.schedule.phases))) {
      let {changeStart} = subscriptionToClientSubscription(subscription);
      setChangesStart(new Date(changeStart * 1000).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}));
    }
  }, [subscription]);

  const nextMonth = useMemo(() => {
    let newMonth = addMonths(1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min]);

  const prevMonth = useMemo(() => {
    let newMonth = addMonths(-1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min])

  return (
		<CalendarContext.Provider value={{
      date,
      startClickable,
      endClickable,
      selectDate,
      startDate,
      endDate,
      hoverOnDate,
      hoverDate,
      setDate: handleSetDate, 
      monthEvents: calendarEvents, 
      nextMonth, 
      prevMonth
    }}>
      <h2>Calendar</h2>
      <hr></hr>
      <div className="flex w-full gap-8 sm:flex-col">
        <div className="max-w-90vh"  style={{flexGrow: 1}}>
          <div className="flex flex-col gap-2 grow max-w-90vh">
            <CalendarMonth allowSetDate={true}/>
            <div className="flex gap-2">
              {changesStart && ((!subscription.cancel_at_period_end || subscription.cancel_at_period_end === "false" ) || subscription.schedule ) &&
                <>
                  <PauseSub subscription={subscription}/>
                  <CancelSub changesStart={changesStart}/>
                </>
              }
              <GetPortal/>
            </div>
          </div>
        </div>
        <div className="flex gap-8 flex-col" style={{flexGrow: 1}}>
          <CalendarWeek/>
          <div className="w-full">
            {children}
          </div>

        </div>

      </div>
		</CalendarContext.Provider>
  )
}

export default SubscriptionCalendar;
