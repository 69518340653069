import React from 'react';
import { Route } from 'react-router-dom';
import SubscriptionOverview from './SubscriptionsOverview';
import Subscription from './Subscription';


const routes = [
  <Route key="subscription" path="/subscription" element={<Subscription/>} />,
  <Route key="staff-subscription" path="/subscription/:staffCode" element={<Subscription/>} />,
  <Route key="my-subscription" path="/my-subscription" element={<SubscriptionOverview/>} />,
];

export default routes;
