import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/farm.jpg';
import ModelDiagram from './ModelDiagram';
import isocontainer from 'assets/images/prefab/isocontainer.png';
import { Link } from 'react-router-dom';

const PrefabUnits = () => {
  const {setPage} = useContext(AppContext);
  const canvasRef = useRef();
  const parentRef = useRef();

  useEffect(() => setPage("Prefab Units"), [setPage]);
  
  const drawCanvas = () => {
    let ctx = canvasRef.current.getContext('2d');
    let width = canvasRef.current.width;
    let height = width;
    ctx.beginPath();     // Start a new path.
    ctx.lineWidth = "2";
    ctx.strokeStyle = "#27B67A";  // This path is green.
    ctx.arc(width*0.3, height/2, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width*0.3, height/2);
    ctx.lineTo(width * 0.4, height * 0.7);
    ctx.lineTo(width * 0.42, height * 0.7);
    ctx.font = "20px Arial";
    ctx.fillStyle = "white";
    ctx.fillText("Automated Planting Trays", width * 0.42, height * 0.7);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(width * 0.15, height * 0.5, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width * 0.15, height * 0.5);
    ctx.lineTo(width * 0.2, height * 0.1);
    ctx.lineTo(width * 0.23, height * 0.1);
    ctx.textAlign = "start";
    ctx.fillText("Arctic Entrance & Produce Cooler", width * 0.23, height * 0.1);
    ctx.stroke();  

    ctx.beginPath();
    ctx.arc(width * 0.95, height * 0.4, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width * 0.95, height * 0.4);
    ctx.lineTo(width * 0.9, height * 0.25);
    ctx.lineTo(width * 0.875, height * 0.25);
    ctx.textAlign = "end";
    ctx.fillText("HVAC", width * 0.875, height * 0.25);
    ctx.stroke();  
    
    ctx.closePath(); // Close the current path.
  }

  const windowResize = useCallback(() => {
      if (canvasRef.current) {
          canvasRef.current.width  = parentRef.current.clientWidth;
          canvasRef.current.height = parentRef.current.clientWidth;
          drawCanvas();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
  }, [windowResize]);

  return (
    <div className="bg-dark" id="build">
      <PaddedPage className="flex gap-8 items-center sm:flex-col" >
        <div className="w-full relative" ref={parentRef}>
          {/* <ModelDiagram parentRef={parentRef}></ModelDiagram> */}
          <img src={isocontainer} className="mb-10 mt-20vw 2xl:mt-64 sm:mt-25vw" alt="isometric view of conatiner farm"/>
          <canvas ref={canvasRef} className="absolute top-0 left-0" id="myCanvas" width="300" height="600"></canvas>
        </div>
        <div className="w-1/3 flex flex-col z-10 sm:w-full">
            <h2>Farm</h2>
            <h1>Anatomy</h1>
            <p>
              Hydroponics is a soilless growing technique. All of our technology uses nutrient enriched water and light
              systems to deliver everything that the plants need to grow. As a result, our technology typically sees growth at
              twice the speed of traditional growing techniques. Hydroponics are very effective when growing leafy greens,
              herbs, and fruiting bodies such as vegetables. The ColdAcre unit specifically focuses on leafy greens and
              herbs. 
            </p>
            <br></br>
            <Link to="/order" className="w-full">
              <ButtonPrimary className="mt-2 w-full" white >Order Now</ButtonPrimary>
            </Link>
        </div>
        </PaddedPage>
    </div>
  );
};

export default PrefabUnits;
