import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import AnonNav from './AnonNav';
import SalesNav from './SalesNav';
import Logo from 'assets/images/logo/LogoFullColor.png';


const NavBar = ({ childRef }) => {
  const location = useLocation();
  const { currentUser, page, organization } = useContext(appContext);
  const [top, setTop] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setTop(scrollTop < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return (() => window.removeEventListener('scroll', handleScroll));
    } else {
      setTop(true);
    }
  }, [location.pathname]);

  return (
    <div
    className={`
      ${!page && 
        'sticky'
      }
      top-0
      flex 
      justify-center
      bg-transparent-white
      border-b
      z-30
      hide-print
    `}
    style={{
      backdropFilter: "blur(1px)"
    }}>
      <div  
        ref={childRef} 
        className={`
          flex 
          justify-between
          py-3
          w-full
          sm:px-6
          md:px-12 
          lg:px-16 
          xl:px-24 
          2xl:max-w-7xl 
          2xl:p-auto
          2xl:max-w-7xl
        `}
      > 
        <Link to="/">
          {
            organization ? 
            <div className="relative">
              <div className="w-32 h-10 bg-contain bg-no-repeat" style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/organizations/${organization.subdomain}.png)`}}/>
              <div className="absolute -bottom-3 right-0 flex  items-center">
                {/* <span className="text-xxs font-bold">powered by</span> */}
                <div className="w-16 h-5 bg-contain" style={{backgroundImage: `url(${Logo})`}}/>
              </div>
            </div>
            :
            <div className="w-32 h-10 bg-contain" style={{backgroundImage: `url(${Logo})`}}/>
          }
        </Link>
        <SalesNav/>
        {currentUser ?
          <UserNav/>
          :
          <AnonNav/>
        }
      </div>
    </div>
  );
};

export default NavBar;
