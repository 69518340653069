import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import { HeadingOne, HeadingThree, TextBodyXLg } from 'components/common/Textbox';
import {ButtonPrimary} from 'components/common/Button';

const TermsAndConditions = () => {
  return (
    <PaddedPage noTop={true}>
      <div className="
        sm:py-6 
        md:py-24 
        "
      >
        <div className="
          flex
          flex-col
          gap-6
        "
        >
          <HeadingThree>Terms And Conditions</HeadingThree>
          <TextBodyXLg>
            <ol className="list-decimal flex flex-col gap-2">
              <li>
                Once orders have been delivered, they are no longer the responsibility of ColdAcre. We cannot guarantee the temperature of your products once the delivery is made. We highly recommend being home for your delivery. 
              </li><li>
                The deadline to change your subscription is the Saturday before your next billing date. Refer to the subscription calendar in your account for a full view of billing dates and upcoming orders. Any subscription changes made after the billing date will only apply after the next billing cycle. 
              </li><li>
                Pick-up orders must be collected within the designated pick-up hours of noon to 6:30pm on Wednesday or Thursday. If a pick-up is missed, customers may come the next day to pick-up their order during the same time-slot, however we cannot guarantee the same freshness of your products if picked up late.
              </li><li>
                Any pick-up orders not collected by 4pm Friday will be considered forfeit. No refunds will be given for forfeit orders.
              </li><li>
                Skipping upcoming orders must be done by the Saturday beforehand via your account settings or by sending an email to winterharvest@coldacre.ca. We cannot cancel orders after the order has been processed as it is significant work to harvest, order and prepare all the products for the subscription.
              </li>
            </ol>
          </TextBodyXLg>
        </div>
      </div>
    </PaddedPage>
  );
};

export default TermsAndConditions;
