import React, {useContext, useState} from 'react';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import Product from './Product';
import { useQuery, gql } from "@apollo/client";
import { useEffect } from 'react';

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
      metadata,
      active,
      unit_label,
      prices {
        id,
        active,
        nickname,
        recurring {
          aggregate_usage,
          interval,
          interval_count,
          usage_type,
        },
        type,
        unit_amount
      }
    }
  }
`;

const categoryLookup = {
  Vegetables: 1,
  'Eggs & Dairy': 2,
  'Baked Goods': 3,
  Meat: 4,
  Drinks: 5,
  'Ready-Made Meals': 6,
  'Prepared Meals': 6,
  'Pantry Staples': 8,
}

const Products = ({title, subtitle, query, subscription, items, onChange, small, oneTime, summary, medium, categories, search, delivery}) => {
  const [show, setShow] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: { query },
    fetchPolicy:'no-cache',
    skip: query ? false : true,
  });
  subscription = subscription || {};
  items = items || subscription.items;

  useEffect(() =>{
    if (subscription && subscription.items && !subscription.load) {
      onChange && onChange((subscription) => {
        let filteredItems = {...subscription.items};
        for (let item in filteredItems) {
          if (!filteredItems[item].metadata || filteredItems[item].metadata.subtype !== "delivery") {
            delete filteredItems[item];
          }
        }
      return {...subscription, items: filteredItems}
      });
    }
    onChange && onChange((subscription) => ({...subscription, load: null}))
  }, [subscription.location, subscription.interval, subscription.empty])

  const updateProducts = (change) => {
    if (subscription && subscription.items) {
    onChange && onChange((subscription) => ({...subscription, items: {...subscription.items, ...change}}));
    } else {
    onChange && onChange({...items, ...change});
    }
  }
  
  const priceDefault = (product) => {
    if (oneTime) {
      return product.prices.find(price => price.active && price.nickname === 'One-Time');
    }
    return items[product.id] && items[product.id].price;
  }

  let productList = data && data.products.filter(product => summary || (product.metadata.location === subscription.location && (product.metadata.exclude ? (product.metadata.exclude === "pick-up" ? delivery : !delivery): true)))
  .reduce((prev, current) => {
    prev[current.metadata.category] = [...(prev[current.metadata.category] || []), current];
    return prev;
  }, {});

  return (
    <div className="flex flex-col gap-12 sm:gap-8">
      <div>
        {title && <div onClick={() => setShow(!show)} className="cursor-pointer flex justify-between py-2 pr-2 items-center bg-light-light-grey">
            <h2>{title}</h2>
            <FontAwesomeIcon className="text-xl" icon={show ? faChevronUp : faChevronDown} />
        </div>}
        <div>{subtitle}</div>
        {
          search &&
          <div className="relative mt-6">
            <FontAwesomeIcon className="absolute top-1 left-1" icon={faSearch}/>
            <input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} className="border-2 border-background-dark rounded w-120 pl-6 font-bold"></input>
          </div>
        }
      </div>
      <div className={`flex flex-col gap-2 ${!show && 'hidden'}`}>
        {
          productList && Object.entries(productList).sort(([category, products], [categoryB, productsB]) => (categoryLookup[category] || 0) - (categoryLookup[categoryB] || 0)).map(([category, products]) =>
            <div key={category}>
              {category && categories && 
                <div className="pb-4 pt-8">
                  <h3 className="text-lg italic">{category}</h3>
                  <hr></hr>
                </div>
              }
              <div className={`flex ${small ? 'gap-4 flex-col' : medium ? 'flex-row flex-wrap gap-8' : 'gap-16 flex-col'} `}>
                {
                  products && products.filter(product => !searchValue || product.name.toLowerCase().includes(searchValue.toLowerCase())).map((product, i) => (
                    <Product 
                      key={i} 
                      small={small} 
                      medium={medium}
                      summary={summary}
                      updateProducts={updateProducts} 
                      items={items} 
                      oneTime={oneTime}
                      interval={subscription.interval}
                      quantityDefault={items[product.id] && items[product.id].quantity} 
                      priceDefault={priceDefault(product)} 
                      product={product}/>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          medium && data && data.products &&
          <>
              <Product 
                small={small} 
                medium={medium}
                summary={summary}
                items={items} 
                oneTime={oneTime}
                interval={subscription.interval}
                product={data.products[0]}
                hidden={true}/>
              <Product 
                small={small} 
                medium={medium}
                summary={summary}
                items={items} 
                oneTime={oneTime}
                interval={subscription.interval}
                product={data.products[0]}
                hidden={true}/>
          </>
        }
      </div>
    </div>
  );
};

export default Products;
