import React from 'react';
import { Route } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import AnonRoute from 'components/routes/AnonRoute';
import ForgotPassword from './ForgotPassword';
import SignupSuccess from './SignupSuccess';
import ConfirmEmail from './ConfirmEmail';
import PasswordReset from './PasswordReset';

const routes = [
  <Route key="login" path="/login" element={<AnonRoute><Login/></AnonRoute>} />,
  <Route key="sign-up" path="/sign-up" exact element={<AnonRoute><Signup/></AnonRoute>} />,
  <Route key="signup-success" path="/signup-success" element={<AnonRoute><SignupSuccess/></AnonRoute>} />,
  <Route key="forgot-password" path="/forgot-password" exact element={<AnonRoute><ForgotPassword/></AnonRoute>} />,
  <Route key="confirm-email" path="/confirm/email/:authUUID" element={<ConfirmEmail/>} />,
  <Route key="password-reset" path="/password-reset/:id" element={<AnonRoute><PasswordReset/></AnonRoute>} />,
];

export default routes;
