import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';

export default function User({user, firmId}) {
  return (
    <Link className="no-underline" to={`/admin/super/users/${user.id}`}>
      <div className={`rounded cursor-pointer justify-between flex border-l-8 border-light-grey shadow-md hover:shadow-lg p-2 items-center gap-2`}>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon className="" icon={faUser} />
            <div className="flex items-center gap-2 ml-2">
              <small><FontAwesomeIcon className="" icon={faTag} /> {user.firstName} {user.lastName}</small>
              <small><FontAwesomeIcon className="" icon={faEnvelope} /> {user.email}</small>
              {user.organization && <small><FontAwesomeIcon className="" icon={faBuilding} /> {user.organization}</small>}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {user.stripeCustomerId && <a onClick={(e) => e.stopPropagation()} href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`} target="_blank"><div className="rounded bg-blue border text-white px-2 text-sm font-bold"><FontAwesomeIcon icon={faStripe}/></div></a>}
            {["admin", "super-admin"].includes(user.type) && <div className="rounded bg-red text-white px-2 text-sm font-bold">{user.type.toUpperCase()}</div>}
            {
              user.activeSubscription &&
              <div className={`rounded px-2 bg-primary text-sm text-white font-bold`}>
                Subscribed
              </div>
            }
            {
              !user.activeSubscription && user.hasSubscribed &&
              <div className={`rounded px-2 bg-red text-sm text-white font-bold`}>
                Cancelled
              </div>
            }
          </div>
      </div>
    </Link>
  )
}
