import Message from 'components/common/Message';
import React, { useMemo } from 'react';

const presentableNumber = (num) => {
  return (Math.round(num*100)/100).toFixed(2)
}

const weeklyPrice = (item) => {
  let price = (item.quantity * Number(item.price.unit_amount))/100;
  if (item.price.nickname.toLowerCase() === "weekly") {
    price = price/2;
  }
  return presentableNumber(price);
}

const priceWithCoupon = (total,coupon) => {
  if (coupon.amount_off) {
    return presentableNumber(total - Number(coupon.amount_off)/100);
  } else if (coupon.percent_off) {
    return presentableNumber(total - (total * Number(coupon.percent_off)/100));
  }
}

const freeShippingCheck = (items) => {
  let weekOneTotal = 0;
  let weekTwoTotal = 0;
  for (let [key, item] of items) {
    if (!item || !item.price) continue;
    if (item.price.nickname.toLowerCase() !== "bi-weekly") {
        weekTwoTotal += item.quantity * Number(item.price.unit_amount)/2;
        weekOneTotal += item.quantity * Number(item.price.unit_amount)/2;
    } else {
      weekOneTotal += item.quantity * Number(item.price.unit_amount);
    }
  }
  if (weekOneTotal > 7500 && (!weekTwoTotal || weekTwoTotal > 7500)) {
      return true;
  }
}

export default function OrderSummary({subscription, errors, couponUsed}) {

  const items = Object.entries(subscription.items).filter(([key,item]) => item.quantity > 0).sort(([key,item],[key2,item2]) => {
    if (item.price.nickname.toLowerCase() === "weekly") {
      if (item2.price.nickname.toLowerCase() === "weekly") {
        return 0;
      } else {
        return -1;
      }
    } else if (item2.price.nickname.toLowerCase() === "weekly") {
      return 1;
    }
  });
  const freeShipping = useMemo(() => freeShippingCheck(items), [items]);

  const weeklyItems = items.filter(([key,item]) => item.price.nickname.toLowerCase() === "weekly");
  const weeklyItemsTotal = weeklyItems.reduce((prev, [key,item]) => {
    return prev + ((freeShipping && item.price.product && item.price.product.name === 'Delivery') ? 0 : ((item.quantity * Number(item.price.unit_amount))/100));
  }, 0);
  const total = items.reduce((prev, [key,item]) => {
    return prev + ((freeShipping && item.price.product && item.price.product.name === 'Delivery') ? 0 : ((item.quantity * Number(item.price.unit_amount))/100));
  }, 0);
  const week1Total = weeklyItemsTotal/2;
  const week2Total = (total - weeklyItemsTotal) + week1Total;

  return (
    <div>
        <h3>Summary</h3>
        <hr></hr>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            {errors && errors.map((error, i) => <Message key={i} className="text-center" negative>{error}</Message>)}
          </div>
          <Message positive={true} className="text-center">
            {couponUsed ?
              couponUsed.name
              :
              freeShipping ?
                "Congratulations, you've earned free shipping with this order."
                :
                "Free Delivery on weekly orders over $75"
            }
          </Message>
        </div>
        <div className="flex flex-col gap-1 mt-2">
          {weeklyItems.length > 0 &&
            <div className="flex flex-col gap-2">
              <div>
                  <h4 className="text-xs">{weeklyItems.length !== items.length ? "Week 1" : "Weekly"}</h4>
                <hr></hr>
              </div>
              {weeklyItems.map(([key,item]) => (
                <div className="flex justify-between pr-1">
                  <div className="flex gap-2">
                    <div className="rounded bg-light-grey px-2 flex justify-center items-center text-xxs text-primary-two">
                      {item.price && item.price.nickname}
                    </div>
                    <strong>
                      <small>
                        {item.quantity} x {item.price && item.price.product && item.price.product.name}
                      </small>
                    </strong> 
                  </div>
                  <p className={`${(item.price && item.price.product && item.price.product.name) === "Delivery" && freeShipping ? 'line-through'  : ''}`}>
                    ${weeklyPrice(item)}
                  </p>
                </div>
              ))}
              <div className="flex justify-between bg-light-grey items-center px-1">
                <div className="flex gap-2">
                  <div className="font-bold text-xs">
                    Subtotal 
                  </div>
                </div>
                <p>${presentableNumber(week1Total)}</p>
              </div>
              <hr></hr>
            </div>
          }
          {
            weeklyItems.length !== items.length &&
            <div className="flex flex-col gap-2">
              <div>
                <h4 className="text-xs">{weeklyItems.length > 0 ? "Week 2" : "Bi-Weekly"}</h4>
                <hr></hr>
              </div>
              {items.map(([key,item]) => (
                <div className="flex justify-between pr-1">
                  <div className="flex gap-2">
                    <div className="rounded bg-light-grey px-2 flex justify-center items-center text-xxs text-primary-two">
                      {item.price && item.price.nickname}
                    </div>
                    <strong>
                      <small>
                        {item.quantity} x {item.price && item.price.product && item.price.product.name}
                      </small>
                    </strong> 
                  </div>
                  <p className={`${(item.price && item.price.product && item.price.product.name) === "Delivery" && freeShipping ? 'line-through'  : ''}`}>
                    ${weeklyPrice(item)}
                  </p>
                </div>
              ))}
              {weeklyItems.length > 0 &&
                <div className="flex justify-between bg-light-grey items-center px-1">
                  <div className="flex gap-2">
                    <div className="font-bold text-xs">
                      Subtotal 
                    </div>
                  </div>
                  <p>${presentableNumber(week2Total)}</p>
                </div>
              }
              <hr></hr>
            </div>
          }
          <div className="flex justify-between">
            <div className="flex gap-2">
              <div className="font-bold">
                Total 
              </div>
              <strong>
                <small>
                  Billed Bi-Weekly
                </small>
              </strong> 
            </div>
            <div className="flex flex-col gap-2 items-end">
              <p
                className={`${couponUsed && 'line-through'}`}
              >
                ${total.toFixed(2)}
              </p>
              {
                couponUsed &&
                <p className="text-green">${priceWithCoupon(total, couponUsed)}</p>
              }
            </div>
          </div>
        </div>
    </div>
  )
}
