import React, {useContext, useEffect, useState} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faArrowRight, faTruckFast, faUtensils, faClock, faPause, faMoneyBill1Wave, faWarning} from '@fortawesome/free-solid-svg-icons';
import Products from './Products';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LocationPicker from 'components/subscriptions/LocationPicker';
import DeliveryPicker from 'components/subscriptions/DeliveryPicker';
import SubscriptionPaymentForm from 'components/payments/SubscriptionPaymentForm';
import { useMutation, gql, useQuery } from "@apollo/client";
import MiniButton from 'components/common/Button/MiniButton';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import PauseModal from './PauseModal';
import { useMemo } from 'react';
import { getNextBillingCycle, subscriptionToClientSubscription, getDefaultSubscription } from 'helpers/subscription';
import bagImage from 'assets/images/winterharvest/bag.png';
import OrderSummary from './OrderSummary';
import IntervalPicker from 'components/subscriptions/IntervalPicker';
import CancelSub from './CancelSub';
import PauseSub from './PauseSub';
import OffsetModal from 'components/subscriptions/OffsetModal';
import SignUp from 'screens/auth/Signup';

const CREATESUBSCRIPTION = gql`
  mutation CreateSubscription($items: JSON, $location: String!, $delivery: String, $paymentMethod: String, $notes: String, $coupon: String) {
    createSubscription(items: $items, location: $location, delivery: $delivery, paymentMethod: $paymentMethod, notes: $notes, coupon: $coupon) {
      status
    }
  }
`;

const OFFSET_SUBSCRIPTION = gql`
  mutation OffsetSubscriptionBilling($direction: Float) {
    offsetSubscriptionBilling(direction: $direction) {
      id
    }
  }
`;

const GET_SUBSCRIPTION = gql`
  query GetSubscription {
    subscription {
      id,
      cancel_at_period_end,
      items {
        price{
          id,
          metadata,
          nickname,
          active,
          recurring{
            interval,
            interval_count
          },
          product{
            id,
            description,
            image,
            name,
            active,
            metadata
          },
          unit_amount
        }
        metadata,
        quantity
      },
      start_date,
      current_period_end,
      current_period_start,
      schedule {
        current_phase {start_date, end_date},
        metadata,
        phases{
          end_date,
          start_date,
          trial_end,
          metadata,
          items{
            quantity,
            price{
              id,
              active,
              metadata,
              nickname,
              recurring{
                interval,
                interval_count
              },
              product{
                id,
                active,
                name,
                metadata
              },
              unit_amount
            }
          }
        }
        status
      },
      metadata
    }
  }
`;

const Subscription = () => {
  const {setPage, currentUser, impersonating} = useContext(AppContext);
  const { loading: subloading, error, data } = useQuery(GET_SUBSCRIPTION, {
    fetchPolicy:'network-only',
    skip: !currentUser,
  });
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState({items: [], location: "whitehorse", empty: true});
  const [changesStart, setChangesStart] = useState();
  const [showChangeBilling, setShowChangeBilling] = useState(false);
  const [couponUsed, setCouponUsed] = useState();
  const [delivery, setDelivery] = useState();
  const existingSubscription = data && data.subscription && (data.subscription.id || data.subscription.schedule);
  
  useEffect(() => {
    if (!subscription.empty) {
      localStorage.setItem('subscription', JSON.stringify(subscription));
    }
  }, [subscription]);

  useEffect(() => {
    if (data && data.subscription && (data.subscription.id || (data.subscription.schedule && data.subscription.schedule.phases))) {
      let {subscription:existingSubscription, changeStart} = subscriptionToClientSubscription(data.subscription);
      let interval = "bi-weekly";
      for (let [key,item] of Object.entries(existingSubscription.items)) {
        if (!item.price.active || !item.price.product.active) {
          if (item.price.product?.metadata?.subtype !== 'delivery') {
            // delete existingSubscription.items[key];
          }
        }
        if (item.price.nickname.toLowerCase() === "weekly") {
          interval = null;
        }
      }
      setChangesStart(new Date(changeStart * 1000).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}));
      setSubscription({...subscription, ...existingSubscription, interval, load: true});
    } else {
      setSubscription(getDefaultSubscription());
    }
  }, [data]);

  const invalid = useMemo(() => {
    let weekly = 0;
    let biWeekly = 0;
    let weeklyAlt = 0;
    let biWeeklyAlt = 0;
    let errors = [];
    for (let [key, item] of Object.entries(subscription.items)) {
      if (!item.price) continue;
      if (item.price.product.metadata && item.price.product.metadata.vendor && item.price.product.metadata.vendor.toLowerCase() === "coldacre") {
        if (item.price.nickname.toLowerCase() === "bi-weekly") {
          biWeekly+= Number(item.quantity);
        } else {
          weekly+= Number(item.quantity);
        }
      } else if (!item.price.product.metadata || item.price.product.metadata.subtype !== "delivery"){
        if (item.price.nickname.toLowerCase() === "bi-weekly") {
          biWeeklyAlt+= Number(item.quantity);
        } else {
          weeklyAlt+= Number(item.quantity);
        }
      }
    }
    if (!subscription.delivery) {
      errors.push("Select a day for pick-up or delivery.")
    }
    if (params.get("staffcode") !== process.env.REACT_APP_STAFF_CODE) {
      if (!weekly && !biWeekly) {
        errors.push("Add Essential Greens to your subscription.")
      }
      if ((weeklyAlt || weekly) && weekly < 1) {
        errors.push("Your weekly order must have a minimum of 1 Essential Green.");
      }
      if ((biWeeklyAlt || biWeekly) && (weekly + biWeekly) < 1) {
        errors.push("Your Bi-Weekly order must have a minimum of 1 Essential Green.");
      }
    }
    return (errors.length === 0) ? false : errors;
  }, [subscription]);

  useEffect(() => setPage("Prefab Units"), [setPage]);
  const [createSubscriptionMutation, { loading }] = useMutation(CREATESUBSCRIPTION);
  const [offsetSubscriptionMutation, { loading:offsetLoading }] = useMutation(OFFSET_SUBSCRIPTION);

  const createSubscription = (paymentMethod, coupon) => {
    const items = Object.entries(subscription.items).filter(([key, item]) => item.quantity > 0 && item.price.product.metadata && item.price.product.metadata.location === subscription.location).map(([key, item]) => ({price: item.price.id, quantity: item.quantity}))
    createSubscriptionMutation({ variables: {...subscription, items, paymentMethod, coupon} })
    .then(res => {
      if (!existingSubscription) {
        window.gtag('event', 'subscribe', {
          'email': currentUser.email,
        });
      }
      navigate('/my-subscription')
    })
  }

  const offsetSubscription = (direction = 1) => {
    setShowChangeBilling(false);
    offsetSubscriptionMutation({variables: {direction}})
      .then(res => {
        navigate('/my-subscription')
      })
  }

  return (
    <>
      {showChangeBilling && 
        <OffsetModal
          allowDirection={(!data.subscription.id && data.subscription.schedule )}
          loading={offsetLoading}
          onConfirm={offsetSubscription}
          changesStart={changesStart}
          onClose={() => setShowChangeBilling(false)}
        />
      }
      <div className="flex flex-col">
        <div className="bg-cover pt-12">
          <PaddedPage no-bottom className="flex" >
            <div className="w-1/2 flex flex-col relative sm:w-full">
                <p className="absolute right-0 sm:relative">
                  {currentUser ? 
                    <>Welcome, {currentUser.firstName}</>
                    :  
                    <>Have an account? <Link to="/login">Login</Link></>
                  }
                </p>
                <h1><span className="gradient">WinterHarvest</span> <br></br>Subscription</h1>
                <p>Yukon's Local Food Subscription.</p>
                {existingSubscription && ((!data.subscription.cancel_at_period_end || data.subscription.cancel_at_period_end === "false" ) || data.subscription.schedule ) &&
                  <div className="flex gap-2">
                    {/* <MiniButton onClick={() => promptCancel()} className="text-red border-red"><FontAwesomeIcon icon={faTimes} /> Cancel subscription</MiniButton> */}
                    <CancelSub changesStart={changesStart}/>
                    <PauseSub subscription={data.subscription} />
                    {impersonating && <MiniButton onClick={() => setShowChangeBilling(true)} className="text-yellow border-yellow"><FontAwesomeIcon icon={faMoneyBill1Wave} /> Offset Subscription</MiniButton>}
                  </div>
                }
                <div className="flex flex-col gap-16 mt-4">
                  <div className="flex flex-col gap-2">
                    <div className="p-6 rounded-lg bg-light-grey flex flex-col gap-4">
                      <p>
                        Time to build your order of fresh, local food! You'll be able to add one-time products on the next page once your base order is complete.
                      </p>
                      <div className="flex justify-center gap-8">
                        <FontAwesomeIcon className="text-xl" icon={faDesktop} />
                        <FontAwesomeIcon className="text-md" icon={faArrowRight} />
                        <FontAwesomeIcon className="text-xl" icon={faTruckFast} />
                        <FontAwesomeIcon className="text-md" icon={faArrowRight} />
                        <FontAwesomeIcon className="text-xl" icon={faUtensils} />
                      </div>
                    </div>
                    {
                      changesStart &&
                      <div className="p-6 rounded-lg border border-dark flex flex-col gap-4">
                        <p>
                        <FontAwesomeIcon className="text-xl" icon={faClock} /> Any subscription changes will take place at the start of the next billing cycle {changesStart}.
                        </p>
                      </div>
                    }
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="p-6 rounded-lg bg-yellow flex flex-col gap-4">
                      <p>
                        <FontAwesomeIcon className="text-xl text-orange pr-4" icon={faWarning} />
                        Thank you for your interest in supporting local food! WinterHarvest is paused until September 2nd. We're upgrading our equipment and taking some time off with loved ones.
                      </p>
                      <p>
                        Set up your subscription now to have fresh, locally grown, pesticide free greens delivered to you this fall. Order today and receive greens September 4th or 5th. Have a wonderful summer,
                      </p>
                      The WinterHarvest Team
                    </div>
                    {
                      changesStart &&
                      <div className="p-6 rounded-lg border border-dark flex flex-col gap-4">
                        <p>
                        <FontAwesomeIcon className="text-xl" icon={faClock} /> Any subscription changes will take place at the start of the next billing cycle {changesStart}.
                        </p>
                      </div>
                    }
                  </div>
                  <LocationPicker defaultLocation={subscription.location} onChange={(location) => setSubscription((subscription) => ({...subscription, location}))}/>
                  <IntervalPicker defaultInterval={subscription.interval} onChange={(interval) => setSubscription((subscription) => ({...subscription, interval}))}/>
                  <DeliveryPicker 
                    defaultDelivery={subscription.delivery}
                    subscription={subscription} 
                    setDelivery={setDelivery}
                    onChange={(delivery) => setSubscription((subscription) => ({...subscription, delivery}))}
                    onNotesChange={(notes) => setSubscription((subscription) => ({...subscription, notes}))}
                    updateDeliveryCharge={(delivery) => setSubscription((subscription) => ({...subscription, items: {...subscription.items, ...delivery}}))} 
                  />
                </div>
            </div>
            <div className="w-1/2 sticky top-8 flex justify-center self-start sm:hidden">
              <div className="w-80 h-96 bg-no-repeat bg-contain bg-center rounded-xl" style={{backgroundImage: `url(${bagImage})`}}>
              </div>
            </div>
          </PaddedPage>
          <PaddedPage noTop className="flex flex-col gap-16">
            <Products 
              delivery={delivery}
              query="metadata['vendor']:'coldacre' -metadata['subtype']:'one-time'" 
              title="Essential Greens" 
              subtitle="Choose at least one essential green."
              onChange={setSubscription} 
              subscription={subscription || {}}
            />
            <Products 
              delivery={delivery}
              categories={true}
              query="-metadata['vendor']:'coldacre' -metadata['subtype']:'delivery' -metadata['subtype']:'one-time'" 
              title="Add-Ons"
              search
              subtitle={<p>
                Choose other local products that will be added to each essential greens order. If you are interested in receiving a product only once, rather than on a recurring basis, check out our one-time product feature on the next page.<br></br><br></br>
                Please note: Subscription changes must be made by the Saturday before your next billing cycle.
                </p>
              }
              medium={true} 
              onChange={setSubscription} 
              subscription={subscription || {}}
            />
            <OrderSummary subscription={subscription} errors={invalid} couponUsed={couponUsed}/>
            <div className="flex flex-col gap-4">
              {currentUser ?
                  <SubscriptionPaymentForm 
                    update={existingSubscription}
                    valid={!invalid} 
                    loading={loading} 
                    onSuccess={createSubscription}
                    coupons={true}
                    setCouponUsed={setCouponUsed}
                  />
                :
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2">
                      <label className="">Create An Account Before Finalizing your Subscription</label>
                      {/* TODO have the login redirect back to sub builder */}
                      <small>Have an account? <Link to="/login">Login</Link></small>
                    </div>
                    <SignUp embedded={true} onSuccess={()=> console.log("success")}/>
                  </div>
              }
            </div>
          </PaddedPage>
        </div>
      </div>
    </>
  );
};

export default Subscription;
