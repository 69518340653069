import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faLocationDot, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import card1 from 'assets/images/landing/card1.jpg';
import card2 from 'assets/images/landing/card2.jpg';


export default function Cards() {
  return (
    <div className="flex flex-col">
        <PaddedPage className="flex flex-col items-stretch gap-8">
            <div className="flex flex-col justify-center text-center pb-2">
              <h1 className="jumbo">Farms that fit your needs,</h1>
              <h3 className="text-med-grey text-2xl font-med">no matter where you are.</h3>
              {/* <h2 className="mt-2">
                Commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </h2> */}
            </div>
          <div className="flex gap-8 justify-center flex-wrap mt-12 sm:mt-0">
            <div>
              <div className="rounded-xl mb-4 bg-primary h-96 w-80 bg-cover bg-center flex justify-center items-center"
                style={{backgroundImage: `url(${card2})`}}
              >
                <FontAwesomeIcon className={`text-white text-9xl`} icon={faLightbulb}/>
              </div>
              <h2>Any Weather</h2>
              <h3 className="text-med-grey text-xl font-med w-80">Our self contained systems provide an optimal growing enviornment.</h3>
            </div>
            <div>
              <div className="rounded-xl mb-4 bg-primary h-96 w-80 flex -mt-12 sm:mt-0 justify-center items-center">
                <FontAwesomeIcon className={`text-white text-9xl`} icon={faLeaf}/>
              </div>
              <h2>Efficient</h2>
              <h3 className="text-med-grey text-xl font-med w-80">Use less water and produce more compated to traditional farming.</h3>
            </div>
            <div>
              <div className="rounded-xl mb-4 bg-primary h-96 w-80 bg-cover bg-center flex justify-center items-center"
                style={{backgroundImage: `url(${card1})`}}
              >
                <FontAwesomeIcon className={`text-white text-9xl`} icon={faLocationDot}/>
              </div>
              <h2>Any Location</h2>
              <h3 className="text-med-grey text-xl font-med w-80">Drop and go, you can farm anywhere a container can fit. </h3>
            </div>
          </div>
        </PaddedPage>
    </div>
  )
}
