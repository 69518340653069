import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, gql } from "@apollo/client";


export default function DeliveryNotes({onChange, subscription}) {
 
  return (
    <div className="flex flex-col gap-4">
      <label>Delivery Notes</label>
      <textarea className="border-2 border-background-dark p-2" value={subscription.notes} onChange={(e) => onChange(e.target.value)}/>
    </div>
  )
}
