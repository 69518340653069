import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const startValue = (initialValue, options) => {
  let value = {};
  for (let option of options) {
    if (initialValue.includes(option.value)) {
      value[option.value] = option;
    }
  }
  return value;
}

export default function MultipleSelect({onChange, options, initialValue, readOnly}) {
  const [selected, setSelected] = useState({}); 
  const [loaded, setLoaded] = useState();
  const unselected = options.filter(option => !selected[option.value]);
  useEffect(() => {
    if (initialValue && initialValue.length > 0 && options.length > 0 && !loaded) {
      setLoaded(true);
      setSelected(startValue(initialValue, options));
    }
  }, [initialValue, options]);
  
  const select = (option) => {
    if (readOnly) return;
    const newSelected = {...selected, [option.value]: option};
    setSelected(newSelected);
    onChange(Object.keys(newSelected));
  }
  const remove = (option) => {
    if (readOnly) return;
    const newSelected = {...selected};
    delete newSelected[option.value];
    setSelected(newSelected);
    onChange(Object.keys(newSelected));
  }

  return (
    <div className="flex flex-col gap-1">
      <div>
        <p className="font-bold text-sm text-med-grey">Selected</p>
        <hr></hr>
      </div>
      <div className="flex flex-wrap gap-2">
        {Object.values(selected).map(option => 
          <div 
            className="bg-primary-two rounded py-1 px-2 text-white cursor-pointer"
            id={option.value}
            onClick={() => remove(option)}
          >
            {option.name}
          </div>  
        )}
        {Object.values(selected).length < 1 && <div className="rounded py-1 px-2 text-sm text-med-grey">-</div>}
      </div>
      {
        readOnly ||
        <>
          <div>
            <p className="font-bold text-sm text-med-grey">Options</p>
            <hr></hr>
          </div>
          <div className="flex flex-wrap gap-2">
            {unselected.map(option => 
              <div 
                key={option.value}
                className="bg-primary rounded py-1 px-2 text-white cursor-pointer"
                id={option.value}
                onClick={() => select(option)}
              >
                {option.name}
              </div>  
            )}
            {unselected.length < 1 && <div className="rounded py-1 px-2 text-sm text-med-grey">-</div>}
          </div>
        </>
      }
    </div>
  )
}
