import { faArrowRight, faCaretDown, faCaretUp, faFilter, faMinus, faPause, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react'
import { useMemo } from 'react';
import ItemListFilters from './ItemListFilters';
import { useMutation, gql } from "@apollo/client";
import Loading from 'components/common/Loading';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MiniButton from 'components/common/Button/MiniButton';
import Quantity from './Quantity';
import OrganizationSelect from 'components/common/OrganizationSelect';

export default function ItemsTable({ inventoryItems, setFilters, filters }) {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <div className="p-4 flex flex-col gap-8">
        <div>
          <div className="">
            <div className="flex gap-2 items-center">
              <h2>Inventory</h2>
              <div className="flex items-end h-full">
                <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
                <FontAwesomeIcon className="text-xs" icon={showFilters ? faCaretUp : faCaretDown} />
              </div>
              <OrganizationSelect defaultOrg={"ColdAcre"}/>
            </div>
            <hr></hr>
            {
              showFilters && <ItemListFilters filters={filters} setFilters={setFilters} />
            }
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Updated</th>
                  <th>Tags</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {inventoryItems && inventoryItems.length > 0 ?
                  inventoryItems.map((item, index) => (
                    <tr key={index}>
                      <td><Link className={`${item.notify && item.notify > item.quantity.quantity ? 'text-red' : ''}`} to={`/admin/inventory/${item.id}`}>{item.name}</Link></td>
                      <td>{item.quantity.createdAt ? new Date(Number(item.quantity.createdAt)).toLocaleDateString() : '-'}</td>
                      <td>
                        <div className="flex flex-col gap-1 items-start max-h-8 overflow-y-scroll relative">
                          <p className="text-primary-two absolute font-bold top-0 right-2">{item.tags.length}</p>
                          {item.tags.map(tag => <div key={tag.id} className="bg-primary-two rounded py-1 px-2 text-white cursor-pointer">{tag.name}</div>)}
                        </div>
                      </td>
                      <td>
                        <Quantity item={item}/>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
