import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useNavigate } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';

const ADD_ORGANIZATION = gql`
  mutation AddOrganization($name: String!, $email: String!, $subdomain: String!, $addressOne: String!, $longitude: Float!, $latitude: Float!, $addressTwo: String, $phoneNumber: String!, $locations: [String]) {
    addOrganization(name: $name, email: $email, subdomain: $subdomain, addressOne: $addressOne, longitude: $longitude, latitude: $latitude, addressTwo: $addressTwo, phoneNumber: $phoneNumber, locations: $locations) {
      id,
    }
  }
`;

export default function Profile() {
  const {currentUser} = useContext(AppContext);
  const [organization, setOrganization] = useState({});
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const addressInputRef = useRef();
  const [addOrganizationMutation, { loading:updateLoading, reset }] = useMutation(ADD_ORGANIZATION);
  const {setActiveSubpage} = useContext(ActivePageContext);
  setActiveSubpage("new");
  
  const measuredRef = useCallback(node => {
    if (node !== null && !edit) {
      let autocomplete = locationAutocomplete(node);
      autocomplete.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setOrganization(current => ({...current, addressOne: place.formatted_address, latitude, longitude}));
      });
      addressInputRef.current = node;
    }
  }, []);

  const valid = () => {
    return organization.latitude && organization.longitude && organization.name && organization.phoneNumber && organization.email && organization.subdomain;
  }

  const addOrganization = () => {
    addOrganizationMutation({variables: {...organization}})
      .then(res => {
        navigate(`/admin/super/organizations/${res.data.addOrganization.id}`)
      })
      .catch(() => {
        reset();
      })
  }

  return (
    <PaddedPage className="w-full">
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            New Organization
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          <label>Name</label>
          <Input valueOverride={organization.name} onChange={(name) => setOrganization({...organization, name})}/>
        </div>
        <div className="flex flex-col">
          <label>Phone Number</label>
          <Input valueOverride={organization.phoneNumber} onChange={(phoneNumber) => setOrganization({...organization, phoneNumber})}/>
        </div>
        <div className="flex flex-col">
          <label>Email</label>
          <Input valueOverride={organization.email} onChange={(email) => setOrganization({...organization, email})}/>
        </div>
        <div className="flex flex-col">
          <label>Subdomain</label>
          <Input valueOverride={organization.subdomain} onChange={(subdomain) => setOrganization({...organization, subdomain})}/>
        </div>
        <div className="flex flex-col">
          <label>Address</label>
          <Input forwardRef={measuredRef} valueOverride={organization.addressOne} onChange={(addressOne) => setOrganization({...organization, addressOne})}/>
        </div>
        <div className="flex flex-col">
          <label>Apt, suite, etc.</label>
          <Input valueOverride={organization.addressTwo} onChange={(addressTwo) => setOrganization({...organization, addressTwo})}/>
        </div>
        {/* <div className="flex flex-col">
          <label>Locations</label>
          <Input valueOverride={organization.locations} onChange={(locations) => setOrganization({...organization, locations})}/>
        </div> */}
        <ButtonPrimary className="self-start mt-2" disabled={!valid() || updateLoading} onClick={addOrganization}>{updateLoading ? <Loading/> : "Add Organization"}</ButtonPrimary>
      </div>
    </PaddedPage>
  )
}
