import React, {useContext, useMemo} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import User from 'components/users/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus, faFilter, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { List } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import UsersFilters from './UsersFilters';
import { useState } from 'react';
import ExportCSC from 'components/common/ExportCSC';
import OrganizationSelect from 'components/common/OrganizationSelect';
import MiniButton from 'components/common/Button/MiniButton';

const GET_USERS = gql`
  query GetUsers($organizationId: String, $filters: JSONObject){
    users(organizationId: $organizationId, filters: $filters) {
      id,
      createdAt,
      stripeCustomerId,
      firstName,
      lastName,
      phoneNumber,
      addressOne, 
      addressTwo,
      email,
      type,
      hasSubscribed,
      activeSubscription,
      organization,
      unsubscribeNotifications,
      unsubscribePromotions
    }
  }
`;

export default function Users() {
  const [filters, setFilters] = useState({});
  const [clientFilters, setClientFilters] = useState({subscribed: "all"});
  const [showFilters, setShowFilters] = useState(true);
  const {setActivePage, organizationId} = useContext(ActivePageContext);
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {filters: filters, organizationId}
  });
  const filteredUsers = useMemo(() => {
    return (data?.users || []).filter(user => {
      if (clientFilters.subscribed === true){
        return user.activeSubscription
      } else if (clientFilters.subscribed === false) {
        return !user.activeSubscription
      }
      return true;
    })
  }, [data?.users, clientFilters]);
  
  setActivePage(null);
  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end justify-between">
        <div className="flex gap-2 items-end">
          <h2>Users</h2>
          <div className="flex items-end h-full">
            <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
            <FontAwesomeIcon className="text-xs" icon={showFilters ? faCaretUp : faCaretDown} />
          </div>
          <OrganizationSelect/>
        </div>
        <div className="flex gap-2">
          <Link to="/admin/super/detailed-users"><MiniButton>Advanced</MiniButton></Link>
          {filteredUsers.length > 0 && <ExportCSC data={filteredUsers.map(user => ({...user, referralCode: user.stripeCustomerId && user.stripeCustomerId.replace('cus_', ''), createdAt: user.createdAt && new Date(Number(user.createdAt))}))} filename="winterharvestUsers"/>}
        </div>
      </div>
      {
        showFilters && <UsersFilters filters={filters} setFilters={setFilters} clientFilters={clientFilters} setClientFilters={setClientFilters}/>
      }
      <div>
        {filteredUsers.map(user => <User key={user.id} user={user}/>)}
        {filteredUsers.length === 0 && <small className="text-med-grey">No Users</small> }
      </div>
    </div>
  )
}
