import React from 'react'
import { Link } from 'react-router-dom';

export default function AnonNav() {
  return (
    <div className="flex gap-4 items-center">
      {/* <a href={`${process.env.REACT_APP_WINTERHARVEST_CLIENT}`}>WinterHarvest</a> */}
      {/* <a href={`${process.env.REACT_APP_CLIENT}`} className="xs:hidden">Prefab units</a> */}
      <Link to="/login">Login</Link>
      <Link to="/sign-up">Sign Up</Link>
   </div>
  )
}
