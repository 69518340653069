import { PaddedPage } from 'components/common/Layout'
import React from 'react'
import { Link } from 'react-router-dom'

export default function SignupSuccess() {
  return (
    <PaddedPage>
      <h1>Success!</h1>
      <br></br>
      <h2>Check your email to confirm your account and then login <Link to="/login">Here</Link> </h2>
    </PaddedPage>
  )
}
