import React from 'react';
import { Route } from 'react-router-dom';
import LandingPage from './LandingPage';

const routes = [
  <Route key="landing" path="/prefab-units" element={<LandingPage/>} />,
  // <Route key="index" path="/" exact element={<LandingPage/>} />
];

export default routes;
