import React, {useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import User from 'components/users/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { List } from 'components/inputs';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';
import ItemsTable from './ItemsTable';
import Loading from 'components/common/Loading';

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions($fresh: Boolean){
    subscriptions(fresh: $fresh) {
      id,
      metadata,
      cancel_at_period_end,
      deliveryZone {
        name
      },
      items {
        price{
          id,
          metadata,
          nickname,
          recurring{
            interval,
            interval_count
          },
          product{
            id,
            description,
            image,
            name,
            metadata,
            inventoryItemId,
          },
          unit_amount
        }
        metadata,
        quantity
      },
      user {
        id,
        firstName,
        lastName,
        email,
        addressOne,
        addressTwo,
        phoneNumber
      },
      start_date,
      current_period_end,
      current_period_start,
      oneTimeProducts {
        id,
        date,
        status,
        quantity,
        price{
          id,
          metadata,
          nickname,
          recurring{
            interval,
            interval_count
          },
          product{
            id,
            description,
            image,
            name,
            metadata,
            inventoryItemId,
          },
          unit_amount
        }
      }
      schedule {
        id
        current_phase {start_date, end_date},
        metadata,
        phases{
          end_date,
          start_date,
          trial_end,
          metadata,
          items{
            quantity,
            price{
              id,
              metadata,
              nickname,
              recurring{
                interval,
                interval_count
              },
              product{
                id,
                description,
                image,
                name,
                metadata,
                inventoryItemId,
              },
              unit_amount
            }
          }
        }
        status
      }
    }
  }
`;

export default function Dispatch() {
  const {currentUser} = useContext(AppContext);
  const [fresh, setFresh] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy:'no-cache',
    variables: {fresh},
    onError: (err) => {
      if(err?.message?.includes("Service Unavailable")) {
        console.log("refetching...");
        refetch();
      }
    }
  });
  const {setActivePage} = useContext(ActivePageContext);
  setActivePage('dispatch');
  
  const subscriptions = (data && data.subscriptions) || [];
  return (
    <div className="p-4 flex flex-col gap-2">
      {
        (data && data.subscriptions) ?
          <ItemsTable subscriptions={subscriptions} reset={() => setFresh(!fresh)}/>
        :
          <div className="flex items-center h-80 justify-center">
            <Loading/>
          </div>
      }
    </div>
  )
}
