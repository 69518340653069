import Plant from './Plant';
import Plants from './Plants';
import New from './New';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = [
  <Route key="plants-new" path="new" element={<New/>} />,
  <Route key="plant" path=":id" element={<Plant/>} />,
  <Route key="plants" path="" element={<Plants/>} />,
];

export default routes;
