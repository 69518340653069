import React, { useContext, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faInfinity, faPause, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getWeekItems } from 'selectors/subscriptionSelectors';
import { iterationsFrom } from 'helpers/calendar';
import ButtonPrimary from 'components/common/Button';
import MiniButton from 'components/common/Button/MiniButton';
import { useState } from 'react';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';

const UNPAUSE = gql`
  mutation unpauseSubscription($startTime: String) {
    unpauseSubscription(startTime: $startTime) {
      message
    }
  }
`;

export default function SubscriptionOverview({sub, lastUpdate}) {
  const [showUnpause, setShowUnpause] = useState(false);
  const {impersonating} = useContext(AppContext);
  const [unpauseMutation, { loading:unpauseLoading }] = useMutation(UNPAUSE);
  const weekItems = useMemo(() => {
    return getWeekItems(sub);
  }, [sub]);

  const canUnpause = sub ? new Date(sub.start_date*1000) > new Date() : false;

  const singleWeek = useMemo(() => {
    return !lastUpdate && sub && (iterationsFrom(sub.start_date*1000, (sub.end_date || sub.current_period_end)*1000) < 1);
  }, [sub, lastUpdate]);

  const cancelPause = () => {
    if (!canUnpause) return;
    unpauseMutation({ variables: {startTime: sub.start_date} })
    .then(res => {
      window.location.reload();
      setShowUnpause();
    })
    .catch(err => {
      setShowUnpause();
      console.log("err", err);
    })
  }

  return (
    <div>
      {showUnpause && <ConfirmNotice 
        title={`Are you sure?`}
        message={`Your pause will be cancelled.`}
        onConfirm={cancelPause}
        onClose={() => setShowUnpause(false)}
      /> }
      {sub && sub.start_date && <div className="flex items-center justify-between text-xs">
          <div className="flex gap-1 items-center">
            <strong>From</strong>
            <div className="bg-accent-two rounded p-1 font-bold">{new Date(sub.start_date*1000).toDateString()}</div>
            <FontAwesomeIcon icon={faArrowRight}/>
            {(!lastUpdate || String(sub.metadata.paused) === "true") ? 
              <div className="bg-accent-two rounded p-1 font-bold">{new Date((sub.end_date || sub.current_period_end)*1000).toDateString()}</div>
              :
              <div className="bg-accent-two rounded p-1 font-bold"><FontAwesomeIcon icon={faInfinity}/></div>
            }
            <div className="font-bold">
              In {sub.metadata.location}
            </div>
          </div>
          {canUnpause && sub && sub.metadata && sub.metadata.paused == "true" && impersonating && <MiniButton onClick={() => setShowUnpause(true)} className="text-red border-red my-2"><FontAwesomeIcon icon={faTimes} /> Cancel Pause</MiniButton>}
      </div>}
      {sub && sub.items ?
        <div className="flex gap-4 items-stretch sm:flex-col">
          <Week title="Week 1" dayDetails={weekItems[0]} sub={sub}/>
          {!singleWeek ? <Week title="Week 2" dayDetails={weekItems[1]} sub={sub}/> : <div className="w-1/2 sm:w-0"/>}
        </div>
        :
        <div className="border-2 rounded p-8 border-med-grey bg-light-grey w-full flex justify-center items-center">
          <FontAwesomeIcon className="font-xl text-med-grey" icon={faTimes} />
        </div>
      }
    </div>
  )
}

const dayList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const Week = ({dayDetails, title, sub}) => {
  return (
    <div className="grow flex flex-col">
      <h3>{title}</h3>
      <div className="flex h-full grow">
        {dayList.map(day => (
          <div key={day} className="flex flex-col grow items-stretch">
            <strong className="text-center">{day.charAt(0)}</strong>
            <div className={`
              flex flex-col border p-2 grow relative
              ${dayDetails[day] && 'bg-light-grey border-med-grey'}
            `}>
              {dayDetails[day] &&<p className="text-center border-b border-med-grey mb-2">🌿</p>}
              {
                dayDetails[day] && dayDetails[day].map((item, index) => (
                  <div key={`${item.id}${index}`}>
                    <p className="font-bold text-sm">{item.quantity} {item.name}</p>
                  </div>
                ))
              }
              {
                dayDetails[day] && sub.metadata.paused == "true" &&
                <div className="w-full h-full absolute bg-yellow left-0 top-0 opacity-75 flex justify-center items-center">
                  <FontAwesomeIcon icon={faPause}/>
                  <p> Paused</p>
                </div>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
