import React, {useContext, useState} from 'react';
import Product from './Product';
import { useQuery, gql, useMutation } from "@apollo/client";
import SuccessNotice from 'components/notices/SuccessNotice';
import ActivePageContext from 'contexts/ActivePageContext';

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
      metadata,
      unit_label,
      prices {
        id,
        active,
        nickname,
        recurring {
          aggregate_usage,
          interval,
          interval_count,
          usage_type,
        },
        metadata,
        type,
        unit_amount
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productId: String!, $price: Float) {
    updateProduct(productId: $productId, price: $price) {
      id,
      description,
      image,
      name,
      metadata,
      unit_label,
      prices {
        id,
        nickname,
        recurring {
          aggregate_usage,
          interval,
          interval_count,
          usage_type,
        },
        metadata,
        type,
        unit_amount
      }
    }
  }
`;

const Products = () => {
  const [show, setShow] = useState(true);
  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS, {
    variables: { query: "" },
  });
  const [updateProductMutation, { loading:updateLoading }] = useMutation(UPDATE_PRODUCT);
  const [updateSuccess, setUpdateSuccess] = useState();
  const {setActivePage} = useContext(ActivePageContext);
  const updateProduct = (change) => {
    return updateProductMutation({ variables: {...change, price: change.price ? Number(change.price) : undefined} })
    .then(({data}) => {
      refetch();
      setUpdateSuccess(true);
    })
  }
  setActivePage("products");

  const priceDefault = (product) => {
    return product.prices.find(price => price.active && price.nickname === 'One-Time');
  }

  return (
    <div className="flex flex-col gap-8 p-4">
      {
        updateSuccess &&
        <SuccessNotice
          title="Product Updated"
          message="Product has been updated"
          submessage="Changes to existing subs will take place over the next billing cycle."
          onClose={() => setUpdateSuccess(false)}
          onConfirm={() => setUpdateSuccess(false)}
        />
      }
      <h2>Products</h2>
      <div className={`flex flex-col gap-4 ${!show && 'hidden'}`}>
        {
          data && data.products.map((product, i) => (
            <Product 
              key={i} 
              updateProduct={updateProduct} 
              priceDefault={priceDefault(product)} 
              product={product}
              loading={updateLoading}
            />
          ))
        }
        
      </div>
    </div>
  );
};

export default Products;
