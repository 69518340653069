import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import AdminRoute from 'components/routes/AdminRoute';
import SuperAdminRoute from 'components/routes/SuperAdminRoute';
import UserRoutes from './user';
import ProductsRoutes from './products';
import OrderRoutes from './order';
import AdminNav from './AdminNav';
import OrganizationNav from './OrganizationNav';
import Users from './user/Users';
import DispatchRoutes from './dispatch';
import InventoryRoutes from './inventory';
import OrganizationRoutes from './organization';
import Inventory from './inventory/Inventory';
import InventoryNav from './inventory/InventoryNav';
import PlantRoutes from './plant';
import PlantManagementRoutes from './plantManagement';
import PlantNav from './PlantNav';
import PlantManagementNav from './PlantManagementNav';
import ColdacreRoute from 'components/routes/ColdacreRoute';


const routes = [
  <Route key="admin" path="/admin" element={<AdminRoute><AdminNav><Outlet/></AdminNav></AdminRoute>}>
    <Route key="coldacre" path="/admin/orders" element={<ColdacreRoute><Outlet/></ColdacreRoute>}>
      {[
        ...OrderRoutes,
      ]}
    </Route>,
    <Route key="coldacre" path="/admin/dispatch" element={<ColdacreRoute><Outlet/></ColdacreRoute>}>
      {[
        ...DispatchRoutes,
      ]}
    </Route>,
    <Route key="super-admin" path="/admin/super" element={<SuperAdminRoute><Outlet/></SuperAdminRoute>}>
      {[
      ...ProductsRoutes,
      ...UserRoutes,
      ]}
    </Route>,
    <Route key="super-admin" path="/admin/super" element={<SuperAdminRoute><OrganizationNav><Outlet/></OrganizationNav></SuperAdminRoute>}>
      {[
        ...OrganizationRoutes,
      ]}
    </Route>,
    <Route key="inventory" path="/admin/inventory" element={<InventoryNav><Outlet/></InventoryNav>}>
      {[
        ...InventoryRoutes,
      ]}
      <Route key="inventoryindex" path="/admin/inventory" element={<Inventory/>}/>
    </Route>,
    <Route key="super-admin" path="/admin/plants" element={<SuperAdminRoute><PlantNav><Outlet/></PlantNav></SuperAdminRoute>}>
      {[
        ...PlantRoutes,
      ]}
    </Route>,
    <Route key="super-admin" path="/admin/plant-management" element={<PlantManagementNav><Outlet/></PlantManagementNav>}>
      {[
        ...PlantManagementRoutes,
      ]}
    </Route>,
    <Route key="adminindex" path="/admin" element={<InventoryNav><Inventory/></InventoryNav>}/>
  </Route>,
];

export default routes;
