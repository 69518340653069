import { gql, useQuery } from '@apollo/client';
import React from 'react'

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
    }
  }
`;

export default function DetailedUsersFilters({filters, setFilters, clientFilters, setClientFilters}) {
  const { loadingProducts, errorProducts, data:productsRes } = useQuery(GET_PRODUCTS, {
    variables: { query: `-metadata['subtype']:'delivery' metadata['organizationId']:null` },
  });
  return (
    <div className="border flex p-2 gap-4 flex-wrap text-xs">
      <div> 
        <strong>Search</strong>
        <hr></hr>
        <div className="flex gap-2">
          <input className="border px-2" onChange={(e) => setFilters({...filters, search: e.target.value})} value={filters.search}/>
        </div>
      </div>
      <div> 
        <strong>Status</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" checked={filters.unsubscribePromotions == ""} defaultChecked={filters.unsubscribePromotions == ""} onClick={() => setFilters({...filters, unsubscribePromotions: ""})} type="radio" id="all" name="subscribe" value=""/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="all">All</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={filters.unsubscribePromotions === "false"} defaultChecked={filters.unsubscribePromotions === "false"} onClick={() => setFilters({...filters, unsubscribePromotions: "false"})} type="radio" id="subscribed1" name="subscribed1" value="subscribed"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="subscribed1">Subscribed</label> 
          </div>
          <div>
            <input className="cursor-pointer" checked={filters.unsubscribePromotions === "true"} defaultChecked={filters.unsubscribePromotions === "true"} onClick={() => setFilters({...filters, unsubscribePromotions: "true"})} type="radio" id="not-subscribed" name="subscribe" value="not-subscribed"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="not-subscribed">Not subscribed</label>
          </div>
        </div>
      </div>
      <div> 
        <strong>Location</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" checked={clientFilters.subscriptionLocation == ""} defaultChecked={clientFilters.subscriptionLocation == ""} onClick={() => setClientFilters({...clientFilters, subscriptionLocation: ""})} type="radio" id="locationall" name="locationall" value=""/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="locationall">All</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.subscriptionLocation === "whitehorse"} defaultChecked={clientFilters.subscriptionLocation === "whitehorse"} onClick={() => setClientFilters({...clientFilters, subscriptionLocation: "whitehorse"})} type="radio" id="locationwhitehorse" name="locationwhitehorse" value="whitehorse"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="locationwhitehorse">Whitehorse</label> 
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.subscriptionLocation === "dawson"} defaultChecked={clientFilters.subscriptionLocation === "dawson"} onClick={() => setClientFilters({...clientFilters, subscriptionLocation: "dawson"})} type="radio" id="locationdawson" name="locationdawson" value="dawson"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="locationdawson">Dawson</label>
          </div>
        </div>
      </div>
      <div> 
        <strong>Cancelled</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" checked={clientFilters.cancelled == ""} defaultChecked={clientFilters.cancelled == ""} onClick={() => setClientFilters({...clientFilters, cancelled: ""})} type="radio" id="cancel-all" name="cancel-all" value=""/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="cancel-all">All</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.cancelled === "cancelled"} defaultChecked={clientFilters.cancelled === "cancelled"} onClick={() => setClientFilters({...clientFilters, cancelled: "cancelled"})} type="radio" id="cancel-cancelled" name="cancel-cancelled" value="cancelled"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="cancel-cancelled">Cancelled</label> 
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.cancelled === "cancelledNoReason"} defaultChecked={clientFilters.cancelled === "cancelledNoReason"} onClick={() => setClientFilters({...clientFilters, cancelled: "cancelledNoReason"})} type="radio" id="cancel-cancelledNoReason" name="cancel-cancelledNoReason" value="cancelledNoReason"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="cancel-cancelledNoReason">Cancelled - no reason</label>
          </div>
        </div>
      </div>
      <div> 
        <strong>Date Range</strong>
        <hr></hr>
        <div className="flex gap-2">
          <input type="date" value={clientFilters.from} onChange={(e) => setClientFilters({...clientFilters, from: e.target.value})}></input>
          <input type="date" value={clientFilters.to} onChange={(e) => setClientFilters({...clientFilters, to: e.target.value})}></input>
        </div>
      </div>
      <div> 
        <strong>Range for</strong>
        <hr></hr>
        <div className="flex gap-2">
          <div>
            <input className="cursor-pointer" checked={clientFilters.rangeFor == ""} onClick={() => setClientFilters({...clientFilters, rangeFor: ""})} type="radio" id="credits" name="rangefor" value=""/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="credits">Credits</label>
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.rangeFor === "cancelled"} onClick={() => setClientFilters({...clientFilters, rangeFor: "cancelled"})} type="radio" id="cancelled" name="rangefor" value="cancelled"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="cancelled">Cancelled at</label> 
          </div>
          <div>
            <input className="cursor-pointer" checked={clientFilters.rangeFor === "subscribed"} onClick={() => setClientFilters({...clientFilters, rangeFor: "subscribed"})} type="radio" id="subscribed" name="rangefor" value="subscribed"/>
            <label className="font-normal text-xs cursor-pointer" htmlFor="subscribed">Subscribed at</label>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <string>Product</string>
        <hr></hr>
          <select 
            name="product" 
            id="product" 
            value={clientFilters.stripeProductId}
            onChange={e => setClientFilters({...clientFilters, stripeProductId: e.target.value})}
          >
            <option value="">none</option>
            {
              productsRes &&  productsRes.products.map(product => 
                <option 
                  key={product.id} 
                  value={product.id}
                >
                  {product.name}
                </option>
              )
            }
          </select>
        </div>
    </div>
  )
}
