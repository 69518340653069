import PlantManagement from './PlantManagement';
import PlantManagements from './PlantManagements';
import New from './New';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = [
  <Route key="plant-managements-new" path="new" element={<New/>} />,
  <Route key="plant-management" path=":id" element={<PlantManagement/>} />,
  <Route key="plant-managements" path="" element={<PlantManagements/>} />,
];

export default routes;
