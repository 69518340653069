import { faArrowRight, faArrowsRotate, faCaretDown, faCaretUp, faFilter, faPause, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangeSelector from 'components/calendar/DateRangeSelector';
import MiniButton from 'components/common/Button/MiniButton';
import { getFirstDayOfWeek, iterationsFrom } from 'helpers/calendar';
import React, { useCallback, useState } from 'react'
import { useMemo } from 'react';
import { getTotalWeekItems, oneTimeProductsForWeek, weekSubscriptionToProductList } from 'selectors/subscriptionSelectors';
import ItemListFilters from './ItemListFilters';
import OrderSheets from './OrderSheets';
import { useMutation, gql, useQuery } from "@apollo/client";
import Loading from 'components/common/Loading';
import { useEffect } from 'react';
import { datePlusOffset, sameDay } from 'helpers/time';

const CREATE_ORDERS = gql`
  mutation CreateOrders($location: String, $users: [String], $date: String) {
    createOrders(location: $location, users: $users, date: $date) {
      status,
      routes {
        driverExternalId,
        driverName,
        duration,
        distance,
        stops {
          stopNumber,
          orderNo,
          locationNo,
          locationName,
          address,
          latitude,
          longitude,
        },
      }
    }
  }
`;

const GET_INVENTORY_ITEM_QUANTITIES = gql`
  query GetInventoryItemQuantities($filters: JSONObject){
    inventoryItemQuantities(filters: $filters) {
      id,
      quantity,
      packed,
      createdAt,
      inventoryItemId
    }
  }
`;

const UPDATE_QUANTITY = gql`
  mutation UpdateQuantity($inventoryItemId: String!, $change: Float!, $packed: String) {
    updateInventoryQuantity(inventoryItemId: $inventoryItemId, change: $change, packed: $packed) {
      id
    }
  }
`;

export default function ItemsTable({ subscriptions, reset }) {
  const [showFilters, setShowFilters] = useState(true);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [routePending, setRoutePending] = useState(false);
  const [filters, setFilters] = useState({ day: "", distribution: "", location: "whitehorse" });
  const [dateRange, setDateRange] = useState({ start: getFirstDayOfWeek(new Date()), iterations: 1 });
  const [createOrdersMutation, { loading: routesLoading }] = useMutation(CREATE_ORDERS);
  const [packedItems, setPackedItems] = useState([]);
  const [updateQuantityMutation, { loading:quantityLoading }] = useMutation(UPDATE_QUANTITY);
  

  const endDate = useMemo(() => {
    return new Date(dateRange.start.getTime() + dateRange.iterations * (1 * 7 * 24 * 60 * 60 * 1000))
  }, [dateRange]);

  let routeInfo = useMemo(() => {
    let routeLookup = {};
    let drivers = [];
    for (let route of routes) {
      for (let stop of route.stops) {
        routeLookup[stop.locationName] = { number: Number(stop.stopNumber), driver: route.driverName };
        if (!drivers.includes(route.driverName)) {
          drivers.push(route.driverName);
        }
      }
    }
    return { routeLookup, drivers };
  }, [routes]);
  const { routeLookup, drivers } = routeInfo;

  const getOrderSheets = useCallback((subscriptions, dateRange, filters, routeLookup) => {
    let productsTotal = {}
    let orderSheets = {};
    for (let sub of subscriptions) {
      weekSubscriptionToProductList(sub, dateRange.start, dateRange.iterations, filters, productsTotal, orderSheets, routeLookup);
    }
    return [productsTotal, orderSheets];
  }, []);

  const [products, orderSheets] = useMemo(() => {
    return getOrderSheets(subscriptions, dateRange, filters, routeLookup);
  }, [subscriptions, dateRange, filters, routeLookup, getOrderSheets]);

  let routedOrderSheets = useMemo(() => {
    if (Object.entries(routeLookup) === 0) return orderSheets
    let routedSheets = Object.entries(orderSheets).map(([id, sheet]) => {
      let stopInfo = routeLookup[sheet.user.email];
      return { ...sheet, driver: stopInfo && stopInfo.driver, stopNumber: stopInfo && stopInfo.number }
    });
    return routedSheets.sort((a, b) => {
      let compare = (a.driver || "zz").localeCompare(b.driver || "zz");
      if (!compare) {
        compare = (a.stopNumber || 0) - (b.stopNumber || 0);
      }
      if (!compare) {
        compare = (a.deliveryZone || "zz").localeCompare(b.deliveryZone || "zz");
      }
      if (!compare) {
        compare = (a.user.firstName || "zz").localeCompare(b.user.firstName || "zz");
      }
      return compare;
    });
  }, [orderSheets, routeLookup]);

  const withinCurrentWeek = useMemo(() => {
    if (sameDay(getFirstDayOfWeek(new Date()), getFirstDayOfWeek(dateRange.start))) {
      console.log("same week", getFirstDayOfWeek(new Date()));
      return true;
    }
  }, [dateRange]);

  const deliveryDay = useMemo(() => {
    return datePlusOffset(dateRange.start, (filters.day === "thursday" ? 4 : 3))
  }, [dateRange.start, filters.day])

  const viewingOneDay = useMemo(() => {
    return subscriptions.length > 0 && filters.day && dateRange.iterations === 1 && withinCurrentWeek;
  }, [filters.day, dateRange, subscriptions, withinCurrentWeek]);

  const getOptimoroute = useCallback((subscriptions, day) => {
    day = day || filters.day || "";
    if (["thursday", "wednesday"].includes(day) && dateRange.iterations === 1) {
      let currentDay = dateRange.start.getDay();
      let distance = (day.toLowerCase() === "thursday" ? 4 : 3) - currentDay;
      let deliveryDay = new Date(dateRange.start);
      deliveryDay.setDate(deliveryDay.getDate() + distance);
      // Adjust for local timezone
      const offset = deliveryDay.getTimezoneOffset();
      deliveryDay = new Date(deliveryDay.getTime() - (offset * 60 * 1000));
      // todo add filter so that only delivery orders are sent
      let [products, orderSheets] = getOrderSheets(subscriptions, { iterations: 1, start: dateRange.start }, { day, location: filters.location, distribution: "delivery" }, {});
      let users = Object.entries(orderSheets).filter(([key, order]) => order.orders[0].metadata.paused !== 'true' && order.orders[0].items.find(item => item.price.product.name === "Delivery")).map(([key, order]) => order.user.id);
      if (users.length > 1) {
        createOrdersMutation({ variables: { location: filters.location, users: users, date: deliveryDay } })
          .then(({ data }) => {
            if (data.createOrders.status === "F") {
              setRoutePending(false);
              setRoutes(data.createOrders.routes);
            } else {
              setRoutePending(true);
              setTimeout(getOptimoroute, 2000);
            }
          })
      } else {
        setRoutes([]);
      }
    } else {
      setRoutes([]);
    }
    // Need to leave out orderSheets dep, bc this can change for driver filter
  }, [createOrdersMutation, dateRange.iterations, dateRange.start, filters.day, filters.location, getOrderSheets]);

  const { data:packedData } = useQuery(GET_INVENTORY_ITEM_QUANTITIES, {
    fetchPolicy:'no-cache',
    variables: {filters: 
      {
        packed: deliveryDay,
        inventoryItems: Object.values(products).map(product => product.inventoryItemId).filter(id => id)
      }
    },
    skip: !viewingOneDay,
    onCompleted: (data) => {
      setPackedItems(data.inventoryItemQuantities.map(quantity => quantity.inventoryItemId))
    }
  });

  useEffect(() => {
    if (subscriptions.length > 0 && filters.day && dateRange.iterations === 1 && withinCurrentWeek) {
      getOptimoroute(subscriptions, filters.day);
    } else {
      setRoutes([]);
    }
  }, [filters.day, dateRange, subscriptions, withinCurrentWeek, getOptimoroute]);

  const updateQuantity = (id, change, checked) => {
    updateQuantityMutation({
      variables: {inventoryItemId: id, change, packed: deliveryDay}
    })
    .then(res => {
      let newPackedItems = [...packedItems];
      if (!checked) {
        newPackedItems = [...newPackedItems, id]
      } else {
        console.log("removing check", newPackedItems, id);
        newPackedItems = newPackedItems.filter(currentId => currentId !== id);
      }
      setPackedItems(newPackedItems);
    })
  }

  return (
    <>
      {
        showSelectDate && <DateRangeSelector onClose={() => setShowSelectDate(false)} setRange={setDateRange} />
      }
      <div className="p-4 print">
        <div className="print-page-break mb-8">
          <div className="">
            <div className="flex gap-2 items-center justify-between">
              <div className="flex gap-2 items-center">
                <h2>Products</h2>
                <div className="flex items-end h-full">
                  <FontAwesomeIcon className="cursor-pointer" icon={faFilter} onClick={() => setShowFilters(!showFilters)} />
                  <FontAwesomeIcon className="text-xs" icon={showFilters ? faCaretUp : faCaretDown} />
                </div>
                <div className="text-center cursor-pointer" onClick={() => setShowSelectDate(true)}>
                  <div className="bg-accent-two inline-block mx-1 rounded p-1 text-sm font-bold">{dateRange.start.toDateString()}</div>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <div className="bg-accent-one inline-block mx-1 rounded p-1 text-sm font-bold">{endDate.toDateString()}</div>
                </div>
              </div>
              <MiniButton onClick={reset}><FontAwesomeIcon icon={faArrowsRotate} /> Refresh</MiniButton>
            </div>
            <hr></hr>
            {
              showFilters && <ItemListFilters loading={routesLoading} filters={filters} drivers={drivers} setFilters={setFilters} />
            }
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Supplier</th>
                  <th>Quantity</th>
                  <th>Paused</th>
                  {viewingOneDay && <th>Packed</th>}
                </tr>
              </thead>
              <tbody>
                {products && Object.entries(products).length > 0 ?
                  Object.values(products).sort((a, b) => {
                    let order = Number(a.order) - Number(b.order);
                    if (!order) {
                      return (a.supplier || '').localeCompare((b.supplier || ""))
                    }
                    return order;
                  }).map((product, i) => (
                    <tr key={i}>
                      <td>{product.name}</td>
                      <td>{product.supplier}</td>
                      <td>{product.quantity}</td>
                      <td>{product.paused && <span className="text-yellow border border-yellow p-1">{product.paused} <FontAwesomeIcon icon={faPause} /></span>}</td>
                      {viewingOneDay && 
                        <td>{product.inventoryItemId  && 
                          (quantityLoading ?
                          <Loading/>
                          :
                          <input 
                            type="checkbox" 
                            checked={packedItems.includes(product.inventoryItemId)}
                            onClick={() => updateQuantity(product.inventoryItemId, -1 * product.quantity, packedItems.includes(product.inventoryItemId))} 
                            className="cursor-pointer scale-150"
                          />)}
                        </td>
                      }
                    </tr>
                  ))
                  :
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <div className="">
            <div className="flex gap-2 items-center justify-between">
              <h2>Order Sheets</h2>
              {/* {routes.length < 1 && dateRange.iterations === 1 && filters.day && !withinCurrentWeek && <MiniButton onClick={() => getOptimoroute(subscriptions)} disabled={routesLoading || routePending}>{(routesLoading || routePending) ? <Loading /> : "Get Optimoroute"}</MiniButton>} */}
            </div>
            <hr></hr>
          </div>
          <div className="flex flex-col gap-2 print-no-flex">
            {routedOrderSheets.length > 0 ?
              <>
                {
                  routedOrderSheets.map(sheet => (
                    <OrderSheets key={sheet.user.email} sheet={sheet} routeLoading={routesLoading || routePending} />
                  ))
                }
              </>
              :
              <div className="border-2 rounded p-8 border-med-grey bg-light-grey w-full flex justify-center items-center">
                <FontAwesomeIcon className="font-xl text-med-grey" icon={faTimes} />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
