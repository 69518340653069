import Users from './Users';
import User from './User';
import React from 'react';
import { Route } from 'react-router-dom';
import DetailedUsers from './DetailedUsers';

const routes = [
  <Route key="user" path="users/:id" element={<User/>} />,
  <Route key="users" path="users" element={<Users/>} />,
  <Route key="detailed-users" path="detailed-users" element={<DetailedUsers/>} />,

];

export default routes;
