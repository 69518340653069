import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import subItems from 'assets/images/landing/sub-items.png';
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';

export default function WinterHarvestJumbo() {
  return (
    <PaddedPage className="flex pt-8 sm:flex-col items-stretch justify-around relative sm:mb-60 sm:pt-20" noTop noBottom>
        <div className="w-1/2 md:-mt-16 md:h-screen sm:w-full gap-8 flex flex-col items-center justify-center sm:z-10">
          <h1 className="jumbo">Yukon’s <span className="gradient">Local</span> Food Subscription</h1>
          <Link to="/subscription">
            <ButtonPrimary className="bg-white">See Subscriptions</ButtonPrimary>
          </Link>
        </div>
        <img src={subItems} className="w-1/2 xl:w-1/3 -mb-20 sm:absolute sm:top-0 sm:w-2/3 sm:right-0 sm:opacity-25" alt="winterharvest subscription items" />
    </PaddedPage>
  )
}
