import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";

const GET_ORGANIZATION = gql`
  query GetOrganization($subdomain: String) {
    organization(subdomain: $subdomain) {
      id,
      name,
      email,
      subdomain,
      addressOne,
      phoneNumber,
      locations
    }
  }
`;

const getSubdomain = () => {
  let host = window.location.host;
  let parts = host.split(".");
  if (parts.length >= 3) {
    return parts[0];
  }
}

export default function useOrganization() {
  const [subdomain, setSubdomain] = useState(getSubdomain());
  const { loading, error, data } = useQuery(GET_ORGANIZATION, {
    variables: { subdomain },
    skip: (subdomain && subdomain !== "winterharvest") ? false : true,
  });

  return {
    subdomain,
    organization: data ? data.organization : null
  }
}
