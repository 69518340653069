import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/farm.jpg';
import largecontainer from 'assets/images/prefab/12/12trans.png';
import largesolar from 'assets/images/prefab/12/12solartrans.png';
import largegreenhouse from 'assets/images/prefab/12/12GHtrans.png';
import { Link } from 'react-router-dom';

const CustomOptions = () => {
  const {setPage} = useContext(AppContext);
  const canvasRef = useRef();
  const parentRef = useRef();

  useEffect(() => setPage("Prefab Units"), [setPage]);
  
  const drawCanvas = () => {
    let ctx = canvasRef.current.getContext('2d');
    let width = canvasRef.current.width;
    let height = width;
    ctx.beginPath();     // Start a new path.
    ctx.lineWidth = "2";
    ctx.strokeStyle = "white";  // This path is green.
    ctx.arc(width/2, height/2, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width/2, height/2);
    ctx.lineTo(width * 0.6, height * 0.8);
    ctx.lineTo(width * 0.62, height * 0.8);
    ctx.font = "20px Arial";
    ctx.fillStyle = "white";
    ctx.fillText("Hello World", width * 0.62, height * 0.8);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(width * 0.4, height * 0.5, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width * 0.4, height * 0.5);
    ctx.lineTo(width * 0.2, height * 0.2);
    ctx.lineTo(width * 0.18, height * 0.2);
    ctx.textAlign = "end";
    ctx.fillText("Hello World", width * 0.181, height * 0.2);
    ctx.stroke();  
    ctx.closePath(); // Close the current path.
  }

  const windowResize = useCallback(() => {
      if (canvasRef.current) {
          canvasRef.current.width  = parentRef.current.clientWidth;
          canvasRef.current.height = parentRef.current.clientWidth;
          console.log("what", canvasRef.current);
          drawCanvas();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
  }, [windowResize]);

  return (
    <div className="bg-cover pt-12" style={{backgroundImage: `url(${farm})`}} id="customize">
        <PaddedPage className="flex gap-4 sm:flex-col" >
          <div className="w-1/3 flex flex-col sm:w-full">
              <h2>Many ways to</h2>
              <h1>Customize</h1>
              <div className="flex flex-col gap-2">
                <p>
                  Our container farms come in 3 sizes:
                </p>
                <div className="flex justify-around">
                  <h2 className="border-2 border-dark rounded p-2">6m</h2>
                  <h2 className="border-2 border-dark rounded p-2">12m</h2>
                  <h2 className="border-2 border-dark rounded p-2">16m</h2>
                </div>
                <p>
                  We also offer optional add-ons
                </p>
                <ul className="backdrop-brightness-50 text-white rounded-md py-2 flex flex-col gap-3">
                  <li className="leading-3">
                    Arctic Entrance<br></br>
                    <small className="font-light">Seperate entrace for better climate control</small>
                  </li>
                  <li className="leading-3">
                    Produce Cooler<br></br>
                    <small className="font-light">Built in cooler for storage inside arctic entrance</small>
                  </li>
                  <li className="leading-3">
                    Greenhouse<br></br>
                    <small className="font-light">Grow a wider variety of crops</small>
                  </li>
                  <li className="leading-3">
                    Solar Packages<br></br>
                    <small className="font-light">Solar options to power your unit</small>
                  </li>
                </ul>
              </div>
              <br></br>
              <Link to="/order">
                <ButtonPrimary className="mt-2 w-full bg-white text-dark" white >Order Now</ButtonPrimary>
              </Link>
          </div>
          <div className="w-2/3 flex flex-col justify-end sm:w-full" ref={parentRef}>
            <div className="w-full h-80 xs:hidden relative">
              <img src={largecontainer} className="absolute bottom-0" alt="large prefab container farm"/>
              <img src={largesolar} className="absolute bottom-0 rotateFarm" alt="large prefab container farm"/>
              <img src={largegreenhouse} className="absolute bottom-0 rotateFarm" alt="large prefab container farm"/>
            </div>
          </div>
        </PaddedPage>
    </div>
  );
};

export default CustomOptions;
