import React, {useContext} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import Plant from 'components/plants/Plant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { List } from 'components/inputs';
import ActivePageContext from 'contexts/ActivePageContext';

const GET_PLANTS = gql`
  query GetPlants{
    plants {
      id,
      date,
      name,
      description,
      image,
      growthPhases {
        name,
        duration,
        icon,
        dataName,
        title,
        instructions,
      }
    }
  }
`;

export default function Plants() {
  const { loading, error, data } = useQuery(GET_PLANTS, {fetchPolicy:'network-only'});
  const {setActiveSubpage} = useContext(ActivePageContext);
  setActiveSubpage(null);

  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex gap-2 items-end">
        <h2>Plants</h2>
      </div>
      <div className="flex flex-col gap-2">
        {data && data.plants.map(plant => <Plant key={plant.id} plant={plant}/>)}
        {data && data.plants.length === 0 && <small className="text-med-grey">No Plants</small> }
      </div>
    </div>
  )
}
