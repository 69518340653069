import { gql, useMutation } from '@apollo/client';
import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import MiniButton from 'components/common/Button/MiniButton';
import Loading from 'components/common/Loading';
import ConfirmNotice from 'components/notices/ConfirmNotice';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';


const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      id
    }
  }
`;

const CREATE_CANCELLATION_REASON = gql`
  mutation CreateCancellationReason($reason: String!) {
    createCancellationReason(reason: $reason) {
      id
    }
  }
`;

export default function CancelSub({changesStart}) {
  const [showCancel, setShowCancel] = useState(false);
  const [reason, setReason] = useState();
  const navigate = useNavigate();
  const [showCancellationReason, setShowCancellationReason] = useState();
  const [cancelSubscriptionMutation, { loading:cancelLoading }] = useMutation(CANCEL_SUBSCRIPTION);
  const [createCancellationReasonMutation, { loading }] = useMutation(CREATE_CANCELLATION_REASON);


  const cancelSubscription = () => {
    setShowCancel(false);
    cancelSubscriptionMutation()
      .then(res => {
        setShowCancellationReason(true);
      })
  }

  const addCancellationReason = () => {
    setShowCancel(false);
    createCancellationReasonMutation({variables: {reason}})
      .then(res => {
        // navigate('/my-subscription')
        window.location.href = '/my-subscription';
      })
  }

  const closeCancellationReason = () => {
    setShowCancellationReason(false);
    // navigate('/my-subscription');
    window.location.href = '/my-subscription';
  }


  const promptCancel = () => {
    setShowCancel(true);
  }

  return (
    <>
      {showCancel && <ConfirmNotice 
        title={`Are you sure you want to cancel your subscription?`}
        message={
          <div>
            <p> Your subscription will end at the end of this billing period {changesStart}.</p>
            <b>
              If you are planning on continuing your subscription in the future you can try pausing your account instead.
            </b>
          </div>
        }
        submessage={`To cancel the subscription type "cancel" below.`}
        confirmText={"cancel"}
        onConfirm={cancelSubscription}
        onClose={() => setShowCancel(false)}
      /> }
      {
        showCancellationReason &&
        <Transparent>
          <div onClick={closeCancellationReason} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border w-full max-w-160 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-green`}>
                <h2 className="">
                  What is your reason for cancelling?
                </h2>
                <FontAwesomeIcon onClick={closeCancellationReason} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2 justify-center">
                <h3>
                  Let us know why you are cancelling so we can improve our service. 
                </h3>
                <textarea onChange={(e) => setReason(e.target.value)} className="border border-dark-grey rounded w-full p-2"/>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={closeCancellationReason} >Close</ButtonPrimary>
                <ButtonPrimary className=""  disabled={loading} onClick={addCancellationReason} >{loading ? <Loading/> : 'Submit'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
      <MiniButton onClick={() => promptCancel()} disabled={cancelLoading} className="text-red border-red" colored>{cancelLoading ? <Loading/> : <><FontAwesomeIcon icon={faTimes} /> Cancel subscription</>}</MiniButton>
    </>
  )
}
