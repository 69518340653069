import React, {useState, useEffect} from 'react';

export default function Input({type, valueOverride, onChange, onBlur, readOnly, placeholder, className, clear, error, forwardRef, ...rest}) {
  const [value, setValue] = useState(valueOverride || '');
  useEffect(() => {
    if (valueOverride || (valueOverride === '' && value)) {
      setValue(valueOverride);
    }
  }, [valueOverride]);

  useEffect(() => {

    if (clear) {
      setValue('');
    }
  }, [clear]);

  const changeValue = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (Math.abs(newValue.length - value.length) > 1 || value === '') {
      handleOnBlur(null, newValue);
    }
    onChange && onChange(newValue);
  }

  const handleOnBlur = (event, override = null) => {
    onBlur && onBlur(override || value);
  }

  return (
    <>
      {type === 'textarea' ?
        <textarea 
          placeholder={placeholder} 
          className={`
            border p-2 rounded ${className} 
            border-dark-grey
            ${readOnly && 'bg-light-light-grey cursor-default'}
            ${error && 'text-red'}
          `} 
          type={type || "text"} 
          readOnly={readOnly} 
          value={value} 
          onChange={e => changeValue(e)}
          onBlur={handleOnBlur}
          ref={forwardRef}
          {...rest}
        />
      :
        <input 
          placeholder={placeholder} 
          className={`
            border p-2 rounded ${className} 
            border-dark-grey
            ${readOnly && 'bg-light-light-grey cursor-default'}
            ${error && 'text-red'}
          `} 
          type={type || "text"} 
          readOnly={readOnly} 
          value={value} 
          onChange={e => changeValue(e)}
          onBlur={handleOnBlur}
          ref={forwardRef}
          {...rest}
        />
      }
    </>
  )
}
