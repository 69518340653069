import Dispatch from './Dispatch';
import User from './ItemsTable';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = [
  <Route key="Dispatch" path="" element={<Dispatch/>} />,
];

export default routes;
