import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import Logo from 'assets/images/logo/LogoBW.png';

const NotificationBanner = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  z-index: 20;
`;

const Footer = ({ childRef }) => {
  return (
    <>
      <NotificationBanner className="bg-primary"></NotificationBanner>
      <div 
        className="
          bg-dark
          sm:p-6 
          md:px-12 
          md:py-6 
          lg:px-16 
          lg:py-8 
          xl:px-24 
          xl:py-12
          hide-print
          "
        ref={childRef}
      >
        <div className="2xl:mx-auto 2xl:max-w-8xl">
          <div className="flex sm:flex-col md:flex justify-between items-center">
              <div className="w-20 h-10 bg-contain bg-no-repeat bg-center" style={{backgroundImage: `url(${Logo})`}}/>
              <div className="flex-auto text-right my-3">
                <a className=" text-xl mr-3" href="https://www.facebook.com"><FontAwesomeIcon icon={faFacebook} /></a>
                <a className=" text-xl" href="https://www.instagram.com"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className=" text-xl ml-3" href="https://www.linkedin.com"><FontAwesomeIcon icon={faLinkedin} /></a>
              </div>
          </div>
          <hr className="border-t-1 border-background-dark" />
          <div className="flex sm:flex-col justify-between">
              <div className="flex py-2 gap-2">
                <Link className="no-underline" to="/contact"><span className="font-bold text-sm">Contact Us</span></Link>
              </div>
              <div>
                <div className="my-2  text-xs">
                  &copy; 2021 Coldacre.
                </div>
                <div className=" text-xs">
                  <Link to={'/terms-and-conditions'}><span className="">TERMS AND CONDITIONS</span></Link>
                  <span> | </span>
                  <Link to={'/privacy'}><span className="">PRIVACY POLICY</span></Link>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
