import React, {useCallback, useContext, useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEnvelope, faUser, faTimes, faCircleLeft, faMask, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import MiniButton from 'components/common/Button/MiniButton';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Input from 'components/inputs';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import locationAutocomplete from 'helpers/locationAutocomplete';
import { useRef } from 'react';
import { useEffect } from 'react';
import { PaddedPage } from 'components/common/Layout';

const GET_ORGANIZATION = gql`
  query GetOrganization($id: String) {
    organization(id: $id) {
      id,
      name,
      email,
      phoneNumber,
      addressOne,
      addressTwo,
      subdomain,
      locations
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id: String!, $name: String, $email: String, $subdomain: String, $phoneNumber: String, $addressOne: String, $addressTwo: String, $locations: [String]) {
    updateOrganization(id: $id, name: $name, email: $email, subdomain: $subdomain, phoneNumber: $phoneNumber, addressOne: $addressOne, addressTwo: $addressTwo, locations: $locations) {
      id
    }
  }
`;

export default function Organization({removeUser, currentFirmId}) {
  const {currentUser} = useContext(AppContext);
  const params = useParams();
  const { loading, error, data } = useQuery(GET_ORGANIZATION, {variables: {id: params.id}});
  const [organization, setOrganization] = useState({});
  const [edit, setEdit] = useState(false);
  const addressInputRef = useRef();
  const [updateOrganizationMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_ORGANIZATION);

  const measuredRef = useCallback(node => {
    if (node !== null && !edit) {
      let autocomplete = locationAutocomplete(node);
      autocomplete.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setOrganization(current => ({...current, addressOne: place.formatted_address, latitude, longitude}));
      });
      addressInputRef.current = node;
    }
  }, []);

  useEffect(() => {
    if (data && data.organization) {
      setOrganization(data.organization);
    }
  }, [data]);

  const toggleEdit = () => {
    if (edit) {
      setOrganization(data.organization);
    }
    setEdit(!edit);
  }

  const updateOrganization = () => {
    updateOrganizationMutation({variables: {id: params.id, ...organization}})
      .then(res => {
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }

  return (
    <PaddedPage className="w-full">
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            Organization
            <sup>
              <FontAwesomeIcon onClick={toggleEdit} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
            </sup>
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          <label>Name</label>
          {edit ?<Input valueOverride={organization.name} onChange={(name) => setOrganization({...organization, name})}/> : <p>{organization.name}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Email</label>
          {edit ?<Input valueOverride={organization.email} onChange={(email) => setOrganization({...organization, email})}/> : <p>{organization.email}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Subdomain</label>
          {edit ?<Input valueOverride={organization.subdomain} onChange={(subdomain) => setOrganization({...organization, subdomain})}/> : <p>{organization.subdomain}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Phone Number</label>
          {edit ?<Input valueOverride={organization.phoneNumber} onChange={(phoneNumber) => setOrganization({...organization, phoneNumber})}/> : <p>{organization.phoneNumber}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Address</label>
          {edit ?<Input forwardRef={measuredRef} valueOverride={organization.addressOne} onChange={(addressOne) => setOrganization({...organization, addressOne})}/> : <p>{organization.addressOne}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Apt, suite, etc.</label>
          {edit ?<Input valueOverride={organization.addressTwo} onChange={(addressTwo) => setOrganization({...organization, addressTwo})}/> : <p>{organization.addressTwo}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Locations</label>
          {edit ?<Input valueOverride={organization.locations} onChange={(locations) => setOrganization({...organization, locations})}/> : <p>{organization.locations}&nbsp;</p>}
        </div>
        {edit && <ButtonPrimary className="self-start" disabled={updateLoading} onClick={updateOrganization}>{updateLoading ? <Loading/> : "Update Organization"}</ButtonPrimary>}
      </div>
    </PaddedPage>
  )
}
