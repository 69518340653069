import React, {useContext, createContext, useState} from 'react';
import { Link } from 'react-router-dom';
import ActivePageContext from 'contexts/ActivePageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AppContext from 'contexts/AppContext';
import { gql, useQuery } from '@apollo/client';

export default function InventoryNav({children}) {
  const {currentUser} = useContext(AppContext);
  const [activeSubpage, setActiveSubpage] = useState();
  const {setActivePage, organizationId, setOrganizationId} = useContext(ActivePageContext);

  setActivePage("inventory");

  return (
    <div className="flex flex-col grow">
        <div className="w-full bg-light border-b border-transparent-dark-grey bg-transparent-dark-grey items-center px-3 flex gap-2 justify-between">
            <div className='flex gap-3 no-underline'>
                <Link className={`no-underline py-1 ${activeSubpage ? 'text-med-grey' : 'text-primary-two'}`} to="/admin/inventory">Inventory</Link>
                <Link className={`no-underline py-1 ${activeSubpage !== "new" ? 'text-med-grey' : 'text-primary-two'}`} to="/admin/inventory/new">New<FontAwesomeIcon icon={faPlus}/></Link>
                <Link className={`no-underline py-1 ${activeSubpage !== "newTag" ? 'text-med-grey' : 'text-primary-two'}`} to="/admin/inventory/new-tag">New Tag<FontAwesomeIcon icon={faPlus}/></Link>
            </div>
        </div>
        <div>
          <ActivePageContext.Provider value={{setActiveSubpage, organizationId, setOrganizationId}}>
            {children}
          </ActivePageContext.Provider>
        </div>
    </div>
  )
}
