import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import PlantBG from 'assets/images/landing/plant.jpg'
import PlantBottom from 'assets/images/landing/bottom.jpg'
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/productlanding.jpg';
import PrefabDiagram from './PrefabDiagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import CustomOptions from './CustomOptions';
import PrefabSizes from './PrefabSizes';
import { Link } from 'react-router-dom';
import PrefabNav from './PrefabNav';


const PrefabUnits = () => {
  const {setPage} = useContext(AppContext);

  useEffect(() => setPage("Prefab Units"), [setPage]);
  
  return (
    <>
      <PrefabNav/>
      <div className="flex flex-col">
        <div className="bg-cover pt-12 bg-center" style={{backgroundImage: `url(${farm})`}}>
          <PaddedPage noBottom noTop className="flex flex-col items-center justify-between pt-4">
            <h1 className="text-white -ml-8">
              Model&nbsp;
              <span className="lightgradient rotatingText">6</span>
              <span className="lightgradient rotatingText">12</span>
              <span className="lightgradient rotatingText">16</span>
            </h1>
            <div className="h-80 w-1/2 sm:h-2">
            
            </div>
            <div className="flex gap-8 py-8 text-white sm:flex-col">
              <div className="flex flex-col items-center">
                <h2 className="text-white">Customizable</h2>
                <small className="font-bold">Options to fit your needs</small>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-white">Tested</h2>
                <small className="font-bold">We use our products</small>
              </div>
              <Link to="/order" className="text-center">
                <ButtonPrimary white >Order Now</ButtonPrimary>
              </Link>
            </div>
          </PaddedPage>
        </div>
        <PrefabDiagram/>
        <PaddedPage className="flex justify-center">
          <div>
            <h1>Fast Lead Times <FontAwesomeIcon icon={faCalendarDays} /></h1>
            <h2>Ready when you need it</h2>
          </div>
        </PaddedPage>
        <CustomOptions/>
        <PrefabSizes/>
      </div>
    </>
  );
};

export default PrefabUnits;
