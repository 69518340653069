import React, {useContext, createContext, useState} from 'react';
import { Link } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import ActivePageContext from 'contexts/ActivePageContext';

export default function AdminNav({children}) {
  const [activePage, setActivePage] = useState();
  const {currentUser} = useContext(AppContext); 
  const [organizationId, setOrganizationId] = useState();

  return (
    <div className="flex flex-col grow">
        <div className="w-full hide-print bg-light-grey border-b border-transparent-dark-grey bg-transparent-dark-grey items-center px-3 flex justify-between">
            <div className="flex gap-2">
              <Link className="no-underline text-primary text-lg font-medium" to="/admin">
                  Admin
              </Link>
            </div>
            <div className='flex gap-2 no-underline'>
                {currentUser.type === "super-admin" && <Link className={`no-underline py-3 ${activePage ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="super/users">Users</Link>}
                {!currentUser.organizationId && <Link className={`no-underline py-3 ${activePage !== "dispatch" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="dispatch">Dispatch</Link>}
                <Link className={`no-underline py-3 ${activePage !== "inventory" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="inventory">Inventory</Link>
                {!currentUser.organizationId && <Link className={`no-underline py-3 ${activePage !== "orders" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="orders">Orders</Link>}
                {currentUser.type === "super-admin" && <Link className={`no-underline py-3 ${activePage !== "organizations" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="/admin/super/organizations">Organizations</Link>}
                {currentUser.type === "super-admin" && <Link className={`no-underline py-3 ${activePage !== "plants" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="/admin/plants">Plants</Link>}
                <Link className={`no-underline py-3 ${activePage !== "plant-management" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="plant-management">Plant Management</Link>
                {currentUser.type === "super-admin" && <Link className={`no-underline py-3 ${activePage !== "products" ? 'text-med-grey' : 'text-dark-grey border-b border-dark-grey'}`} to="super/products">Products</Link>}
            </div>
        </div>
        <div>
          <ActivePageContext.Provider value={{setActivePage, organizationId, setOrganizationId}}>
            {children}
          </ActivePageContext.Provider>
        </div>
    </div>
  )
}
