import { gql, useQuery } from '@apollo/client';
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MiniButton from 'components/common/Button/MiniButton';
import React, { useState } from 'react'

const GET_PORTAL = gql`
  query GetStripePortalSession {
    stripePortalSession
  }
`;

export default function GetPortal() {
  const [getPortal, setGetPortal] = useState(false);
  const { loading: portalLoading } = useQuery(GET_PORTAL, {
    fetchPolicy:'network-only',
    skip: !getPortal,
    onCompleted: (data) => {
      window.open(data.stripePortalSession, '_blank').focus();
    }
  });

  return (
    <MiniButton onClick={() => setGetPortal(true)} disabled={portalLoading} className=""><FontAwesomeIcon icon={faMoneyBill1Wave} /> Invoice History</MiniButton>
  )
}
