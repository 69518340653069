import Transparent from 'components/notices/Transparent'
import React from 'react'
import ButtonPrimary from './Button'

export default function Modal({close, children, naked}) {
  return (
    <Transparent less>
      <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
        {
          naked ?
            <div onClick={(e) => e.stopPropagation()}lassName='max-h-85 overflow-y-scroll scroller rounded border bg-white'>
              {children}
            </div>
          :
            <div className="bg-white rounded border max-w-7/8 shadow-md">
                <div className={`flex justify-between relative px-4 py-2`}>
                  {children}
                </div>
                <div className="p-4 bg-whiter flex justify-end border-t py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                </div>
            </div>
        }
      </div>
    </Transparent>
  )
}
