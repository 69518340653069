import { faArrowRight, faMoneyBill, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonPrimary from 'components/common/Button';
import Transparent from 'components/notices/Transparent';
import React, { useState } from 'react'
import Calendar from 'components/calendar';
import Loading from 'components/common/Loading';
import { useMutation, useQuery, gql } from "@apollo/client";
import Input from 'components/inputs';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useEffect } from 'react';

const CREATE_BALANCE = gql`
  mutation CreateCustomerBalance($userId: String!, $amount: Int!) {
    createCustomerBalance(userId: $userId, amount: $amount) {
      id,
      amount,
      customer,
      description,
      ending_balance,
      type,
      metadata,
      invoice,
      created,
    }
  }
`;

const GET_CUSTOMER_BALANCES = gql`
  query GetCustomerBalances($userId: String!) {
    customerBalances(userId: $userId) {
      id,
      amount,
      customer,
      description,
      ending_balance,
      type,
      metadata,
      invoice,
      created,
    }
  }
`;

export default function CreateBalanceModal({user, onConfirm, onClose}) {
  let [open, setOpen] = useState(true);
  let [customerBalances, setCustomerBalances] = useState();
  let [amount, setAmount] = useState(0);
  const [createBalanceMutation, { loading, reset }] = useMutation(CREATE_BALANCE);
  const { loading: balancesLoading, error, data } = useQuery(GET_CUSTOMER_BALANCES, {
    variables: { userId: user.id },
  });

  useEffect(() => {
    if (data && data.customerBalances) {
      setCustomerBalances(data.customerBalances);
    }
  }, [data]);


  const close = () => {
    // setOpen(false);
    onClose();
  }

  const confirm = () => {
    if (amount && user) {
      // setOpen(false);
      createBalanceMutation({variables: {amount: Math.round(amount*100), userId: user.id}})
      .then((res) => {
        onConfirm && onConfirm();
        setCustomerBalances([ {...res.data.createCustomerBalance, new: true},...customerBalances]);
        reset();
      })
      .catch(() => {
        onClose();
      })
    }
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border w-full max-w-160 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-grey`}>
                <h2 className="">
                  <FontAwesomeIcon onClick={close} className="" icon={faMoneyBill} />
                  &nbsp;
                  Update Customer Balance
                </h2>
                <FontAwesomeIcon onClick={close} className=" cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2 justify-center">
                <div className="flex flex-col gap-2">
                  <div>
                    <h3>Previous Balance Changes</h3>
                    <hr></hr>
                  </div>
                  <div className="flex flex-col gap-1 max-h-160 overflow-y-scroll scroller">
                    {customerBalances ?
                      customerBalances.map(balance => (
                        <div className={`rounded bg-light-grey p-1 flex justify-between ${balance.new && 'bg-green'}`}>
                          <p className="font-bold"><FontAwesomeIcon className="" icon={faCalendar} /> {new Date(balance.created * 1000).toDateString()}</p> 
                          <p>${balance.amount/100}</p>
                        </div>
                      ))
                      :
                      <div className="border rounded p-4 border-med-grey bg-light-grey w-full flex justify-center items-center">
                        <FontAwesomeIcon className="font-xl text-med-grey" icon={faTimes} />
                      </div>
                    }
                  </div>
                  <h3>
                    How much would you like to update the customer's balance?
                    <br></br>
                    <small>A negative value is a credit for the customer’s balance, and a positive value is a debit to the customer’s balance.</small>
                  </h3>
                  <div>
                    <strong>$</strong><Input valueOverride={amount} onChange={setAmount} type="number"></Input>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
                <ButtonPrimary className=""  disabled={!amount || loading} onClick={confirm} >{loading ? <Loading/> : 'Add Credit'}</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
