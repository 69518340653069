import { faBuilding, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import OrderToggle from './OrderToggle'

export default function Order({order, product, admin}) {
  return (
    <div className="rounded bg-light-grey flex border border-background-dark">
        <div className="bg-background-dark text-sm flex items-center rounded-l px-1 text-white">
            {order.id.slice(0,6)}
        </div>
        <div className="p-1 px-2 flex justify-between items-center w-full">
            <div>
                <p className="font-bold">
                {order.quantity} x {product.name} 
                &nbsp;
                <span className="font-medium text-sm bg-white p-1 rounded"><FontAwesomeIcon icon={faClock}/> ordered: {new Date(Number(order.createdAt)).toISOString().split('T')[0]}</span>
                </p>
            </div>
            <div className="flex gap-2">
                {admin && 
                    <p className="rounded bg-primary-two text-sm text-white p-1"><FontAwesomeIcon icon={faBuilding}/> {order.organization.name}</p>
                }
                <OrderToggle order={order} readOnly={!admin}/>
            </div>
        </div>
    </div>  
  )
}
