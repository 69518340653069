window.autoCompleteSearchBar = null;
window.initAutoComplete = (input, options) => {
  try{
    const options = {
      componentRestrictions: { country: 'can' },
      fields: ['geometry', 'formatted_address'],
    };
    return window.google && new window.google.maps.places.Autocomplete(window.autoCompleteSearchBar || input, options);
    // autocomplete && autocomplete.setFields([, "name"]);
  }catch (e) {
    console.log('error', e);
  }
};
export default function locationAutocomplete(input) {
  window.autoCompleteSearchBar = input;
  return window.initAutoComplete && window.initAutoComplete(input);
}
