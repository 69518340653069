import React, {useMemo, useState} from 'react';
import CalendarMonth from './CalendarMonth';
import CalendarContext from 'contexts/CalendarContext';
import { monthsBetween } from 'helpers/calendar';
import { sameDay } from 'helpers/time';


const addMonths = (change, date) => {
  let newMonth = date.getMonth() + change;
  if (newMonth > 11) {
    return new Date(date.getFullYear() + 1, newMonth%12, 1);
  } else if (newMonth < 0) {
    return new Date(date.getFullYear() + 1, 12 - newMonth, 1);
  } else {
    return new Date(date.getFullYear(), newMonth, 1);            
  }
}

const OneTimeProductCalendar = ({max, min, billingAnchor, onSelected, validDays}) => {
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState();

  const handleSetDate = (val) => {
		setDate(val);
	}
  // eventually can have these dynamic so different select criteria
  const startClickable = (date) => {
    if (validDays.includes(date.toISOString().split('T')[0])) {
      return true;
    }
  }

  const selectDate = (date) => {
    if (!startDate) {
      onSelected(date);
      setStartDate(date);
    } else {
      setStartDate();
      onSelected();
    }
  }

  const nextMonth = useMemo(() => {
    let newMonth = addMonths(1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min]);

  const prevMonth = useMemo(() => {
    let newMonth = addMonths(-1, date);
    let diff = monthsBetween(newMonth, new Date());
    if ((max !== undefined ? diff <= max : true) && (min !== undefined ? diff >= min: true)) {
      return () => setDate(newMonth);
    }
    return null;
  }, [date, max, min])

  return (
		<CalendarContext.Provider value={{
      date,
      startClickable,
      selectDate,
      startDate,
      endDate: startDate,
      setDate: handleSetDate, 
      nextMonth, 
      prevMonth
    }}>
      <div className="flex gap-4 justify-center">
          <div className="flex flex-col gap-2 grow max-w-40vh">
            <CalendarMonth/>
          </div>
      </div>
		</CalendarContext.Provider>
  )
}

export default OneTimeProductCalendar;
