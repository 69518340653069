import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const options = [
  '🎋','🎍','🌻','🌼','🌷','🥀','🌱','🍂','🍁','🍀','🌿','🌾','🌵','🌴','🌳','🍃','🌸','💐','🥜','🌰','🥕','🧅','🧄','🥔','🥦','🥬','🥒','🥑','🍄','🍃',
  '📌',
  '💦','🧊',
  '⚡',
  '🔷',
  '💥',
  '🔰',
  '🟢',
  '🎁',
  '🎄',
  '🎀',
  '💎',
  '💰',
  '🧱',
  '📤',
  '📥'
];

export default function IconSelector({initialValue, onChange, small, readOnly}) {
  const [value, setValue] = useState(initialValue || '');
  const [showIcons, setShowIcons] = useState();

  const changeValue = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  }
  return (
    <div className="relative">
        <div className="flex flex-col items-start py-2 cursor-pointer" onClick={() => !readOnly && setShowIcons(!showIcons)}>
          {value ?
            <h1 className={`${small ? 'text-base' : ''}`}>{value} <FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></h1>
            :
            <h1 className={`bg-light-grey rounded ${small ? 'text-base' : ''}`}><span className="opacity-0">❌</span><FontAwesomeIcon className="relative top-1 text-base" icon={faCaretDown} /></h1>
          }
        </div>
        {
          showIcons && 
          <div className={`p-1 text-2xl flex flex-wrap gap-3 ${small ? 'absolute w-fullscreen border border-background-dark bg-white rounded z-10' : ''}`}>
            {options.map((option) => (
                  <div
                    className="hover:text-3xl hover:bg-light-grey hover:p-1 p-2 cursor-pointer"
                    key={option}
                    name={option} 
                    value={option}
                    onClick={() => {
                      setShowIcons(false);
                      changeValue(option);
                    }}
                  >
                    {option}
                  </div>
              ))
            }
          </div>
        }
    </div>
  )
}
