import { gql, useMutation } from '@apollo/client';
import { faExclamationTriangle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MiniButton from 'components/common/Button/MiniButton'
import Loading from 'components/common/Loading';
import Tooltip from 'components/common/Tooltip';
import AppContext from 'contexts/AppContext';
import React, { useContext } from 'react'
import { useState } from 'react';

const UPDATE_QUANTITY = gql`
  mutation UpdateQuantity($inventoryItemId: String!, $change: Float!) {
    updateInventoryQuantity(inventoryItemId: $inventoryItemId, change: $change) {
      id
    }
  }
`;

export default function Quantity({item}) {
  const {updateWarnings} = useContext(AppContext);
  const [quantityStart, setQuantityStart] = useState(Number(item.quantity.quantity));
  const [manualWarn, setManualWarn] = useState();
  const [change, setChange] = useState(0);
  const [updateQuantityMutation, { loading }] = useMutation(UPDATE_QUANTITY);

  const update = () => {
    updateQuantityMutation({
      variables: {inventoryItemId: item.id, change: change}
    })
    .then(res => {
      if (item.notify && ((item.notify > quantityStart && item.notify <= quantityStart + change) || (item.notify <= quantityStart && item.notify > quantityStart + change))) {
        if (item.notify > quantityStart + change) {
          setManualWarn('warn');
        } else {
          setManualWarn("safe");
        }
        updateWarnings();
      }
      setQuantityStart(quantityStart + change);
      setChange(0);
    })
  }

  return (
    <div className="flex gap-4 items-center">
        <div className="relative">
          <input type="number" onChange={e => setChange(e.target.value - quantityStart)} value={quantityStart + change}/>
          {
            (manualWarn && (manualWarn == 'warn')) ||
            (item.notify && item.notify > item.quantity.quantity) ?
              <div className="absolute top-1 right-4">
                <Tooltip 
                  message="This Item is low Stock, to turn off these warinings view inventory item edit page"
                  icon={faExclamationTriangle}
                  color="red"
                />
              </div>
            :
            <></>
          }
        </div>
        <div className="flex items-center gap-2">
        <MiniButton onClick={() => setChange(change + 1)} className="text-primary-two border-primary-two"><FontAwesomeIcon icon={faPlus}/></MiniButton>
        <MiniButton onClick={() => setChange(change - 1)} className="text-red border-red"><FontAwesomeIcon icon={faMinus}/></MiniButton>
        {change ? <MiniButton onClick={update} diabled={loading}>{loading ? <Loading/> : "Update"}</MiniButton> : ''}
        </div>
    </div>
  )
}
