import { PaddedPage } from 'components/common/Layout'
import React from 'react'
import Subscription from './old/Subscription'
import { useQuery, gql } from "@apollo/client";
import SubscriptionOverview from './SubscriptionOverview';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'components/common/Button';
import SubscriptionCalendar from 'components/calendar/SubscriptionCalendar';
import OneTimeProducts from './OneTimeProducts';
import { useState } from 'react';
import OneTimeProductModal from './OneTimeProductModal';
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import { faGift, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

const GET_SUBSCRIPTION = gql`
  query GetSubscription {
    subscription {
      id,
      cancel_at_period_end,
      items {
        price {
          unit_amount,
          nickname, 
          metadata,
          product{
            description,
            image,
            name
          },
        },
        metadata,
        quantity
      },
      start_date,
      metadata,
      current_period_end,
      current_period_start,
      schedule {
        current_phase {start_date, end_date}
        metadata,
        phases{
          end_date,
          start_date,
          trial_end,
          metadata,
          items{
            quantity,
            price{
              metadata,
              nickname,
              recurring{
                interval,
                interval_count
              },
              product{
                description,
                image,
                name
              },
              unit_amount
            }
          }
        }
        status
      },
      user {
        balance,
        stripeCustomerId
      },
      oneTimeProducts {
        id,
        status,
        quantity,
        price{
          metadata,
          nickname,
          recurring{
            interval,
            interval_count
          },
          product{
            description,
            image,
            name
          },
          unit_amount
        }
        invoiceId,
        subscriptionId,
        invoiceItemId,
        date,
      }
    }
  }
`;

export default function SubscriptionsOverview() {
  const { loading, error, data, refetch } = useQuery(GET_SUBSCRIPTION, {fetchPolicy:'network-only'});
  const navigate = useNavigate();
  const [showOneTimeModal, setShowOneTimeModal] = useState(false);
  const {currentUser, impersonating} = useContext(AppContext);
  const upcommingChanges = (data && data.subscription && data.subscription.schedule && data.subscription.schedule.phases && data.subscription.schedule.phases.filter(phase => (new Date(phase.start_date * 1000) > new Date()))) || null;
  let forwardedMeta = {...((data && data.subscription && data.subscription.metadata) || {}), ...((data && data.subscription && data.subscription.schedule && data.subscription.schedule.metadata) || {})};
  for (let change of (upcommingChanges || [])) {
    forwardedMeta = {...forwardedMeta, ...change.metadata};
    if (!change.metadata.paused) {
      delete forwardedMeta.paused;
    }
    change.metadata = forwardedMeta;
  }

  const addOneTimeProduct = () => {
    setShowOneTimeModal(false); 
    refetch();
  }

  const activeSubscription = data && data.subscription && (data.subscription.id || upcommingChanges);
  return (
    <PaddedPage className="m-0 w-full">
      {showOneTimeModal && <OneTimeProductModal
        subscription={data && data.subscription} 
        upcommingChanges={upcommingChanges}
        onComplete={addOneTimeProduct}
        loading={false}
        onClose={() => setShowOneTimeModal(false)}
      /> }
      <div className="flex flex-col gap-4 w-full">
        <h1>
          My Subscription
          {
            activeSubscription &&
            <sup>
              &nbsp;
              <span className="text-primary text-xl tracking-tight">Edit</span>
              &nbsp;
              <FontAwesomeIcon onClick={() => navigate("/subscription")} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
            </sup>
          }
        </h1>
        {/* <div className="flex w-full gap-8 sm:flex-col"> */}
          <SubscriptionCalendar 
            min={0} 
            max={2} 
            subscription={data && data.subscription} 
            upcommingChanges={upcommingChanges}
            billingAnchor={data && data.subscription && (data.subscription.current_period_end || (data.subscription.schedule && data.subscription.schedule.phases && data.subscription.schedule.phases[0].start_date))}
          >
            {activeSubscription &&
              <OneTimeProducts onRemove={refetch} addProduct={() => setShowOneTimeModal(true)} subscription={data && data.subscription}/>
            }
          </SubscriptionCalendar>
        {/* </div> */}
        <div>
          {!activeSubscription &&
            <Link to="/subscription">
              <ButtonPrimary className="w-full">Add Subscription</ButtonPrimary>
            </Link>
          }
        </div>
        {
          data &&
          <div>
            {data.subscription.user.balance ?
              <div>
                <FontAwesomeIcon icon={faMoneyBill} /><label> Customer Balance: </label>${data.subscription.user.balance/100}
              </div>
              : <></>
            }
            <div>
              <FontAwesomeIcon icon={faGift} /><label> Referral Code: <i>{data && data.subscription.user.stripeCustomerId.replace('cus_', '')}</i></label>
            </div>
          </div>
        }
        {
          (impersonating || ["admin", "super-admin"].includes(currentUser?.type)) &&
          <>
            <div className="w-full flex flex-col gap-2">
              <div>
                <h2>Active Subscription</h2>
                <hr></hr>
              </div>
              <SubscriptionOverview sub={data && data.subscription} lastUpdate={data && data.subscription && data.subscription.cancel_at_period_end !== 'true' && (!upcommingChanges || upcommingChanges.length === 0)}/>
            </div>
            {upcommingChanges &&
              <div className="w-full flex flex-col gap-2">
                <div>
                  <h2>Pending Updates</h2>
                  <hr></hr>
                </div>
                {upcommingChanges.map((change, index) => (
                  <SubscriptionOverview key={index} sub={change} lastUpdate={index === upcommingChanges.length -1}/>
                ))}
              </div>
            }
          </>
        }
      </div>

    </PaddedPage>
  )
}
