import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, gql } from "@apollo/client";
import DeliveryNotes from './DeliveryNotes';

const GET_PRODUCTS = gql`
  query GetProducts($query: String!) {
    products(query: $query) {
      id,
      description,
      image,
      name,
      metadata,
      unit_label,
      prices {
        id,
        active,
        nickname,
        recurring {
          aggregate_usage,
          interval,
          interval_count,
          usage_type,
        },
        type,
        unit_amount
      }
    }
  }
`;

const unitPrice = (price) => {
  if (price?.nickname && price.nickname.toLowerCase() === "weekly") {
    return (Number(price.unit_amount)/2/100).toFixed(2);
  }
  return (Number(price?.unit_amount)/100).toFixed(2);
}

const isWeeklySub = (subscription, deliveryProductId) => {
  return Object.entries(subscription.items).find(([key, item]) => key !== deliveryProductId && item.price && item.price.nickname.toLowerCase() === "weekly")
}

export default function DeliveryPicker({defaultDelivery, onChange, updateDeliveryCharge, subscription, onNotesChange, setDelivery}) {
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: { query : "metadata['subtype']:'delivery'" },
    fetchPolicy:'no-cache',
  });
  const pricingInfo = useMemo(() =>{
    let deliveryProduct =  data && data.products && data.products.find(prod => prod.metadata.location == subscription.location);
    if (deliveryProduct) {
      let weekly = isWeeklySub(subscription, deliveryProduct.id);
      // check if weekly or biweekly & match to current sub
      let prices = deliveryProduct.prices.filter(price => price?.active == true && price.recurring && price.recurring.interval === "week" && price.recurring.interval_count === "2");
      let weeklyPrice = prices.find(price => price.nickname.toLowerCase() === "weekly");
      let biweeklyPrice = prices.find(price => price.nickname.toLowerCase() === "bi-weekly")
      return {price: weekly ? weeklyPrice : biweeklyPrice, product: deliveryProduct};
    }
  }, [subscription, data]);

  const deliveryCharged = useMemo(() => {
    const charged = subscription && pricingInfo && ((subscription.items[pricingInfo.product.id] && subscription.items[pricingInfo.product.id].quantity > 0));
    setDelivery(charged);
    return charged;
  },[subscription, pricingInfo]);
  
  useEffect(() => {
    // if subscription changes from weekly to biweekly or reverse update delivery charge
    if (deliveryCharged) {
      // let weekly = isWeeklySub(subscription, pricingInfo.product);
      if (pricingInfo.price?.id !== subscription.items[pricingInfo.product?.id].price?.id) {
      // if ((weekly && subscription.items[pricingInfo.product].price.id !== pricingInfo.weeklyPrice) || (!weekly && subscription.items[pricingInfo.product].price.id !== pricingInfo.biweeklyPrice)) {
        updateDeliveryCharge({[pricingInfo.product.id]: {price: {...pricingInfo.price, product: pricingInfo.product}, quantity: 1}});
      }
    }
  }, [subscription, deliveryCharged, pricingInfo, updateDeliveryCharge]);

  useEffect(() => {
    let changes = {};
    if (data) {
      for (let product of data.products || []) {
        changes[product.id] = {quantity: 0};
      }
      if (subscription.location === "whitehorse") {
        updateDeliveryCharge(changes);
      } else {
        updateDeliveryDay("wednesday");
        updateDeliveryCharge({...changes, [pricingInfo.product.id]: {price: {...pricingInfo.price, product: pricingInfo.product}, metadata: {subtype: "delivery"}, quantity: 1}});
      }
    }
  }, [subscription.location]);

  const updateDeliveryDay = (delivery) => {
    onChange(delivery);
  }

  const addDeliveryCharge = () => {
    updateDeliveryCharge({[pricingInfo.product.id]: {price: {...pricingInfo.price, product: pricingInfo.product}, quantity: 1}});
  }
  const removeDeliveryCharge = () => {
    updateDeliveryCharge({[pricingInfo.product.id]: {price: {...pricingInfo.price, product: pricingInfo.product}, quantity: 0}});
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <label>Distribution</label>
        {
          deliveryCharged ?
            <p className="p-6 rounded-lg bg-light-grey">
              {subscription.location === "whitehorse" ?
              "Deliveries are done on Wednesdays or Thursdays between 4pm and 8:30pm to in-town customers who live between Crestview and the Carcross cut-off."
              :
              "Deliveries are done on Wednesday evenings between 4 and 8:30pm in Dawson."
              }
            </p>
          :
            <p className="p-6 rounded-lg bg-light-grey">
              Pick-ups are on Wednesdays or Thursdays between noon and 6:30pm at our 110 Titanium Way location. Please bring a reusable bag.
            </p>
        }
        <div className="flex gap-2">
          {subscription.location === "whitehorse" &&
          <div className={`
            border-2 rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
            ${!deliveryCharged ? 'border-dark-grey text-dark-grey' : 'border-light-grey text-light-grey'}
            `}
              onClick={() => removeDeliveryCharge()}
          >
            <h2 className={`${!deliveryCharged ? 'text-dark-grey': "text-light-grey"}`}>Pick-up</h2>
            <small className="font-bold">Free</small>
          </div>}
          <div className={`
            border-2 rounded px-4 py-2 flex flex-col cursor-pointer grow text-center
            ${deliveryCharged ? 'border-dark-grey text-dark-grey' : 'border-light-grey text-light-grey'}
            `}
              onClick={() => addDeliveryCharge()}
          >
            <h2 className={`${deliveryCharged ? 'text-dark-grey': "text-light-grey"}`}>Delivery</h2>
            <small className="font-bold">${pricingInfo && unitPrice(pricingInfo.price)}/delivery</small>
          </div>
        </div>
        <div className="flex gap-2 justify-around">
          <div className="flex flex-col items-center">
            <h2 className="text-light-grey">S</h2>
            <div className="rounded-full w-10 h-10 border"/>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-light-grey">M</h2>
            <div className="rounded-full w-10 h-10 border"/>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-light-grey">T</h2>
            <div className="rounded-full w-10 h-10 border"/>
          </div>
          {defaultDelivery == 'wednesday' || subscription.location !== "whitehorse" ?
            <div className="flex flex-col items-center">
              <h2 className="text-background-dark">W</h2>
              <div className={`cursor-pointer rounded-full w-10 h-10 border ${defaultDelivery == 'wednesday' ? 'bg-accent-three' : ''} border-light-grey`}
                onClick={() => updateDeliveryDay("wednesday")}
              />
            </div>
          :
            <div className="flex flex-col items-center">
              <h2 className="text-light-grey">W</h2>
              <div className="rounded-full w-10 h-10 border"/>
            </div>
          }
          {subscription.location === "whitehorse" ?
            <div className="flex flex-col items-center">
              <h2 className="text-background-dark">T</h2>
              <div className={`cursor-pointer rounded-full w-10 h-10 border ${defaultDelivery == 'thursday' ? 'bg-accent-three' : ''} border-light-grey`}
                onClick={() => updateDeliveryDay("thursday")}
              />
            </div>
          :
            <div className="flex flex-col items-center">
              <h2 className="text-light-grey">T</h2>
              <div className="rounded-full w-10 h-10 border"/>
            </div>
          }
          <div className="flex flex-col items-center">
            <h2 className="text-light-grey">F</h2>
            <div className="rounded-full w-10 h-10 border"/>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-light-grey">S</h2>
            <div className="rounded-full w-10 h-10 border"/>
          </div>
        </div>
        {
          <div className="flex gap-4 justify-center">
            <div className="p2 flex flex-col cursor-pointer text-center">
              <h3 className="">Available</h3>
              {
                deliveryCharged ?
                <p className="">4pm - 8:30pm</p>
                :
                <p className="">Noon - 6:30pm</p>
              }
            </div>
          </div>
        }
      </div>
      {deliveryCharged && <DeliveryNotes subscription={subscription} onChange={onNotesChange}/>}
    </>
  )
}
