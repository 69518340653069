import { PaddedPage } from 'components/common/Layout'
import React, { useCallback, useEffect } from 'react'
import { useContext } from 'react';
import AppContext from 'contexts/AppContext';
import Input, { Checkbox } from 'components/inputs';
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from 'react';
import userEvent from '@testing-library/user-event';
import ButtonPrimary from 'components/common/Button';
import Loading from 'components/common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useRef } from 'react';
import locationAutocomplete from 'helpers/locationAutocomplete';

const GET_ME = gql`
  query GetMe {
    me {
      id,
      firstName,
      lastName,
      email,
      phoneNumber,
      addressOne,
      addressTwo,
      unsubscribeNotifications,
      unsubscribePromotions,
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($firstName: String, $lastName: String, $addressOne: String, $longitude: Float, $latitude: Float, $addressTwo: String, $phoneNumber: String, $unsubscribeNotifications: Boolean, $unsubscribePromotions: Boolean) {
    updateUser(firstName: $firstName, lastName: $lastName, addressOne: $addressOne, longitude: $longitude, latitude: $latitude, addressTwo: $addressTwo, phoneNumber: $phoneNumber, unsubscribeNotifications: $unsubscribeNotifications, unsubscribePromotions: $unsubscribePromotions) {
      id,
    }
  }
`;

export default function Profile() {
  const {currentUser} = useContext(AppContext);
  const { loading, error, data } = useQuery(GET_ME, { fetchPolicy:'network-only',});
  const [user, setUser] = useState({});
  const [edit, setEdit] = useState(false);
  const addressInputRef = useRef();
  const [updateUserMutation, { loading:updateLoading, reset }] = useMutation(UPDATE_USER);

  const measuredRef = useCallback(node => {
    if (node !== null && !edit) {
      let autocomplete = locationAutocomplete(node);
      autocomplete.addListener("place_changed", (res) => {
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setUser(current => ({...current, addressOne: place.formatted_address, latitude, longitude}));
      });
      addressInputRef.current = node;
    }
  }, []);

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me);
    }
  }, [data]);

  const toggleEdit = () => {
    if (edit) {
      setUser(data.me);
    }
    setEdit(!edit);
  }

  const updateUser = () => {
    updateUserMutation({variables: {...user}})
      .then(res => {
        setEdit(false);
      })
      .catch(() => {
        reset();
      })
  }
  console.log("user", user);
  return (
    <PaddedPage className="w-full">
      <div className="flex flex-col gap-2">
        <div>
          <h2>
            Profile
            <sup>
              <FontAwesomeIcon onClick={toggleEdit} className={`text-l cursor-pointer text-primary`} icon={faPenToSquare}/>
            </sup>
          </h2>
          <hr></hr>
        </div>
        <div className="flex flex-col">
          <label>First Name</label>
          {edit ?<Input valueOverride={user.firstName} onChange={(firstName) => setUser({...user, firstName})}/> : <p>{user.firstName}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Last Name</label>
          {edit ?<Input valueOverride={user.lastName} onChange={(lastName) => setUser({...user, lastName})}/> : <p>{user.lastName}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Phone Number</label>
          {edit ?<Input valueOverride={user.phoneNumber} onChange={(phoneNumber) => setUser({...user, phoneNumber})}/> : <p>{user.phoneNumber}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Address</label>
          {edit ?<Input forwardRef={measuredRef} valueOverride={user.addressOne} onChange={(addressOne) => setUser({...user, addressOne})}/> : <p>{user.addressOne}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Apt, suite, etc.</label>
          {edit ?<Input valueOverride={user.addressTwo} onChange={(addressTwo) => setUser({...user, addressTwo})}/> : <p>{user.addressTwo}&nbsp;</p>}
        </div>
        <div className="flex flex-col">
          <label>Notifications</label>
          <Checkbox
            name="terms"
            className="mr-2"
            readOnly={!edit}
            initialValue={!user.unsubscribeNotifications}
            onChange={() => {
              setUser({ ...user, unsubscribeNotifications: !user.unsubscribeNotifications });
            }}
            text={
              <label>
                Subscribed
              </label>
            }
            required
          />
        </div>
        <div className="flex flex-col">
          <label>Promotional Material</label>
          <Checkbox
            name="terms"
            initialValue={!user.unsubscribePromotions}
            className="mr-2"
            readOnly={!edit}
            onChange={() => {
              setUser({ ...user, unsubscribePromotions: !user.unsubscribePromotions });
            }}
            text={
              <label>
                Subscribed
              </label>
            }
            required
          />
        </div>
        {edit && <ButtonPrimary className="self-start" disabled={updateLoading} onClick={updateUser}>{updateLoading ? <Loading/> : "Update Profile"}</ButtonPrimary>}
      </div>
    </PaddedPage>
  )
}
